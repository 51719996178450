<template>
  <div>
    <div class="floating-buttons">
      <router-link class="fl-button grey" :to="{ path: '/cardgroups/' }"
        ><i class="customicon customicon-angle-left"></i
      ></router-link>
      <button
        :disabled="is_save"
        class="fl-button"
        type="button"
        v-on:click="save"
      >
        <i class="customicon customicon-save"></i>
      </button>
    </div>
    <h1 class="py-6">Kártyacsoport</h1>
    <div class="row">
      <div class="col-xs-6">
        <inputbox
          id="name"
          label="Megnevezés"
          v-bind:errors="errors"
          name="name"
          v-model="form.group.name"
        ></inputbox>
        <select2ajax
          method="post"
          multi="true"
          :url="searchCardsUrl"
          label="Kártyák"
          id="cards"
          name="cards"
          v-model="form.cards"
        ></select2ajax>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data: function () {
    return {
      form: {
        group: {
          name: "",
        },
        cards: {
          items: [],
          selected: [],
        },
      },
      errors: [],
      is_save: false,
    };
  },

  computed: {
    searchCardsUrl: function () {
      return "card_group/search_cards/" + this.$store.state.token;
    },
  },

  mounted: function () {
    var _this = this;

    this.$root.checkRootUserHasSet(function () {
      _this.$root.ajax(
        "card_group/edit/" +
          _this.$store.state.token +
          "/" +
          (_this.$route.query.groupID != undefined
            ? _this.$route.query.groupID
            : 0),
        {
          success: function (response) {
            _this.form.group = response.group;
            _this.form.cards = response.cards;
          },
        }
      );
    });
  },

  methods: {
    save: function (event) {
      var _this = this;
      var form = JSON.parse(JSON.stringify(_this.form));
      this.is_save = true;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("card_group/save/" + _this.$store.state.token, {
          method: "POST",
          data: form,
          waitForSaveInfo: true,
          success: function (response) {
            _this.is_save = false;

            if (response.errors.length > 0) {
              _this.errors = response.errors;
            } else {
              _this.$router.push({ path: "/cardgroups" });
            }
          },
        });
      });
    },
  },
};
</script>
