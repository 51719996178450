<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewReader' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Olvasók</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Épület"
                :items="buildings"
                item-text="name"
                item-value="id"
                @change="buildingChangedEventHandler"
                v-model="search.building_id"
                hide-details
                clearable
                :value-comparator="$config.comparators.isEqual"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Épület szint"
                :items="buildingLevels"
                item-text="name"
                item-value="id"
                @change="searchEventHandler"
                v-model="search.building_level_id"
                hide-details
                clearable
                :value-comparator="$config.comparators.isEqual"
                :placeholder="search.building_id ? 'Összes szint' : 'Válasszon épületet!'"
                :readonly="!search.building_id"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Kontroller"
                :items="controllers"
                item-text="name"
                item-value="element_id"
                @change="searchEventHandler"
                v-model="search.controller_id"
                hide-details
                clearable
                :value-comparator="$config.comparators.isEqual"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.k`]="{ item }">{{ item.x_coord }}, {{ item.y_coord }}</template>
        <template #[`item.actions`]="{ item }" class="d-flex justify-content-end" text-end>
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm m-2"
            :to="{ name: 'ReaderEditor', params: { id: item.element_id } }"
            title="Módosítás"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm m-2"
            @click="deleteReader(item)"
            title="Törlés"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ReaderList',
      fetchItems: this.fetchReaders,
      dataTable: {
        options: {
          sortBy: ['reader_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'reader_name' },
          { text: 'Kontroller', value: 'controller_name' },
          { text: 'Épület', value: 'building_name' },
          { text: 'Épület szint', value: 'building_level_name' },
          { text: 'Olvasó cím', value: 'reader_address' },
          { text: 'Kontroller vonal', value: 'controller_line' },
          { text: 'Koordináta', value: 'k' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        name: '',
        building_id: 0,
        building_level_id: 0,
        controller_id: 0,
      },
      readers: [],
      controllers: [],
      buildings: [],
      buildingLevels: [],
    };
  },

  mounted() {
    this.fetchBuildings();
    if (this.search.building_id) {
      this.fetchBuildingLevels();
    }
    this.fetchControllers();
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        building_id: Number(this.$route.query.building_id),
        building_level_id: Number(this.$route.query.building_level_id),
        controller_id: Number(this.$route.query.controller_id),
      };
    },

    async deleteReader(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> olvasó törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.post(`readers/delete/${item.element_id}`);
        this.fetchItems();
        if (response.status === 'OK') {
          this.$dialog.notify.info('Az olvasó törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async buildingChangedEventHandler() {
      if (this.search.building_id == 0) {
        this.search.building_level_id = 0;
        return;
      }

      this.fetchBuildingLevels();
      this.search.building_level_id = 0;
      this.searchEventHandler();
    },

    async fetchControllers() {
      try {
        const response = await this.$http.post('controllers/list');
        this.controllers = response.controllers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.post('buildings/list');
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildingLevels() {
      try {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.search.building_id,
        });
        this.buildingLevels = response.building_levels;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchReaders() {
      this.loading = true;
      try {
        const response = await this.$http.post('readers/list', this.options);
        this.dataTable.items = response.readers;
        this.dataTable.itemsLength = response.readers_count;
        this.dataTable.options.page = this.$route.query.page*1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        building_id: this.search.building_id || 0,
        building_level_id: this.search.building_level_id || 0,
        controller_id: this.search.controller_id || 0,
      };
    },
  },
};
</script>
