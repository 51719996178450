import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": true,
      "width": "500"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(" Normál üzemmódra állítás "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "small": "",
      "fab": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c(VCardText, {
    staticClass: "pb-0"
  }, [_c('v-datetime-picker', {
    attrs: {
      "timePicker": "",
      "datePicker": "",
      "errorMessages": _vm.errors.back_to_normal_datetime,
      "label": "Kezdeti időpont",
      "required": "",
      "filled": ""
    },
    model: {
      value: _vm.backToNormalDatetime,
      callback: function ($$v) {
        _vm.backToNormalDatetime = $$v;
      },
      expression: "backToNormalDatetime"
    }
  })], 1), _c(VDivider, {
    staticClass: "mb-0 pb-0"
  }), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Mentés ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }