import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-auto"
  }, [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcskezelés")]), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, {
    staticStyle: {
      "position": "sticky",
      "top": "10px"
    }
  }, [_c(VCardTitle, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-swap-horizontal-bold")]), _vm._v(" Kulcsok kezelése ")], 1)]), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VSheet, {
    directives: [{
      def: Ripple,
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "d-flex flex-column justify-center align-center",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "primary",
      "rounded": "",
      "height": "160",
      "dark": "",
      "title": "Kártyatulajdonos kulcshoz való hozzáférésének ellenőrzése",
      "elevation": "2"
    },
    on: {
      "click": function ($event) {
        _vm.view = _vm.Request;
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-tray-arrow-up")]), _vm._v(" Kulcs kiadása ")], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VSheet, {
    directives: [{
      def: Ripple,
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "d-flex flex-column justify-center align-center",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "primary",
      "rounded": "",
      "height": "160",
      "dark": "",
      "title": "Kulcs visszavétele kártyatulajdonostól",
      "elevation": "2"
    },
    on: {
      "click": function ($event) {
        _vm.view = _vm.Return;
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-tray-arrow-down")]), _vm._v(" Kulcs visszavétele ")], 1)], 1)], 1)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key-chain")]), _vm._v(" Kulcsszekrény ")], 1)]), _c(VCardText, [_c(VAutocomplete, {
    attrs: {
      "label": "Kulcsszekrény",
      "filled": "",
      "loading": _vm.keyCabinetsLoading,
      "items": _vm.keyCabinets,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "hint": !_vm.keyCabinetId ? 'Nincs kulcsszekrény kiválasztva. A továbblépéshez válasszon egyet!' : '',
      "persistent-hint": ""
    },
    model: {
      value: _vm.keyCabinetId,
      callback: function ($$v) {
        _vm.keyCabinetId = $$v;
      },
      expression: "keyCabinetId"
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "lg": 12
    }
  }, [_vm.keyCabinetId ? _c(VCard, {
    attrs: {
      "outlined": "",
      "loading": _vm.keysLoading
    }
  }, [_c(VCardTitle, [_vm._v("Kulcsok")]), _c(VSimpleTable, {
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', _vm._l(_vm.keys, function (key) {
          return _c('tr', {
            key: ("key-" + (key.id))
          }, [_c('td', {
            attrs: {
              "width": "10"
            }
          }, [_c(VBadge, {
            attrs: {
              "title": key.taken_by ? 'A kulcsot elvitték' : 'A kulcs a helyén van',
              "avatar": "",
              "bordered": "",
              "overlap": "",
              "color": key.taken_by ? 'error' : 'success',
              "icon": key.taken_by ? 'mdi-close' : 'mdi-check'
            }
          }, [_c(VAvatar, {
            attrs: {
              "size": "32",
              "color": key.color,
              "rounded": ""
            }
          }, [_c(VIcon, [_vm._v("mdi-key")])], 1)], 1)], 1), _c('td', [_c('strong', [_vm._v(_vm._s(key.name))]), _c('br'), _c('small', [_vm._v(_vm._s(key.remark))])])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 4029254286)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "value": _vm.view,
      "persistent": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    on: {
      "input": function (v) {
        _vm.view = v ? _vm.view : null;
        if (!_vm.view) {
          _vm.$asyncComputed.keys.update();
        }
      }
    }
  }, [_c(VCard, {
    staticClass: "d-flex flex-column justify-center",
    attrs: {
      "elevation": "0",
      "color": "muted"
    }
  }, [_c(VToolbar, {
    staticClass: "flex-grow-1",
    attrs: {
      "color": "transparent",
      "elevation": "0"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c(VSheet, {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "800",
      "color": "transparent"
    }
  }, [_c(_vm.view, {
    tag: "component",
    on: {
      "close": _vm.closeDialog,
      "switch-action": function (v) {
        return _vm.view = v;
      }
    }
  })], 1), _c(VSpacer)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }