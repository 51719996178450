<template>
  <v-dialog :value="true" persistent max-width="480">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-file-export</v-icon>
        Exportálás
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text class="mt-4">
        <div class="d-flex">
          <v-text-field label="Fájlnév" filled hide-details v-model="filename" class="mr-1" />

          <v-select
            style="width: 100px"
            label="Formátum"
            filled
            hide-details
            :items="[
              { text: 'Excel (xls)', value: 'xls' },
              { text: 'Excel (xlsx)', value: 'xlsx' },
            ]"
            v-model="format"
            :disabled="loading"
          />
        </div>

        <slot/>

        <v-alert v-if="loading" type="warning" text class="mt-4">
          A fájl exportálása folyamatban van. Kérjük várjon!
        </v-alert>

        <!-- <v-select
          label="Fájl típusa"
          filled
          readonly
          value="xlsx"
          :items="[
            // { text: 'Excel táblázatfájl régi (XLS)', value: 'xls' },
            { text: 'Excel táblázatfájl (XLSX)', value: 'xlsx' },
          ]"
        /> -->
        <!-- <div class="d-flex">
          <v-select
            label="Munkalapok"
            :items="[
              { text: 'Összes', value: 'all' },
              { text: 'Egyéni', value: 'custom' },
            ]"
            filled
            v-model="worksheetSelectionMode"
          />
          <v-select
            :disabled="worksheetSelectionMode !== 'custom'"
            label="Kiválasztott munkalapok"
            filled
            multiple
            small-chips
            v-model="worksheets"
            :items="worksheetOptions"
            :item-text="worksheetOptionsItemText"
            :item-value="worksheetOptionsItemValue"
            :menu-props="{ auto: true }"
          >
            <template #selection="{ item, index }">
              <span v-if="!worksheets.length">Nincs kiválasztva</span>
              <span v-if="index === 0">{{ worksheets.length }} kiválasztva</span>
            </template>
          </v-select>
        </div>
        <div class="d-flex">
          <v-select
            label="Oszlopok"
            :items="[
              { text: 'Összes', value: 'all' },
              { text: 'Egyéni', value: 'custom' },
            ]"
            filled
            v-model="columnSelectionMode"
          />
          <v-select
            :disabled="columnSelectionMode !== 'custom'"
            label="Kiválasztott oszlopok"
            filled
            multiple
            small-chips
            v-model="columns"
            :items="columnOptions"
            :item-text="columnOptionsItemText"
            :item-value="columnOptionsItemValue"
            :menu-props="{ auto: true }"
          >
            <template #selection="{ item, index }">
              <span v-if="!columns.length">Nincs kiválasztva</span>
              <span v-if="index === 0">{{ columns.length }} kiválasztva</span>
            </template>
          </v-select>
        </div> -->
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" large depressed @click="generateFile" :loading="loading"> Fájl exportálása </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fileDownload from 'js-file-download';

export default {
  props: {
    apiEndpoint: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    worksheetOptions: {
      type: Array,
      default: () => [],
    },
    worksheetOptionsItemText: {
      type: String,
      default: 'text',
    },
    worksheetOptionsItemValue: {
      type: String,
      default: 'value',
    },
    columnOptions: {
      type: Array,
      default: () => [],
    },
    columnOptionsItemText: {
      type: String,
      default: 'text',
    },
    columnOptionsItemValue: {
      type: String,
      default: 'value',
    },
    additionalPostParams: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      // worksheetSelectionMode: 'all',
      // columnSelectionMode: 'all',
      format: 'xlsx',
      // worksheets: [],
      // columns: [],
      loading: false,
    };
  },

  // mounted() {
  //   this.worksheets = [];
  //   this.columns = [];
  // },

  methods: {
    async generateFile() {
      this.loading = true;
      // if (this.worksheetSelectionMode == 'all') {
      //   for (const option of this.worksheetOptions) {
      //     this.worksheets.push(option[this.worksheetOptionsItemValue]);
      //   }
      // }
      // if (this.columnSelectionMode == 'all') {
      //   for (const option of this.columnOptions) {
      //     this.columns.push(option[this.columnOptionsItemValue]);
      //   }
      // }

      const response = await this.$http.post(
        this.apiEndpoint,
        {
          // worksheets: this.worksheets,
          // columns: this.columns,
          format: this.format,
          ...this.additionalPostParams,
        },
        { responseType: 'blob' }
      );
      this.loading = false;

      fileDownload(
        response,
        `[UNIOM][export][${process.env.VUE_APP_VARIANT}] ${this.filename} ${this.$moment().format(
          '(YYYY-MM-DD hh-mm-ss)'
        )}.${this.format}`
      );
      this.$emit('close');
    },
  },
};
</script>
