import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": true,
      "persistent": "",
      "max-width": "640"
    }
  }, [_c(VForm, {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-plus")]), _vm._v(" Kártya létrehozása "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-close ")])], 1)], 1), _c(VDivider), _c(VCardText, [_c('div', {
    staticClass: "text-h6 ma-2"
  }, [_vm._v("Birtokos adatai")]), _c('dynamic-field', _vm._b({
    attrs: {
      "component-path": "uninet-vuetify/src/components/DatetimePicker/DatetimePicker.vue"
    },
    model: {
      value: _vm.owner.name,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "name", $$v);
      },
      expression: "owner.name"
    }
  }, 'dynamic-field', {
    filled: '',
    label: 'tesztelek'
  }, false)), _c(VAutocomplete, {
    staticClass: "ma-2",
    attrs: {
      "label": "Birtokos",
      "items": _vm.owners,
      "filled": "",
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.card.owner_id,
      callback: function ($$v) {
        _vm.$set(_vm.card, "owner_id", $$v);
      },
      expression: "card.owner_id"
    }
  }), _vm.card.owner_id == 0 ? [_c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c(VTextField, {
    staticClass: "ma-2",
    attrs: {
      "label": "Kártyabirtokos neve",
      "filled": "",
      "hint": "Kötelező",
      "persistent-hint": "",
      "hide-details": ""
    },
    model: {
      value: _vm.owner.name,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "name", $$v);
      },
      expression: "owner.name"
    }
  })], 1), _c(VCol, [_c('v-datetime-picker', {
    staticClass: "ma-2",
    attrs: {
      "label": "Lejárat",
      "filled": "",
      "time-picker": false,
      "hide-details": ""
    },
    model: {
      value: _vm.owner.validity_end,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "validity_end", $$v);
      },
      expression: "owner.validity_end"
    }
  })], 1)], 1), _vm.ownerDetails ? _c('div', [_c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c(VTextField, {
    staticClass: "ma-2",
    attrs: {
      "label": "Cím",
      "filled": "",
      "hide-details": ""
    },
    model: {
      value: _vm.owner.address,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "address", $$v);
      },
      expression: "owner.address"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-datetime-picker', {
    staticClass: "ma-2",
    attrs: {
      "label": "Születési idő",
      "filled": "",
      "time-picker": false,
      "hide-details": ""
    },
    model: {
      value: _vm.owner.birth_date,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "birth_date", $$v);
      },
      expression: "owner.birth_date"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c(VTextField, {
    staticClass: "ma-2",
    attrs: {
      "label": "Email cím",
      "filled": "",
      "hide-details": ""
    },
    model: {
      value: _vm.owner.email,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "email", $$v);
      },
      expression: "owner.email"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c(VTextField, {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.phoneNumber,
      expression: "$config.inputMasks.phoneNumber"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "Telefonszám",
      "filled": "",
      "hide-details": ""
    },
    model: {
      value: _vm.owner.phone,
      callback: function ($$v) {
        _vm.$set(_vm.owner, "phone", $$v);
      },
      expression: "owner.phone"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex"
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "small": "",
      "text": "",
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        _vm.ownerDetails = !_vm.ownerDetails;
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.ownerDetails ? 'mdi-eye-off' : 'mdi-eye'))]), _vm.ownerDetails ? _c('div', [_vm._v("További adatok elrejtése")]) : _c('div', [_vm._v("További adatok megjelenítése")])], 1), _c(VSpacer)], 1)] : _vm._e()], 2), _c(VDivider), _c(VCardText, [_c('div', {
    staticClass: "text-h6 ma-2"
  }, [_vm._v("Kártya adatai")]), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c(VTextField, {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.rfId,
      expression: "$config.inputMasks.rfId"
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "RFID",
      "filled": "",
      "placeholder": "Érintse a kártyát az olvasóhoz",
      "hint": "Kötelező",
      "persistent-hint": "",
      "hide-details": ""
    },
    model: {
      value: _vm.card.rf_id,
      callback: function ($$v) {
        _vm.$set(_vm.card, "rf_id", $$v);
      },
      expression: "card.rf_id"
    }
  })], 1), _c(VCol, [_c(VTextField, {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.cardNumber,
      expression: "$config.inputMasks.cardNumber"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "Kártyaszám",
      "filled": "",
      "hint": "Kötelező",
      "persistent-hint": "",
      "hide-details": ""
    },
    model: {
      value: _vm.card.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.card, "card_number", $$v);
      },
      expression: "card.card_number"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c(VAutocomplete, {
    staticClass: "ma-2",
    attrs: {
      "label": "Hozzáférési szintek",
      "items": _vm.accessLevels,
      "filled": "",
      "multiple": "",
      "clearable": "",
      "chips": "",
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": ""
    },
    model: {
      value: _vm.card.access_level_ids,
      callback: function ($$v) {
        _vm.$set(_vm.card, "access_level_ids", $$v);
      },
      expression: "card.access_level_ids"
    }
  })], 1), _c(VCol, [_c(VCombobox, {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.plateNumber,
      expression: "$config.inputMasks.plateNumber"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "Rendszámok",
      "filled": "",
      "multiple": "",
      "clearable": "",
      "chips": "",
      "hide-details": ""
    },
    model: {
      value: _vm.card.plates,
      callback: function ($$v) {
        _vm.$set(_vm.card, "plates", $$v);
      },
      expression: "card.plates"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, [_c(VAutocomplete, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasFeature('CARD_TEMPLATE'),
      expression: "hasFeature('CARD_TEMPLATE')"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "Sablon",
      "items": _vm.cardTemplates,
      "filled": "",
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.card.template_id,
      callback: function ($$v) {
        _vm.$set(_vm.card, "template_id", $$v);
      },
      expression: "card.template_id"
    }
  })], 1)], 1)], 1), _c(VDivider), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "large": "",
      "depressed": "",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1), _c(VSpacer)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }