import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "grey",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "accent",
      "width": "60",
      "height": "60",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Ütemzés")]), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardText, [_c(VRow, {
    staticClass: "m-1"
  }, [_c(VCol, {
    staticClass: "mb-0 pb-0",
    attrs: {
      "cols": 12,
      "md": 10,
      "sm": 10
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c(VCol, {
    staticClass: "d-flex align-items-center justify-end",
    attrs: {
      "cols": 12,
      "md": 2
    }
  }, [_c(VBtn, {
    staticClass: "rounded-sm",
    attrs: {
      "fab": "",
      "dark": "",
      "depressed": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.add
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.form.items,
      "item-key": "id",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.v",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(_vm._s(index + 1) + ".")];
      }
    }, {
      key: "item.start_time",
      fn: function (_ref2) {
        var item = _ref2.item,
          i = _ref2.i;
        return [_c('v-masked-text-field', {
          attrs: {
            "error-messages": _vm.errors[("start_time_" + i)],
            "mask": _vm.$config.inputMasks.time
          },
          model: {
            value: item.start_time,
            callback: function ($$v) {
              _vm.$set(item, "start_time", $$v);
            },
            expression: "item.start_time"
          }
        })];
      }
    }, {
      key: "item.end_time",
      fn: function (_ref3) {
        var item = _ref3.item,
          i = _ref3.i;
        return [_c('v-masked-text-field', {
          attrs: {
            "error-messages": _vm.errors[("end_time_" + i)],
            "mask": _vm.$config.inputMasks.time
          },
          model: {
            value: item.end_time,
            callback: function ($$v) {
              _vm.$set(item, "end_time", $$v);
            },
            expression: "item.end_time"
          }
        })];
      }
    }, {
      key: "item.monday.selected",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.monday.selected,
            callback: function ($$v) {
              _vm.$set(item.monday, "selected", $$v);
            },
            expression: "item.monday.selected"
          }
        })];
      }
    }, {
      key: "item.tuesday.selected",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.tuesday.selected,
            callback: function ($$v) {
              _vm.$set(item.tuesday, "selected", $$v);
            },
            expression: "item.tuesday.selected"
          }
        })];
      }
    }, {
      key: "item.wednesday.selected",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.wednesday.selected,
            callback: function ($$v) {
              _vm.$set(item.wednesday, "selected", $$v);
            },
            expression: "item.wednesday.selected"
          }
        })];
      }
    }, {
      key: "item.thursday.selected",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.thursday.selected,
            callback: function ($$v) {
              _vm.$set(item.thursday, "selected", $$v);
            },
            expression: "item.thursday.selected"
          }
        })];
      }
    }, {
      key: "item.friday.selected",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.friday.selected,
            callback: function ($$v) {
              _vm.$set(item.friday, "selected", $$v);
            },
            expression: "item.friday.selected"
          }
        })];
      }
    }, {
      key: "item.saturday.selected",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.saturday.selected,
            callback: function ($$v) {
              _vm.$set(item.saturday, "selected", $$v);
            },
            expression: "item.saturday.selected"
          }
        })];
      }
    }, {
      key: "item.sunday.selected",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c(VSimpleCheckbox, {
          attrs: {
            "color": "orange",
            "ripple": false
          },
          model: {
            value: item.sunday.selected,
            callback: function ($$v) {
              _vm.$set(item.sunday, "selected", $$v);
            },
            expression: "item.sunday.selected"
          }
        })];
      }
    }, {
      key: "item.date_interval_id",
      fn: function (_ref11) {
        var item = _ref11.item,
          i = _ref11.i;
        return [_c(VCombobox, {
          attrs: {
            "items": _vm.dateIntervals,
            "item-text": "label",
            "item-value": "value"
          },
          on: {
            "change": function ($event) {
              return _vm.dateChange(item, i);
            }
          },
          model: {
            value: item.date_interval_id,
            callback: function ($$v) {
              _vm.$set(item, "date_interval_id", $$v);
            },
            expression: "item.date_interval_id"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref12) {
        var index = _ref12.index;
        return [_c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              return _vm.del(index);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }