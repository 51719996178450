import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.exportDialog ? _c('export-dialog', {
    attrs: {
      "filename": "Terület napló",
      "api-endpoint": "/logs/export/building",
      "additional-post-params": {
        period: _vm.search.period,
        from: _vm.search.from,
        to: _vm.search.to
      }
    },
    on: {
      "close": function ($event) {
        _vm.exportDialog = false;
      }
    }
  }) : _vm._e(), _c('title-row', {
    attrs: {
      "title": "Terület napló"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "color": _vm.polling ? 'primary' : 'muted'
          },
          on: {
            "click": _vm.togglePolling
          }
        }, [!_vm.loading ? _c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v(_vm._s(_vm.polling ? 'mdi-stop' : 'mdi-play'))]) : _c(VProgressCircular, {
          staticClass: "mr-2 ml-n1",
          attrs: {
            "indeterminate": "",
            "size": "18",
            "width": "2"
          }
        }), _vm._v(" Frissítés ")], 1), _c('title-row-button', {
          attrs: {
            "color": "muted"
          },
          on: {
            "click": function ($event) {
              _vm.exportDialog = true;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-export")]), _vm._v(" Exportálás ")], 1)];
      },
      proxy: true
    }])
  }), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Épület",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "Összes",
      "multiple": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "building_id", $$v);
      },
      expression: "search.building_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Kontroller",
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "Összes",
      "multiple": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "controller_id", $$v);
      },
      expression: "search.controller_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Név / Kártyaszám / Vonalkód száma",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.keyphrase,
      callback: function ($$v) {
        _vm.$set(_vm.search, "keyphrase", $$v);
      },
      expression: "search.keyphrase"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": _vm.search.period === 'custom' ? 1 : 2
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Időkorlát",
      "items": _vm.timePeriods,
      "item-text": "label",
      "item-value": "value",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.period,
      callback: function ($$v) {
        _vm.$set(_vm.search, "period", $$v);
      },
      expression: "search.period"
    }
  })], 1), _vm.search.period === 'custom' ? [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát kezdete",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedStartDates
      },
      "time-picker": false,
      "error-messages": _vm.search.from ? '' : 'Kötelező!'
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.from,
      callback: function ($$v) {
        _vm.$set(_vm.search, "from", $$v);
      },
      expression: "search.from"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát vége",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedEndDates
      },
      "time-picker": false,
      "disabled": !_vm.search.from,
      "error-messages": _vm.search.from && !_vm.search.to ? 'Kötelező!' : ''
    },
    on: {
      "change": _vm.searchChangeEventHandler
    },
    model: {
      value: _vm.search.to,
      callback: function ($$v) {
        _vm.$set(_vm.search, "to", $$v);
      },
      expression: "search.to"
    }
  })], 1)] : _vm._e()], 2)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.dataTable.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      },
      "contextmenu:row": _vm.rowContextMenuClick
    },
    scopedSlots: _vm._u([{
      key: "item.timestamp",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.timestamp, _vm.$config.momentFormats.explicit)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.timestamp, 'lll')) + " ")])];
      }
    }, {
      key: "item.creator_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.creator_name))]), _c('div', [_vm._v(_vm._s(item.card_number || item.barcode_number))])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.element_id && _vm.hasRight('CARD_READ') ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            },
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "text": ""
          }
        }, [_c(VIcon, [_vm._v("mdi-card-account-details")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c(VMenu, {
    attrs: {
      "position-x": _vm.x,
      "position-y": _vm.y,
      "absolute": "",
      "offset-y": ""
    },
    model: {
      value: _vm.columnSelectorMenu,
      callback: function ($$v) {
        _vm.columnSelectorMenu = $$v;
      },
      expression: "columnSelectorMenu"
    }
  }, [_c(VList, [_vm._v(" eheheh ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }