import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _defaultSearch;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Relé")]), _c(VCard, {
    staticClass: "mb-6",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-doorbell")]), _vm._v(" Alapadatok ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6,
      "sm": 12
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Kontroller",
      "error-messages": _vm.errors.controller_id,
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "clearable": "",
      "placeholder": "Válasszon",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "controller_id", $$v);
      },
      expression: "form.controller_id"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Relé cím",
      "error-messages": _vm.errors.address,
      "filled": "",
      "clearable": "",
      "type": "number",
      "min": "0",
      "max": "32"
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Működési mód",
      "error-messages": _vm.errors.operating_mode,
      "items": _vm.operatingModes,
      "item-text": "label",
      "item-value": "value",
      "filled": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.operating_mode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "operating_mode", $$v);
      },
      expression: "form.operating_mode"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "auto-grow": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1), _c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-transit-connection-variant")]), _vm._v(" Eszközök ")], 1), _c(VCardText, [_c('v-two-panel-multiselect', {
    attrs: {
      "headers": [{
        text: 'Eszköz',
        value: 'name'
      }, {
        text: 'Típus',
        value: 'type'
      }],
      "items": _vm.devices,
      "options": {
        sortBy: ['type.id']
      },
      "item-value": "element_id",
      "default-search": (_defaultSearch = {}, _defaultSearch['type.model_name'] = _vm.deviceTypes.map(function (e) {
        return e.model_name;
      }), _defaultSearch)
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var search = _ref.search;
        return [_c(VCard, {
          staticClass: "mb-6",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter")]), _vm._v(" Keresés ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Eszköz neve",
            "clearable": ""
          },
          model: {
            value: search.name,
            callback: function ($$v) {
              _vm.$set(search, "name", $$v);
            },
            expression: "search.name"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VSelect, {
          attrs: {
            "label": "Eszköz típus",
            "items": _vm.deviceTypes,
            "item-text": "name",
            "item-value": "model_name",
            "multiple": "",
            "clearable": "",
            "small-chips": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function (_ref2) {
              var item = _ref2.item,
                index = _ref2.index;
              return [search['type.model_name'].length < _vm.deviceTypes.length ? _c(VChip, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" " + _vm._s(item.name) + " ")]) : index === 0 ? _c(VChip, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("Minden típus")]) : _vm._e()];
            }
          }], null, true),
          model: {
            value: search['type.model_name'],
            callback: function ($$v) {
              _vm.$set(search, 'type.model_name', $$v);
            },
            expression: "search['type.model_name']"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }, {
      key: "item.type",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.type.name) + " ")];
      }
    }], null, true),
    model: {
      value: _vm.form.devices,
      callback: function ($$v) {
        _vm.$set(_vm.form, "devices", $$v);
      },
      expression: "form.devices"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }