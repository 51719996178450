import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm$card$owner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-scroll', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('section', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c(VCard, {
    attrs: {
      "color": "info"
    }
  }, [_c(VCardTitle, [_vm._v(" Test panel "), _c(VDivider), _c(VBtn, {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF123456');
      }
    }
  }, [_vm._v("Existing key")]), _c(VBtn, {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF12345');
      }
    }
  }, [_vm._v("Not existing key")]), _c(VDivider), _c(VBtn, {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('FD1F4323');
      }
    }
  }, [_vm._v("Allowed card")]), _c(VBtn, {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('E87138C7');
      }
    }
  }, [_vm._v("Disallowed card")]), _c(VBtn, {
    staticClass: "mx-2",
    on: {
      "click": function ($event) {
        return _vm.simulateRead('FD1F432');
      }
    }
  }, [_vm._v("Not existing card")])], 1)], 1), _c(VExpandTransition, [_c(VCard, {
    staticClass: "mx-auto my-auto",
    attrs: {
      "loading": _vm.loading,
      "width": "960",
      "elevation": "0",
      "color": "transparent"
    }
  }, [_c(VCardText, [_c(VRow, {
    staticClass: "d-flex justify-center"
  }, [_c(VExpandTransition, [_vm.card ? _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VCard, {
    staticClass: "d-flex flex-column justify-center align-center",
    attrs: {
      "outlined": "",
      "height": "300px"
    },
    on: {
      "click": _vm.toggleCard
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-account-details")]), _vm._v(" Kártya ")], 1), !_vm.card ? _c(VCardText, [_vm._v("Érintse a kártyát az olvasóhoz!")]) : _c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardText, {
    staticClass: "d-flex align-center"
  }, [_c(VAvatar, {
    attrs: {
      "size": "120",
      "color": "grey ligthen-2",
      "rounded": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "ml-3 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s((_vm$card$owner = _vm.card.owner) === null || _vm$card$owner === void 0 ? void 0 : _vm$card$owner.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kártyaszám: " + _vm._s(_vm.card.card_number))]), _c('div', [_vm._v(_vm._s(_vm.card.card_rule_name_hu))])])], 1)], 1)], 1)], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.key ? _c(VCol, {
    attrs: {
      "cols": "6"
    }
  }, [_c(VCard, {
    staticClass: "d-flex flex-column justify-center align-center",
    attrs: {
      "outlined": "",
      "height": "300px"
    },
    on: {
      "click": _vm.toggleKey
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key")]), _vm._v(" Kulcs ")], 1), !_vm.key ? _c(VCardText, [_vm._v(" Érintse a kulcsot az olvasóhoz! ")]) : _c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardText, {
    staticClass: "d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.key.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kulcsszám: " + _vm._s(_vm.key.keyNumber))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(" " + _vm._s(_vm.key.building) + " épület " + _vm._s(_vm.key.floor) + ". emelet " + _vm._s(_vm.key.room) + ". szoba ")]), _c('div', [_vm._v("Megjegyzés: " + _vm._s(_vm.key.remark))])])], 1)], 1)], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.response ? _c(VCol, {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c(VAlert, {
    attrs: {
      "type": _vm.response.color,
      "prominent": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.response.message) + " ")])], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.card || _vm.key ? _c(VCol, {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c(VBtn, {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Visszaállítás ")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }