import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VContainer, [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Szolgáltatás állapota")]), _vm._l(_vm.processes, function (process) {
    return _c(VCard, {
      key: process.name,
      staticClass: "mb-6"
    }, [_c(VCardTitle, [_vm._v(_vm._s(_vm.prettyName(process.name)))]), _c(VCardText, [_c('div', {
      staticClass: "d-flex justify-end flex-row-reverse"
    }, _vm._l(process.statuses, function (status) {
      return _c(VTooltip, {
        key: status.id,
        attrs: {
          "bottom": "",
          "color": _vm.statusColor(status)
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref) {
            var on = _ref.on,
              attrs = _ref.attrs;
            return [_c(VAlert, _vm._g(_vm._b({
              staticClass: "pa-0",
              staticStyle: {
                "margin": "1px"
              },
              attrs: {
                "rounded": "sm",
                "width": "20",
                "height": "32",
                "color": _vm.statusColor(status)
              }
            }, 'v-alert', attrs, false), on))];
          }
        }], null, true)
      }, [status.downtime / status.period === 0 ? _c('div', [_vm._v("Folyamatos működés")]) : status.downtime / status.period < 1 ? _c('div', [_vm._v(" Fennakadás "), _c('small', [_c('div', [_vm._v("Leállás időtartama: ~" + _vm._s(status.downtime) + "s")]), _c('div', [_vm._v("Kilépési kód: " + _vm._s(status.code))])])]) : _c('div', [_vm._v(" Leállás "), _c('small', [_c('div', [_vm._v("Kilépési kód: " + _vm._s(status.code))])])]), _c('small', [_vm._v(_vm._s(_vm._f("moment")(status.created, 'LLL')) + " - " + _vm._s(status.period / 60) + "m")])]);
    }), 1), _c('div', {
      staticClass: "d-flex align-center grey--text mt-3"
    }, [_c('div', [_vm._v("3 napja")]), _c(VDivider, {
      staticClass: "ma-2"
    }), _c('div', [_vm._v(_vm._s(_vm.downTimeInPercents(process)) + "% elérhetőség")]), _c(VDivider, {
      staticClass: "ma-2"
    }), _c('div', [_vm._v("Most")])], 1)])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }