import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.exportDialog ? _c('export-dialog', {
    attrs: {
      "filename": "Kártyabirtokosok",
      "api-endpoint": "/card-owners/export"
    },
    on: {
      "close": function ($event) {
        _vm.exportDialog = false;
      }
    }
  }) : _vm._e(), _c('title-row', {
    attrs: {
      "title": "Kártyabirtokosok"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "color": "muted"
          },
          on: {
            "click": function ($event) {
              _vm.exportDialog = true;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-export")]), _vm._v(" Exportálás ")], 1), _c('title-row-button', {
          attrs: {
            "to": {
              name: 'NewCardOwner'
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Új kártyabirtokos ")], 1)];
      },
      proxy: true
    }])
  }), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Név",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _vm._l(_vm.extraFields, function (field) {
    return [field.searchable == 1 && field.show_in_list == 1 ? _c(VCol, {
      key: ("extra-field-" + (field.name)),
      attrs: {
        "cols": 12,
        "sm": 4,
        "md": 3
      }
    }, [_c('dynamic-field', {
      staticClass: "my-4 my-sm-0",
      attrs: {
        "field": field,
        "hide-details": "",
        "dense": "",
        "clearable": "",
        "required": false
      },
      on: {
        "input": _vm.searchEventHandler
      },
      model: {
        value: _vm.search[field.name],
        callback: function ($$v) {
          _vm.$set(_vm.search, field.name, $$v);
        },
        expression: "search[field.name]"
      }
    })], 1) : _vm._e()];
  })], 2)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTableHeaders,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.contact",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm._f("VMask")(item.phone, _vm.$config.inputMasks.phoneNumber)) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.email) + " ")])];
      }
    }, _vm._l(_vm.extraFields, function (field) {
      return {
        key: ("item.extra_fields." + (field.name)),
        fn: function (_ref2) {
          var item = _ref2.item,
            index = _ref2.index;
          return [item.extra_fields && item.extra_fields[field.name] ? _c('dynamic-field-value', {
            key: ("dynamic-field-value-" + index + "-" + (field.name)),
            attrs: {
              "value": item.extra_fields[field.name],
              "field": field
            }
          }) : _vm._e()];
        }
      };
    }), {
      key: "item.validity_end",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c(VTooltip, {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s(_vm._f("moment")(item.validity_end, 'L')) + " ")])];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.validity_end, 'LL')) + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.hasRight('CARD_OWNER_DELETE') ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "small": "",
            "text": "",
            "fab": "",
            "dark": "",
            "depressed": "",
            "color": "error",
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteOwner(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e(), _vm.hasRight('CARD_OWNER_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "small": "",
            "text": "",
            "fab": "",
            "dark": "",
            "depressed": "",
            "color": "secondary",
            "to": {
              name: 'CardOwnerEditor',
              params: {
                id: item.element_id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }