<template>
  <div>
    <div class="floating-buttons">
      <button
        v-show="this.query.page == 1"
        class="fl-button"
        type="button"
        v-on:click="refreshingChange()"
      >
        <i v-bind:class="this.refreshing_icon"></i>
      </button>
    </div>
    <h1 v-if="$route.params.type === 'Building'">Épület log</h1>
    <h1 v-else-if="$route.params.type === 'ParkingLot'">Parkoló log</h1>

    <div class="row">
      <div class="col-xs-12">
        <h2>Kereső</h2>
      </div>
      <div class="col-xs-2">
        <comboboxV2
          id="type"
          label="Típus"
          name="type"
          :options="search.type.items"
          v-on:input="typeChange"
          value="enter_identified"
        />
      </div>

      <div class="col-xs-2">
        <comboboxV2
          id="buildings"
          :label="building_type == 'P' ? 'Parkoló' : 'Épület'"
          name="buildings"
          :options="search.buildings.items"
          v-on:input="buildingChange"
        />
      </div>
      <div class="col-xs-2">
        <comboboxV2
          id="time_limit"
          label="Időkorlát"
          name="time_limit"
          :options="search.time_limits.items"
          v-on:input="timeLimitChange"
          value="1_DAY"
        />
      </div>
      <div class="col-xs-2">
        <comboboxV2
          id="controller"
          label="Kontroller"
          name="controller"
          :options="search.controllers.items"
          v-on:input="controllerChange"
          value="1_DAY"
        />
      </div>
      <div class="col-xs-4">
        <inputbox
          id="kerkif"
          :label="
            building_type == 'P'
              ? 'Név / Kártyaszám / Vonalkód száma / Rendszám'
              : 'Név / Kártyaszám / Vonalkód száma'
          "
          name="kerkif"
          v-model="query.kerkif"
          @keyup="kerkifChange"
        ></inputbox>
      </div>
    </div>

    <div v-if="logs.length > 0" class="table-with-title">
      <h2>Log</h2>
      <table class="list">
        <thead>
          <tr>
            <th>Idő</th>
            <th>Név</th>
            <th>Kártyaszám</th>
            <th>Hely</th>
            <th>Station ID</th>
            <th>Gate ID</th>
            <th>Típus</th>
            <th>Esemény</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, i) in logs" :key="i">
            <td :style="tdStyle(log)">{{ log.timestamp }}</td>
            <td :style="tdStyle(log)">{{ log.creator_name }}</td>
            <td :style="tdStyle(log)">
              {{
                log.card_number
                  ? log.card_rule_prefix + log.card_number
                  : log.barcode_number
              }}
            </td>
            <td :style="tdStyle(log)">{{ log.gatename }}</td>
            <td :style="tdStyle(log)">{{ log.stationid }}</td>
            <td :style="tdStyle(log)">{{ log.gateid }}</td>
            <td :style="tdStyle(log)">{{ log.type }}</td>
            <td :style="tdStyle(log)">{{ log.message }}</td>
            <td v-if="log.rf_id" :style="tdStyle(log)">
              <i
                v-on:click="cardSearch(log.element_id)"
                style="font-size: 18px; cursor: pointer"
                class="small-button customicon customicon-search"
              ></i>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="logs.length == 0" class="normal-text">
      Nem található log vagy nincs jogosultsága a megtekintéshez.
    </div>
    <pagination
      v-if="logs.length > 0"
      showcombo="1"
      :limit="query.limit"
      :amount="query.amount"
      v-on:pageChanged="pageChanged"
      v-model="query.page"
    ></pagination>
  </div>
</template>
<script>
export default {
  computed: {
    refreshing_icon: function () {
      return this.refreshing == 1
        ? "customicon customicon-stop"
        : "customicon customicon-play";
    },
  },

  data: function () {
    return {
      refreshing: 0,
      building_type: "P",
      waiting: false,
      query: {
        page: 1,
        limit: 100,
        amount: 0,
        kerkif: "",
        type: "enter_identified",
        building: "",
        time_limit: "1_DAY",
        controller: "",
      },
      search: {
        type: {
          items: [],
          selected: "enter_identified",
        },
        buildings: {
          items: [],
          selected: "",
        },
        controllers: {
          items: [],
          selected: "",
        },
        time_limits: {
          items: [
            {
              label: "- nincs korlát -",
              value: "",
            },
            {
              label: "utolsó 1 nap",
              value: "1_DAY",
            },
            {
              label: "utolsó 1 hét",
              value: "1_WEEK",
            },
            {
              label: "utolsó 1 hónap",
              value: "1_MONTH",
            },
            {
              label: "utolsó 3 hónap",
              value: "3_MONTH",
            },
          ],
          selected: "1_DAY",
        },
      },
      logs: [],
    };
  },

  watch: {
    $route: "routerChange",
  },

  created: function () {
    this.routerChange();
  },

  mounted: function () {
    var _this = this;

    this.getTypes();
    this.getLogs();
    this.getBuildings();
    this.getControllers();
    if (this.query.page == 1) {
      this.refreshing = 1;
      this.$root.interval = setInterval(function () {
        _this.getLogs();
      }, 2000);
    }
  },

  methods: {
    routerChange: function () {
      if (this.$route.path == "/building_log") {
        this.building_type = "B";
      } else {
        this.building_type = "P";
      }

      if (this.$route.fullPath == "/building_log") {
        this.refreshing = 0;
      } else if (this.$route.fullPath == "/parkinglot_log") {
        this.refreshing = 0;
      }

      if (Object.keys(this.$route.query).length > 1) {
        // Lementi az url paraméterben átadott keresési értékeket
        this.query = JSON.parse(JSON.stringify(this.$route.query));

        this.search.type.selected = this.query.type;
      } else {
        this.query = {
          limit: 100,
          page: 1,
          kerkif: "",
          type: "enter_identified",
          building: "",
          time_limit: "1 DAY",
          controller: "",
        };
      }

      this.getBuildings();
      this.getLogs();
    },

    refreshingChange: function () {
      var _this = this;
      if (this.refreshing == 1) {
        this.refreshing = 0;
        clearInterval(this.$root.interval);
      } else {
        this.refreshing = 1;
        this.$root.interval = setInterval(function () {
          _this.getLogs();
        }, 2000);
      }
    },

    /*searchClick: function() {
      if (this.$root.interval != undefined)
        clearInterval(this.$root.interval);

      this.query.page = 1;

      this.$router.push({path: '/device_log', query: this.query});
    },*/

    typeChange: function (val) {
      this.query.page = 1;
      this.query.type = val;
      this.getLogs();
    },

    buildingChange: function (val) {
      this.query.page = 1;
      this.query.building = val;
      this.getLogs();
    },

    timeLimitChange: function (val) {
      this.query.page = 1;
      this.query.time_limit = val;
      this.getLogs();
    },

    controllerChange: function (val) {
      this.query.page = 1;
      this.query.controller = val;
      this.getLogs();
    },

    kerkifChange: function (val) {
      this.query.page = 1;
      this.query.kerkif = val;
      this.getLogs();
    },

    pageChanged: function (val) {
      if (this.$root.interval != undefined && val != 1) {
        this.refreshing = 0;
        clearInterval(this.$root.interval);
      }
      this.query.page = val;
      this.$router.push({ path: this.$route.path, query: this.query });
    },

    cardSearch: function (val) {
      this.$router.push({ path: "/card/edit/" + val });
    },

    tdStyle: function (log) {
      return {
        "background-color": log.row_background_color,
        color: log.row_text_color,
      };
    },

    getTypes: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("device_logtypes/" + _this.$store.state.token, {
          success: function (response) {
            _this.search.type.items = response.types;
          },
        });
      });
    },

    getBuildings: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax(
          "log_buildings/" +
            _this.$store.state.token +
            "/" +
            _this.building_type,
          {
            success: function (response) {
              _this.search.buildings.items = response.buildings;
            },
          }
        );
      });
    },

    getControllers: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax(
          "log_controllers/" +
            _this.$store.state.token +
            "/" +
            _this.building_type,
          {
            success: function (response) {
              _this.search.controllers.items = response.controllers;
            },
          }
        );
      });
    },

    getLogs: function () {
      var _this = this;

      if (this.waiting == false) {
        this.waiting = true;
        this.$root.checkRootUserHasSet(function () {
          _this.$root.ajax(
            "daemon_logs/" +
              _this.$store.state.token +
              "/0/" +
              _this.building_type,
            {
              method: "post",
              data: _this.query,
              success: function (response) {
                _this.logs = response.logs;
                _this.query.amount = response.amount;
                _this.waiting = false;
              },
            }
          );
        });
      }
    },
  },
};
</script>
