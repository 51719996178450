/* eslint-disable no-unused-vars */
import '@/errorHandler';
import 'babel-polyfill';
import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import './menu';
import http from '@/http';
import '@/mixins';
import '@/directives';
import '@/filters';
import '@/components';
import router from './router';
import { vuetify } from '@/plugins';
import colors from 'vuetify/lib/util/colors';
import config from '@/config';
import variantConfig from './config';

vuetify.framework.theme.themes['dark'] = {
  primary: '#D5AF4B',
  secondary: '#CEA333',
  accent: '#a92d22',
  white: colors.grey.darken4,
  grey: colors.grey.darken3,
  muted: '#272727',
  success: colors.green.darken1,
  error: colors.red.base,
  warning: colors.amber.darken3,
  text: colors.white,
};
vuetify.framework.theme.themes['light'] = {
  primary: '#D5AF4B',
  secondary: '#D5AF4B',
  accent: '#a92d22',
  // white: colors.grey.darken4,
  // grey: colors.grey.darken3,
  // muted: '#321323',
  success: colors.green.darken2,
  error: colors.red.darken1,
  warning: colors.amber.darken3,
  text: colors.black,
};

Vue.config.productionTip = false;

Vue.prototype.$config = { ...config, ...variantConfig };
// Vue.prototype.$dialogs = {};
Vue.prototype.$http = http;

Vue.prototype.$logout = async ({ message, redirect }) => {
  await http.get('auth/logout');
  // store.dispatch('user/set', null, { root: true })
  if (router.currentRoute.name !== 'Login') {
    router.push({
      name: 'Login',
      params: { message, redirect: (redirect || router.currentRoute.fullPath) },
    });
  }
};

window.vue = new Vue({
  data() {
    return {
      user: {},
    };
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
