<template>
  <div class="ma-auto">

    <h1 class="py-6">Kulcskezelés</h1>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-card style="position: sticky; top: 10px">
          <v-card-title>
            <span>
              <v-icon left>mdi-swap-horizontal-bold</v-icon>
              Kulcsok kezelése
            </span>
            <!-- <span v-else>
              <v-btn @click="view = null" icon color="primary" small class="mr-1">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span v-if="view == Return"> Kulcs visszavétele </span>
              <span v-if="view == Request"> Kulcs kiadása </span>
            </span> -->
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-sheet
                  color="primary"
                  rounded
                  class="d-flex flex-column justify-center align-center"
                  height="160"
                  v-ripple
                  dark
                  style="cursor: pointer"
                  @click="view = Request"
                  title="Kártyatulajdonos kulcshoz való hozzáférésének ellenőrzése"
                  elevation="2"
                >
                  <v-icon size="96">mdi-tray-arrow-up</v-icon>
                  Kulcs kiadása
                </v-sheet>
                <!-- <v-btn color="primary" x-large block> Kulcs kiadása </v-btn> -->
              </v-col>
              <v-col cols="6">
                <v-sheet
                  color="primary"
                  rounded
                  class="d-flex flex-column justify-center align-center"
                  height="160"
                  v-ripple
                  dark
                  style="cursor: pointer"
                  @click="view = Return"
                  title="Kulcs visszavétele kártyatulajdonostól"
                  elevation="2"
                >
                  <v-icon size="96">mdi-tray-arrow-down</v-icon>
                  Kulcs visszavétele
                </v-sheet>
                <!-- <v-btn color="primary" x-large block @click="view = Return"
                  >Kulcs visszavétele</v-btn
                > -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title>
            <span>
              <v-icon left>mdi-key-chain</v-icon>
              Kulcsszekrény
            </span>
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              label="Kulcsszekrény"
              filled
              :loading="keyCabinetsLoading"
              :items="keyCabinets"
              v-model="keyCabinetId"
              item-text="name"
              item-value="id"
              :value-comparator="$config.comparators.isEqual"
              :hint="
                !keyCabinetId
                  ? 'Nincs kulcsszekrény kiválasztva. A továbblépéshez válasszon egyet!'
                  : ''
              "
              persistent-hint
            />
            <v-row>
              <!-- <v-col :cols="12" :lg="6">
                <v-card v-if="keyCabinetId" outlined :loading="keysLoading">
                  <v-card-title>Legutóbbi események</v-card-title>
                </v-card>
              </v-col> -->
              <v-col :cols="12" :lg="12">
                <v-card v-if="keyCabinetId" outlined :loading="keysLoading">
                  <v-card-title>Kulcsok</v-card-title>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="key in keys" :key="`key-${key.id}`">
                          <td width="10">
                            <v-badge
                              :title="key.taken_by ? 'A kulcsot elvitték' : 'A kulcs a helyén van'"
                              avatar
                              bordered
                              overlap
                              :color="key.taken_by ? 'error' : 'success'"
                              :icon="key.taken_by ? 'mdi-close' : 'mdi-check'"
                            >
                              <v-avatar size="32" :color="key.color" rounded>
                                <v-icon>mdi-key</v-icon>
                              </v-avatar>
                            </v-badge>
                          </td>
                          <td>
                            <strong>{{ key.name }}</strong
                            ><br />
                            <small>{{ key.remark }}</small>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      :value="view"
      @input="
        (v) => {
          view = v ? view : null;
          if (!view) {
            $asyncComputed.keys.update();
          }
        }
      "
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card elevation="0" class="d-flex flex-column justify-center" color="muted">
        <v-toolbar color="transparent" elevation="0" class="flex-grow-1">
          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-sheet max-width="800" class="mx-auto" color="transparent">
          <component :is="view" @close="closeDialog" @switch-action="v => view = v"></component>
        </v-sheet>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Request from './Request.vue';
import Return from './Return.vue';

export default {
  data() {
    return {
      Request,
      Return,
      keysLoading: false,
      keyCabinetsLoading: false,
      readerDisabled: false,
      response: null,
      lightbox: false,
      keyCabinetId: 0,
      view: null,
      loading: false,
    };
  },

  mounted() {
    this.keyCabinetId = this.keyCabinetId || this.$store.getters.config.default_key_cabinet_id;
  },

  methods: {
    alert(v) {
      console.log(v);
    },

    reset() {
      this.view = null;
      this.readerDisabled = false;
      // this.response = null;
      // this.card.data = null;
      // this.key.data = null;
    },

    async fetchKeys() {
      const response = await this.$http.post('keys/list');
      this.keys = response.keys;
    },

    closeDialog() {
      this.view = null;
    },

    // async checkAccess() {
    //   const res = await this.$http.post('keys/check-access', {
    //     card_element_id: this.card.data.element_id,
    //     key_id: this.key.data.id,
    //   });
    //   this.response = res.permitted ? RESPONSES.permitted : RESPONSES.rejected;
    //   this.readerDisabled = true;
    // },

    // test methods
    // async simulateRead(rfid) {
    //   if (this.readerDisabled) {
    //     return;
    //   }
    //   try {
    //     this.loading = true;
    //     const response = await this.$http.get(`keys/lookup/${rfid}`);
    //     if (!response.key && !response.card) {
    //       throw Error('Ez a kártya vagy kulcs nincs a rendszerben!<br>Kérjük próbálja újra!');
    //     }

    //     this.card.data = response.card ?? this.card.data;
    //     this.key.data = response.key ?? this.key.data;

    //     if (this.key.data?.taken_by) {
    //       this.response = this.card.data ? RESPONSES.taken : RESPONSES.returned;
    //       this.readerDisabled = true;
    //       return;
    //     }

    //     if (this.card.data && this.key.data) {
    //       this.checkAccess();
    //     }
    //   } catch (err) {
    //     this.$dialog.notify.error(err.message, {
    //       position: 'bottom-right',
    //     });
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  },

  asyncComputed: {
    keyCabinets: {
      async get() {
        this.keyCabinetsLoading = true;
        const response = await this.$http.post('key-cabinets/list', { nolimit: true });
        this.keyCabinetsLoading = false;
        return response.key_cabinets;
      },
      default: [],
    },
    keys: {
      async get() {
        this.keysLoading = true;
        const response = await this.$http.post('keys/list', {
          key_cabinet_id: this.keyCabinetId,
          nolimit: true,
        });
        this.keysLoading = false;
        return response.keys;
      },
      default: [],
    },
  },
};
</script>
