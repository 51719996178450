import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm$card$owner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(VCardText, [_c('div', {
    staticClass: "text-h4 text-center mb-6"
  }, [_vm._v("Kulcs kiadása")]), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c(VCard, {
    staticClass: "text-center d-flex flex-column justify-center align-center",
    staticStyle: {
      "position": "relative",
      "overflow": "hidden"
    },
    attrs: {
      "width": "360",
      "height": "180",
      "outlined": ""
    }
  }, [_c(VOverlay, {
    attrs: {
      "value": _vm.loading,
      "absolute": ""
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c(VCardText, [!_vm.card ? _c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c(VIcon, {
    staticClass: "my-6",
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-scan-helper")]), _c(VIcon, {
    staticClass: "center",
    attrs: {
      "size": "48"
    }
  }, [_vm._v("mdi-card-account-details")]), _c('div', {
    staticClass: "spinner center"
  })], 1), _vm._v(" Érintse a kártyát az olvasóhoz! ")]) : _c('div', {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c(VAvatar, {
    attrs: {
      "width": "120",
      "height": "160",
      "color": "grey ligthen-2",
      "rounded": ""
    }
  }, [_vm.card.photo ? _c(VImg, {
    attrs: {
      "src": 'data:image/png;base64, ' + _vm.card.photo
    },
    on: {
      "click": function ($event) {
        _vm.lightbox = true;
      }
    }
  }) : _c(VIcon, {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s((_vm$card$owner = _vm.card.owner) === null || _vm$card$owner === void 0 ? void 0 : _vm$card$owner.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kártyaszám: " + _vm._s(_vm.card.card_number))]), _c('div', {}, [_vm._v("Típus: " + _vm._s(_vm.card.card_rule_name_hu))])])], 1)])], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 6
    }
  }, [_c(VCard, {
    staticClass: "text-center d-flex flex-column justify-center align-center",
    staticStyle: {
      "position": "relative",
      "overflow": "hidden"
    },
    attrs: {
      "width": "360",
      "height": "180",
      "outlined": ""
    }
  }, [_c(VOverlay, {
    attrs: {
      "value": _vm.loading,
      "absolute": ""
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c(VCardText, [!_vm.key ? _c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c(VIcon, {
    staticClass: "my-6",
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-scan-helper")]), _c(VIcon, {
    staticClass: "center",
    attrs: {
      "size": "48"
    }
  }, [_vm._v("mdi-key")]), _c('div', {
    staticClass: "spinner center"
  })], 1), _vm._v(" Érintse a kulcsot az olvasóhoz! ")]) : _c('div', {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c(VAvatar, {
    attrs: {
      "width": "120",
      "height": "160",
      "color": _vm.key.color,
      "rounded": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-key")])], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.key.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(_vm._s(_vm.key.remark))])])], 1)])], 1)], 1)], 1), _c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextarea, {
    attrs: {
      "rows": "3",
      "auto-grow": "",
      "outlined": "",
      "label": "Megjegyzés",
      "autofocus": ""
    },
    model: {
      value: _vm.remark,
      callback: function ($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.result ? _c(VAlert, {
    attrs: {
      "color": _vm.result.color,
      "outlined": "",
      "text": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "100",
      "color": _vm.result.color
    }
  }, [_vm._v(_vm._s(_vm.result.icon))]), _c('div', {
    staticClass: "mt-2 text-h5",
    domProps: {
      "innerHTML": _vm._s(_vm.result.message)
    }
  }), _vm.RESULTS.permitted === _vm.result ? [_c(VDivider, {
    staticClass: "ma-4"
  }), _c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Megtörtént a kulcs kiadása?")]), _vm.result ? _c(VBtn, {
    staticClass: "ma-4",
    attrs: {
      "color": "error",
      "width": "200",
      "depressed": "",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-close-thick")]), _vm._v(" NEM ")], 1) : _vm._e(), _vm.RESULTS.permitted === _vm.result ? _c(VBtn, {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "width": "200",
      "depressed": "",
      "x-large": ""
    },
    on: {
      "click": _vm.taken
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" IGEN ")], 1) : _vm._e()] : _vm._e()], 2) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }