import Vue from 'vue';

const theme = {
  primary: '#bfb48f',
  secondary: '#564e58',
  accent: '#904e55',
  success: '#f2efe9',
  warning: '#252627',
  info: '#17a2b8',
  danger: '#dc3545',
};


Vue.prototype.$vuetify.theme.themes['light'] = theme;
Vue.prototype.$vuetify.theme.themes['dark'] = theme;
