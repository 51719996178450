<template>
  <!-- <div v-if="form.card_owner != ''"> -->
  <div>
    <title-row
      :title="
        $route.name === 'NewCardOwner'
          ? 'Új kártyabirtokos létrehozása'
          : 'Kártyabirtokos szerkesztése'
      "
    >
      <template #buttons>
        <title-row-button
          v-if="hasRight('CARD_OWNER_WRITE') && hasFeature('CARD_OWNER_CREATION')"
          @click="save"
        >
          <v-icon left>mdi-check</v-icon>
          Mentés
        </title-row-button>
      </template>
    </title-row>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title><v-icon left>mdi-clipboard-account</v-icon>Alapadatok</v-card-title>
          <v-card-text>
            <v-masked-text-field
              label="Név"
              :error-messages="errors.name"
              v-model="form.name"
              :mask="$config.inputMasks.name"
              filled
              clearable
              hint="Kötelező"
              persistent-hint
            />
            <v-datetime-picker
              label="Orvosi alkalmassági lejárata"
              v-model="form.validity_end"
              :time-picker="false"
              filled
              hint="Kötelező (Elveszíti a jogosultságait az ezt követő napon)"
              persistent-hint
            />

            <dynamic-field
              v-for="field in extraFields"
              :key="`extra-field-${field.name}`"
              :field="field"
              v-model="form.extra_fields[field.name]"
              :error-messages="errors[field.name]"
              filled
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="6" v-if="$route.name !== 'NewCardOwner'">
        <v-card>
          <v-card-title><v-icon left>mdi-badge-account-horizontal</v-icon>Kártyák</v-card-title>
          <div v-if="form.element_id">
            <v-data-table :headers="headers" :items="cards" hide-default-footer>
              <template #[`item.card_number`]="{ item }">
                <router-link :to="{ name: 'CardEditor', params: { id: item.element_id } }">
                  {{ item.card_number }}
                </router-link>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  fab
                  small
                  color="secondary"
                  depressed
                  class="rounded-sm m-2"
                  :to="{ name: 'CardEditor', params: { id: item.element_id } }"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <v-divider />
          <div class="d-flex justify-center py-4">
            <v-btn
              color="secondary"
              depressed
              class="rounded-sm mt-2"
              :to="{ name: 'NewCard', params: { ownerId: form.element_id } }"
            >
              <v-icon>mdi-plus</v-icon> <span class="mt-1">Hozzáadás</span>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DynamicField from '@/components/DynamicField.vue';

export default {
  components: { DynamicField },

  data() {
    return {
      form: {
        extra_fields: {},
      },
      formSnapshot: null,
      showDetails: false,
      cards: [],
      errors: {},
      idDocTypes: [],
      companies: [],
      loading: false,
      headers: [
        { text: 'Kártyszám', value: 'card_number' },
        { text: 'RF ID', value: 'rf_id' },
        { text: 'Típus', value: 'template_name' },
        { text: 'Státusz', value: 'status_name' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
    };
  },

  mounted() {
    this.fetchCardOwner();
  },

  methods: {
    async fetchCardOwner() {
      this.loading = true;
      try {
        const response = await this.$http.get(`card-owners/details/${this.$route.params.id || 0}`);
        this.form = response.card_owner;
        this.formSnapshot = JSON.stringify(this.form);
        this.cards = response.cards;
        this.idDocTypes = response.id_doc_types;
        this.companies = response.companies;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('card-owners/save', {
          ...this.form,
        });
        if (Object.keys(response.errors).length) {
          return (this.errors = response.errors);
        }
        if (!response.element_id) {
          throw response;
        }
        this.$dialog.notify.success('Sikeres mentés');
        if (this.$route.name === 'NewCardOwner') {
          this.$router.replace({ name: 'CardOwnerEditor', params: { id: response.element_id } });
        }
      } catch (e) {
        this.$dialog.notify.error(e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    extraFields() {
      return this.$store.getters['fields/findByModelName']('CardOwners');
    },

    isDirty() {
      return JSON.stringify(this.form) !== this.formSnapshot;
    },
  },
};
</script>
