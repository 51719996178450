<template>
  <v-app-bar
    fixed
    :height="headerHeight"
    color="white"
    elevation="1"
    app
    class="flex-column flex-wrap align-start justify-start px-4"
  >
    <div v-show="!$store.state.drawer" class="ml-n4 mr-4" transition="slide-x-transition">
      <v-sheet
        color="primary"
        dark
        :height="headerHeight - 4"
        :width="headerHeight"
        class="d-flex align-center justify-center"
      >
        <v-icon size="28" class="mx-4">mdi-shield-key-outline</v-icon>
      </v-sheet>
      <v-sheet :width="headerHeight" height="4" color="primary darken-1"></v-sheet>
    </div>

    <v-app-bar-nav-icon @click="$store.state.drawer = !$store.state.drawer" class="rounded-sm" />

    <v-btn icon tile @click="$store.commit('openSearch')">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-spacer />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          small
          height="40"
          class="mx-1 rounded-sm"
          :color="uniomd_unreachable ? 'error' : 'success'"
          v-bind="attrs"
          v-on="on"
        >
          <span class="d-sm-inline-block d-none">Státusz</span>
          <template v-if="uniomd_unreachable">
            <v-icon right color="error">mdi-alert-circle</v-icon>
          </template>
          <template v-else>
            <v-icon right color="success">mdi-check-circle</v-icon>
          </template>
        </v-btn>
      </template>
      <span> Szolgáltatás állapota: {{ uniomd_unreachable ? 'HIBA' : 'OK' }} </span>
    </v-tooltip>

    <v-menu transition="slide-y-transition" right :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text small height="40" class="mx-1 px-1 rounded-sm" v-bind="attrs" v-on="on">
          <v-avatar color="grey lighten-4" size="32" class="rounded-sm">
            <v-icon size="24" color="grey">mdi-account</v-icon>
          </v-avatar>
          <div class="data text-left mx-2">
            <div class="font-weight-bold text-subtitle-2" style="text-transform: none">
              {{ $store.state.user.name }}
            </div>
          </div>
          <v-icon size="24">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-card outlined>
        <v-sheet color="white" class="d-flex pa-2">
          <v-avatar color="grey lighten-4" size="64" class="rounded-sm">
            <v-icon size="48" color="grey">mdi-account</v-icon>
          </v-avatar>

          <div class="d-flex flex-column justify-center pa-2 ml-2">
            <div class="font-weight-bold">
              {{ $store.state.user.name }}
            </div>
            <div>
              {{ $store.state.user.email }}
            </div>
          </div>
        </v-sheet>

        <div class="d-flex flex-column justify-center pa-2">
          <v-btn text small block to="/change-password" height="36" class="mb-1">
            <v-icon left>mdi-key-variant</v-icon>
            Jelszó módosítás
          </v-btn>

          <v-btn text small block height="36" class="mb-1" @click="$store.commit('toggleDarkMode')">
            <template v-if="$vuetify.theme.dark">
              <v-icon left>mdi-white-balance-sunny</v-icon>
              Váltás világos módra
            </template>
            <template v-else>
              <v-icon left>mdi-weather-night</v-icon>
              Váltás sötét módra
            </template>
          </v-btn>
        </div>

        <v-sheet color="primary" height="2" />

        <v-sheet color="muted" class="pa-2">
          <v-btn text block small @click="$logout">
            <v-icon left>mdi-power</v-icon>
            Kijelentkezés
          </v-btn>
        </v-sheet>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    statusInterval: null,
    unreachable_gates: false,
    daemon_unreachable: false,
    uniomd_unreachable: false,
  }),

  mounted() {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    clearInterval(this.statusInterval);
    this.checkCheckerLog();
    this.statusInterval = setInterval(() => {
      this.checkCheckerLog();
    }, 60000);
  },

  computed: {
    headerHeight() {
      return parseInt(process.env.VUE_APP_HEADER_HEIGHT);
    },
  },

  methods: {
    async checkCheckerLog() {
      const response = await this.$http.get('check_checkerlog');
      this.unreachable_gates = response.unreachable_gates;
      this.daemon_unreachable = response.daemon_unreachable;
      this.uniomd_unreachable = response.uniomd_unreachable;
    },
  },

  destroyed() {
    clearInterval(this.statusInterval);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
  padding: 0 !important;
}
.v-application header.white {
  width: auto;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-color: var(--v-muted-base) !important;
}

#profile-button .data > div {
  line-height: 14px;
}

#server-status-success {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 0.75;
    transform: scale(0.9);
  }
}
</style>
