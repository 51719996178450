import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey",
      "dark": "",
      "title": "Vissza"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Funkciók")]), _c(VCard, [_c(VSimpleTable, {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Név ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Alias ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Engedélyezve ")])])]), _c('tbody', _vm._l(_vm.features, function (feature) {
          return _c('tr', {
            key: feature.alias
          }, [_c('td', [_vm._v(_vm._s(feature.name))]), _c('td', [_vm._v(_vm._s(feature.alias))]), _c('td', [_c(VCheckbox, {
            model: {
              value: feature.enabled,
              callback: function ($$v) {
                _vm.$set(feature, "enabled", $$v);
              },
              expression: "feature.enabled"
            }
          })], 1)]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }