import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Parkolójegyek")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "RF ID / Kód",
      "mask": _vm.$config.inputMasks.barcodeRfId,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.rf_id_code,
      callback: function ($$v) {
        _vm.$set(_vm.search, "rf_id_code", $$v);
      },
      expression: "search.rf_id_code"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Rendszám",
      "mask": _vm.$config.inputMasks.plateNumber,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.license_plate_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "license_plate_number", $$v);
      },
      expression: "search.license_plate_number"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.rf_id_code",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_c('small', [_vm._v(_vm._s(item.rf_id))]), _c('br'), _vm._v(" " + _vm._s(item.barcode_number) + " ")])];
      }
    }, {
      key: "item.payment",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.exonerated ? _c('span', [_c(VIcon, [_vm._v("mdi-information")]), _vm._v(" Fizetés alól felmentve ")], 1) : _c('span', [_vm._v(_vm._s(item.end_date))])];
      }
    }, {
      key: "item.exit_datetime",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.exit_datetime === false ? _c('span', [_vm._v("-")]) : _c('span', [_vm._v(_vm._s(item.exit_datetime))])];
      }
    }, {
      key: "item.cost",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.exit_datetime || item.exonerated ? _c('span', [_vm._v("0 Ft")]) : _c('span', [_vm._v(_vm._s(parseInt(item.fee).toLocaleString()) + " Ft")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.hasRight('PARKING_TICKET_WRITE') && !item.exit_datetime && item.end_date !== '9999-12-31 00:00:00' ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "x-small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "disabled": _vm.loading,
            "title": "Fizetési kötelezettség elengedése"
          },
          on: {
            "click": function ($event) {
              return _vm.exonerationClick(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-currency-usd-off")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c(VDialog, {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VAppBar, {
    staticClass: "mb-6",
    attrs: {
      "flat": "",
      "color": "primary"
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": "",
      "left": ""
    }
  }, [_vm._v("mdi-currency-usd-off")]), _c(VToolbarTitle, {
    staticClass: "text-h6 white--text pl-0"
  }, [_vm._v(" Fizetési kötelezettség elengedése ")])], 1), _c(VCardText, {
    staticClass: "pb-0 px-9"
  }, [_c(VRow, {
    staticClass: "mb-3"
  }, [_vm._v(" Az alábbi parkolójegy mentesül minden fizetési kötelezettség alól. ")]), _c(VRow, [_c(VCol, {
    staticClass: "p-0",
    attrs: {
      "cols": 12,
      "md": 6,
      "sm": 12
    }
  }, [_c('b', [_vm._v("RF ID:")])]), _c(VCol, {
    staticClass: "p-0",
    attrs: {
      "cols": 12,
      "md": 6,
      "sm": 12
    }
  }, [_vm._v(" " + _vm._s(_vm.exoneration.ticket.rf_id) + " ")])], 1), _c(VRow, [_c(VCol, {
    staticClass: "p-0",
    attrs: {
      "cols": 12,
      "md": 6,
      "sm": 12
    }
  }, [_c('b', [_vm._v("Behajtás ideje:")])]), _c(VCol, {
    staticClass: "p-0",
    attrs: {
      "cols": 12,
      "md": 6,
      "sm": 12
    }
  }, [_vm._v(" " + _vm._s(_vm.exoneration.ticket.created) + " ")])], 1), _c(VRow, {
    staticClass: "pt-3"
  }, [_c(VTextarea, {
    attrs: {
      "filled": "",
      "auto-grow": "",
      "rows": "3",
      "label": "Indoklás (opcionális)"
    },
    model: {
      value: _vm.exoneration.ticket.remark,
      callback: function ($$v) {
        _vm.$set(_vm.exoneration.ticket, "remark", $$v);
      },
      expression: "exoneration.ticket.remark"
    }
  })], 1)], 1), _c(VDivider, {
    staticClass: "mb-0 pb-0"
  }), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Mégse ")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start"
    },
    on: {
      "click": _vm.exonerationHandler
    }
  }, [_vm._v(" Elfogadás ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }