import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "grey",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Időszak")]), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardText, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VRow, {
    staticClass: "m-1"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Kezdő dátum",
      "error-messages": _vm.errors.start_date,
      "timePicker": false,
      "datePicker": "",
      "filled": ""
    },
    model: {
      value: _vm.form.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "start_date", $$v);
      },
      expression: "form.start_date"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Záró dátum",
      "error-messages": _vm.errors.end_date,
      "timePicker": false,
      "datePicker": "",
      "filled": ""
    },
    model: {
      value: _vm.form.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "end_date", $$v);
      },
      expression: "form.end_date"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }