<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn
        href="/static/installers/uniom_card_reader_setup.exe"
        download
        height="60"
        depressed
        tile
        color="muted darken-1"
        class="d-none d-md-flex"
      >
        <v-icon dark left>mdi-download</v-icon>
        Kártyaolvasó illesztő letöltése
      </v-btn>
    </div>

    <h1 class="py-6">Üdvözöljük</h1>
    <div class="normal-text mb-8">
      A rendszer használatához válasszon a bal oldali menüpontok közül!
    </div>

    <!-- <v-row class="d-flex align-stretch">
      <v-col :md="4" :cols="12">
        <v-card class="card">
          <v-overlay
            absolute
            color="white"
            opacity="0.5"
            light
            :value="cards.loading && barcodes.loading"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title> Parkolóhelyek </v-card-title>
          <v-card-subtitle> Szabad / Összes </v-card-subtitle>
          <v-card-text class="display-1">
            {{
              555 -
              (cards.inside +
                barcodes.tickets_inside +
                barcodes.qr_codes_inside)
            }}
            / 555
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="card">
          <v-overlay
            absolute
            color="white"
            opacity="0.1"
            light
            :value="cards.loading && barcodes.loading"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title> Bent tartózkodók száma </v-card-title>
          <v-card-subtitle> Bérlettel / Jeggyel / Kóddal </v-card-subtitle>
          <v-card-text class="display-1">
            {{ cards.inside }} / {{ barcodes.tickets_inside }} /
            {{ barcodes.qr_codes_inside }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="card">
          <v-overlay
            absolute
            color="white"
            opacity="0.5"
            light
            :value="barcodes.loading"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title> Átlagos parkolási idő </v-card-title>
          <v-card-subtitle>
            Parkolójegy váltásától a kihajtásig
          </v-card-subtitle>
          <v-card-text class="display-1">
            {{ avgParkingTime }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetchInterval: null,
      cards: {
        loading: true,
        inside: 0,
      },
      barcodes: {
        loading: true,
        tickets_inside: 0,
        qr_codes_inside: 0,
        avg_parking_time: 0,
      },
    };
  },
  mounted() {
    this.fetchInterval = setInterval(() => {
      this.getBarcodeStats();
      this.getCardStats();
    }, 60 * 1000);
    setTimeout(() => {
      this.getBarcodeStats();
      this.getCardStats();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.fetchInterval);
  },
  methods: {
    async getBarcodeStats() {
      try {
        const response = await this.$http.get("parking-tickets/stats");
        this.barcodes.tickets_inside = response.tickets_inside;
        this.barcodes.qr_codes_inside = response.qr_codes_inside;
        this.barcodes.avg_parking_time = response.avg_parking_time;
      } catch (e) {
        console.error(e);
      } finally {
        this.barcodes.loading = false;
      }
    },
    async getCardStats() {
      try {
        const response = await this.$http.get("cards/stats");
        this.cards.inside = response.in_with_season_ticket;
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          this.cards.loading = false;
        }, 4000);
      }
    },
    setupDownload() {
      window.open("/cardReaderSetup.zip");
    },
  },
  computed: {
    avgParkingTime() {
      const minutes = Math.ceil(this.barcodes.avg_parking_time / 60) || 65;

      let avg = {
        hours: Math.floor(minutes / 60),
        minutes: minutes % 60,
      };

      return (
        (avg.hours ? avg.hours + " óra " : "") +
        (avg.minutes ? avg.minutes + " perc" : "")
      );
    },
  },
};
</script>
