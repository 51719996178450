import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "title": "Importálás fájlból"
    },
    on: {
      "click": function ($event) {
        _vm.showImportDialog = true;
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-import")])], 1), _vm.hasRight('KEY_WRITE') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewKey'
      },
      "title": "Új kulcs"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcs lista")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2",
    attrs: {
      "align": "end"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Kulcsszekrény",
      "items": _vm.key_cabinets,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.key_cabinet_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "key_cabinet_id", $$v);
      },
      expression: "search.key_cabinet_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 2,
      "md": 2
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Használatban",
      "items": [{
        text: 'Igen',
        value: true
      }, {
        text: 'Nem',
        value: false
      }],
      "value-comparator": _vm.$config.comparators.isEqual,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.taken,
      callback: function ($$v) {
        _vm.$set(_vm.search, "taken", $$v);
      },
      expression: "search.taken"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "accent",
      "depressed": ""
    },
    on: {
      "click": _vm.fetchItems
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Szűrés ")], 1)], 1)], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.taken_by ? _c(VIcon, {
          attrs: {
            "color": "error",
            "title": ("A kulcsot elvitte: " + (item.taken_by_name) + " (" + (item.taken_by_card_number) + ")")
          }
        }, [_vm._v(" mdi-close-box ")]) : _c(VIcon, {
          attrs: {
            "color": "success",
            "title": "A kulcs a helyén van"
          }
        }, [_vm._v("mdi-checkbox-marked")])];
      }
    }, {
      key: "item.taken_by",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.taken_by_name))]), _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(item.taken_by_card_number))])])];
      }
    }, {
      key: "item.name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c(VChip, {
          staticClass: "key-tag pl-3 pr-0",
          attrs: {
            "color": ("" + (item.color))
          }
        }, [_c(VSheet, {
          staticClass: "key-tag-label px-2 py-1",
          attrs: {
            "label": "",
            "color": "white",
            "rounded": ""
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c(VChip, {
          staticClass: "key-tag-hole px-2 mx-1",
          attrs: {
            "color": "white",
            "x-small": ""
          }
        })], 1)];
      }
    }, {
      key: "item.cabinets",
      fn: function (_ref4) {
        var item = _ref4.item;
        return _vm._l(item.cabinets, function (cabinet) {
          return _c(VChip, {
            key: cabinet.id,
            staticClass: "ma-1",
            attrs: {
              "label": "",
              "link": "",
              "small": "",
              "to": {
                name: 'KeyCabinetList',
                params: {
                  highlight: [cabinet.id]
                }
              }
            }
          }, [_vm._v(" " + _vm._s(cabinet.name) + " ")]);
        });
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.hasRight('KEY_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm mr-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "to": {
              name: 'KeyEditor',
              params: {
                id: item.id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('KEY_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm ma-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "title": "Archiválás"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteKey(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-archive")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('import-dialog', {
    on: {
      "close": function ($event) {
        _vm.showImportDialog = false;
      },
      "success": _vm.fetchKeys
    },
    model: {
      value: _vm.showImportDialog,
      callback: function ($$v) {
        _vm.showImportDialog = $$v;
      },
      expression: "showImportDialog"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }