<template>
  <div>
    <slot name="header" v-bind="{ search }"></slot>
    <v-row>
      <v-col :cols="12" :md="6">
        <v-card outlined>
          <v-card-title>Kiválasztott elemek</v-card-title>
          <v-data-table
            :headers="[...headers, { value: 'actions', width: '56px', sortable: false }]"
            :items="selectedFilteredItems"
            :options.sync="tableOptions"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                fab
                small
                depressed
                color="secondary"
                class="rounded-sm"
                title="Eltávolítás"
                @click="removeItem(item)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <template v-for="header in headers" #[`item.${header.value}`]="{ item, index }">
              <slot :name="`item.${header.value}`" v-bind="{ item, index }" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-card outlined>
          <v-card-title>Választható elemek</v-card-title>
          <v-data-table
            :headers="[{ value: 'actions', width: '56px', sortable: false }, ...headers]"
            :items="remainingItems"
            :options.sync="tableOptions"
          >
            <template v-for="header in headers" #[`item.${header.value}`]="{ item, index }">
              <slot :name="`item.${header.value}`" v-bind="{ item, index }" />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                fab
                small
                depressed
                color="secondary"
                class="rounded-sm"
                title="Hozzáadás"
                @click="selectedItems.push(item)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 10,
        sortBy: 'name',
        sortDesc: false,
      }),
    },
  },


  data() {
    return {
      tableOptions: this.options,
      selectedItems: [],
      search: {},
    };
  },

  methods: {
    removeItem(item) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.lodash.isEqual(this.selectedItems[i], item)) {
          this.selectedItems.splice(i, 1);
          break;
        }
      }
    },

    find(search, items) {
      const results = [];
      for (const item of items) {
        if (!Object.entries(search).length) {
          results.push(item);
          continue;
        }
        let match = false;
        for (const [key, value] of Object.entries(search)) {
          if (item[key].toLowerCase().includes(value.toLowerCase())) {
            match = true;
          }
        }
        if (match) {
          results.push(item);
        }
      }
      return results;
    }
  },

  computed: {
    selectedFilteredItems() {
      return this.find(this.search, this.selectedItems).filter((item) => this.selectedItems.includes(item));
    },

    remainingItems() {
      return this.lodash.differenceWith(
        this.find(this.search, this.items),
        this.selectedItems,
        this.lodash.isEqual
      );
    },
  },
};
</script>
