import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VNavigationDrawer, {
    staticClass: "elevation-1",
    attrs: {
      "width": _vm.sidebarWidth,
      "app": "",
      "floating": "",
      "fixed": "",
      "mobile-breakpoint": 840
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'Home'
            }
          }
        }, [_c(VHover, {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var hover = _ref.hover;
              return [_c(VSheet, {
                staticClass: "d-flex align-center",
                attrs: {
                  "fab": "",
                  "tile": "",
                  "width": _vm.sidebarWidth,
                  "height": _vm.headerHeight,
                  "color": ("primary darken-" + (hover ? 1 : 5)),
                  "dark": ""
                }
              }, [_c(VIcon, {
                staticClass: "mx-4",
                attrs: {
                  "size": "36"
                }
              }, [_vm._v("mdi-shield-key-outline")]), _c('div', {
                staticClass: "text-left"
              }, [_c('div', {
                staticClass: "font-weight-bold"
              }, [_vm._v("UNIOM")]), _c('div', {
                staticClass: "caption"
              }, [_vm._v("Beléptetőrendszer")])])], 1)];
            }
          }])
        })], 1), _c(VSheet, {
          attrs: {
            "height": "4",
            "color": "primary darken-1"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c(VDivider, {
          staticClass: "ma-0"
        }), _c(VSheet, {
          staticClass: "d-flex justify-center align-center pa-3",
          attrs: {
            "flat": "",
            "dark": "",
            "height": _vm.footerHeight
          }
        }, [_c(VBtn, {
          attrs: {
            "large": "",
            "text": "",
            "block": ""
          },
          on: {
            "click": _vm.$logout
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-exit-run")]), _vm._v(" Exit ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('sidebar-menu', {
    attrs: {
      "items": _vm.menu
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }