<template>
  <v-dialog :value="true" width="1280" :persistent="true" :overlay-opacity="0.9" dark>
    <v-card>
      <v-card-title>
        {{ barrier.name }}
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close" x-small fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <stream-player
        :server-address="barrier.stream_server_address"
        :stream-id="barrier.stream_id"
        :css-filters="barrier.css_filters"
        :url="`${barrier.stream_id}?key=${$store.state.token}`"
      />

      <v-divider class="mb-0 pb-0"></v-divider>
      <v-card-actions style="overflow-x: auto">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!permanentOpen"
          color="primary"
          text
          align="start"
          @click="
            sequentialInvoker(
              { fn: openEventHandler, args: [barrier] },
              { fn: fetchBarrierDetails }
            )
          "
          title="Kinyitás"
        >
          <v-icon left>mdi-lock-open</v-icon>
          Nyitás
        </v-btn>
        <v-btn
          v-if="!permanentOpen && !barrier.nciom_control_seq_id"
          color="primary"
          text
          align="start"
          @click="
            sequentialInvoker(
              { fn: permanentOpenEventHandler, args: [barrier] },
              { fn: fetchBarrierDetails }
            )
          "
          title="Tartós nyitás"
        >
          <v-icon left>mdi-lock-open-variant</v-icon>
          Tartós nyitás
        </v-btn>
        <v-btn
          v-if="permanentOpen"
          color="primary"
          text
          align="start"
          @click="
            sequentialInvoker(
              { fn: normalOperationDatetimeEventHandler, args: [barrier] },
              { fn: fetchBarrierDetails }
            )
          "
          title="Normál üzem időpont"
        >
          <v-icon left>mdi-lock</v-icon>
          Normál üzem ütemzése
        </v-btn>
        <v-btn
          v-if="permanentOpen"
          color="primary"
          text
          align="start"
          @click="
            sequentialInvoker(
              { fn: normalOperationNowEventHandler, args: [barrier] },
              { fn: fetchBarrierDetails }
            )
          "
          title="Normál üzem azonnal"
        >
          <v-icon left>mdi-lock</v-icon>
          Normál üzem azonnal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StreamPlayer from '@/components/StreamPlayerMSE.vue';

export default {
  components: {
    StreamPlayer,
  },

  props: {
    barrier: {
      type: [Object],
      required: true,
    },
    openEventHandler: {
      type: [Function],
      required: true,
    },
    permanentOpenEventHandler: {
      type: [Function],
      required: true,
    },
    normalOperationDatetimeEventHandler: {
      type: [Function],
      required: true,
    },
    normalOperationNowEventHandler: {
      type: [Function],
      required: true,
    },
  },

  data() {
    return {
      feedUrl: null,
      details: false,
      open: 0,
      permanentOpen: false,
    };
  },

  mounted() {
    this.fetchBarrierDetails();
  },

  methods: {
    async sequentialInvoker(...methods) {
      for (const m of methods) {
        if (m.args) {
          await m.fn(...m.args);
        } else {
          await m.fn();
        }
      }
    },

    async fetchBarrierDetails() {
      try {
        const response = await this.$http.get(`barriers/details/${this.barrier.element_id}`);
        this.open = response.barrier.open;
        this.permanentOpen = response.barrier.permanent_open;
      } catch (e) {
        console.error(e);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
