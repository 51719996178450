import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky"
  }, [_c(VBtn, {
    staticClass: "d-none d-md-flex",
    attrs: {
      "href": "/static/installers/uniom_card_reader_setup.exe",
      "download": "",
      "height": "60",
      "depressed": "",
      "tile": "",
      "color": "muted darken-1"
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": "",
      "left": ""
    }
  }, [_vm._v("mdi-download")]), _vm._v(" Kártyaolvasó illesztő letöltése ")], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Üdvözöljük")]), _c('div', {
    staticClass: "normal-text mb-8"
  }, [_vm._v(" A rendszer használatához válasszon a bal oldali menüpontok közül! ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }