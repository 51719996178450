import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.options.page != 1,
      "title": _vm.options.page == 1 ? '' : 'A frissítés opció csak az első oldalon működik'
    },
    on: {
      "click": _vm.togglePolling
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_vm.polling ? _c(VIcon, [_vm._v("mdi-stop")]) : _c(VIcon, [_vm._v("mdi-play")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcsnapló")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2",
    attrs: {
      "align": "end"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Szekrény",
      "items": _vm.cabinets,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "Összes",
      "multiple": ""
    },
    on: {
      "change": _vm.periodChangeEventHandler
    },
    model: {
      value: _vm.search.cabinet_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "cabinet_id", $$v);
      },
      expression: "search.cabinet_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Név / Kártyaszám",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.periodChangeEventHandler
    },
    model: {
      value: _vm.search.keyphrase,
      callback: function ($$v) {
        _vm.$set(_vm.search, "keyphrase", $$v);
      },
      expression: "search.keyphrase"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 2,
      "lg": 2
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Használatban",
      "items": [{
        text: 'Igen',
        value: true
      }, {
        text: 'Nem',
        value: false
      }],
      "dense": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.taken,
      callback: function ($$v) {
        _vm.$set(_vm.search, "taken", $$v);
      },
      expression: "search.taken"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": _vm.search.period === 'custom' ? 1 : 2
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Időkorlát",
      "items": _vm.timePeriods,
      "item-text": "label",
      "item-value": "value",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.periodChangeEventHandler
    },
    model: {
      value: _vm.search.period,
      callback: function ($$v) {
        _vm.$set(_vm.search, "period", $$v);
      },
      expression: "search.period"
    }
  })], 1), _vm.search.period === 'custom' ? [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát kezdete",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedStartDates
      },
      "time-picker": false,
      "error-messages": _vm.search.from ? '' : 'Kötelező!'
    },
    on: {
      "change": _vm.periodChangeEventHandler
    },
    model: {
      value: _vm.search.from,
      callback: function ($$v) {
        _vm.$set(_vm.search, "from", $$v);
      },
      expression: "search.from"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 2,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Időkorlát vége",
      "clearable": "",
      "dense": "",
      "date-picker-props": {
        allowedDates: _vm.allowedEndDates
      },
      "time-picker": false,
      "disabled": !_vm.search.from,
      "error-messages": _vm.search.from && !_vm.search.to ? 'Kötelező!' : ''
    },
    on: {
      "change": _vm.periodChangeEventHandler
    },
    model: {
      value: _vm.search.to,
      callback: function ($$v) {
        _vm.$set(_vm.search, "to", $$v);
      },
      expression: "search.to"
    }
  })], 1)] : _vm._e(), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 2,
      "md": 2
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "accent",
      "depressed": ""
    },
    on: {
      "click": _vm.fetchItems
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Szűrés ")], 1)], 1)], 2)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.created",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.created, _vm.$config.momentFormats.explicit)
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(item.created, 'lll')))])];
      }
    }, {
      key: "item.event",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.event === 'take' ? _c(VChip, {
          attrs: {
            "color": "red",
            "dark": ""
          }
        }, [_vm._v("Kiadás")]) : item.event === 'return' ? _c(VChip, {
          attrs: {
            "color": "green",
            "dark": ""
          }
        }, [_vm._v("Visszavétel")]) : item.event === 'request' ? _c(VChip, [_vm._v("Kérelem (" + _vm._s(item.card_rfid) + ")")]) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.element_id && _vm.hasRight('CARD_READ') ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            },
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "text": ""
          }
        }, [_c(VIcon, [_vm._v("mdi-card-account-details")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }