import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcsszekrény")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file-cabinet")]), _vm._v(" Alapadatok ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Épület",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "filled": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Szint",
      "items": _vm.building_levels,
      "item-text": "name",
      "item-value": "id",
      "disabled": !_vm.form.building_id,
      "hint": !_vm.form.building_id ? 'Épület kiválasztása kötelező' : '',
      "persistent-hint": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "filled": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "name": "name",
      "filled": "",
      "auto-grow": "",
      "rows": 5
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key-chain")]), _vm._v(" Kulcsok ")], 1), _c(VCardText, [_c('v-two-panel-multiselect', {
    attrs: {
      "headers": [{
        text: 'Név',
        value: 'name'
      }, {
        text: 'Megjegyzés',
        value: 'remark'
      }],
      "options": {
        sortBy: ['card_number']
      },
      "items": _vm.keys,
      "item-value": "element_id"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var search = _ref.search;
        return [_c(VCard, {
          staticClass: "mb-6",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter")]), _vm._v(" Keresés ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Név",
            "hide-details": ""
          },
          model: {
            value: search.name,
            callback: function ($$v) {
              _vm.$set(search, "name", $$v);
            },
            expression: "search.name"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Megjegyzés",
            "hide-details": ""
          },
          model: {
            value: search.remark,
            callback: function ($$v) {
              _vm.$set(search, "remark", $$v);
            },
            expression: "search.remark"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.form.keys,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keys", $$v);
      },
      expression: "form.keys"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }