import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form.gateway != undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _vm.$route.name === 'NewGateway' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új gateway")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Gateway szerkesztése")]), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Gateway adatok")]), _c(VCardText, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.gateway.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "name", $$v);
      },
      expression: "form.gateway.name"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "label",
      "item-value": "value",
      "filled": ""
    },
    model: {
      value: _vm.form.gateway.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "building_id", $$v);
      },
      expression: "form.gateway.building_id"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Folyamat név",
      "error-messages": _vm.errors.process_name,
      "filled": ""
    },
    model: {
      value: _vm.form.gateway.process_name,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "process_name", $$v);
      },
      expression: "form.gateway.process_name"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Cím",
      "error-messages": _vm.errors.address,
      "mask": _vm.$config.inputMasks.gatewayAdddress,
      "filled": ""
    },
    model: {
      value: _vm.form.gateway.address,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "address", $$v);
      },
      expression: "form.gateway.address"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Port",
      "error-messages": _vm.errors.port,
      "filled": "",
      "mask": _vm.$config.inputMasks.integerNumber
    },
    model: {
      value: _vm.form.gateway.port,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "port", $$v);
      },
      expression: "form.gateway.port"
    }
  }), _c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": ""
    },
    model: {
      value: _vm.form.gateway.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form.gateway, "comment", $$v);
      },
      expression: "form.gateway.comment"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }