import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm$selectedKey;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mx-6"
  }, [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcsfoglalások")]), _c(VCard, {
    staticClass: "mb-6"
  }, [_c(VCardText, [_c(VAutocomplete, {
    attrs: {
      "label": "Kulcs",
      "items": _vm.keys,
      "item-text": "name",
      "item-value": "id",
      "return-object": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.selectedKey,
      callback: function ($$v) {
        _vm.selectedKey = $$v;
      },
      expression: "selectedKey"
    }
  })], 1)], 1), _c(VCard, {
    staticClass: "relative",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VOverlay, {
    attrs: {
      "value": !_vm.selectedKey,
      "absolute": "",
      "opacity": "0.8"
    }
  }, [_vm._v(" A foglalások megtekintéséhez válasszon ki egy kulcsot! ")]), _c(VCardTitle, [_c(VBtn, {
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "tile": ""
    },
    on: {
      "click": _vm.prev
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-chevron-left ")])], 1), _c(VBtn, {
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "tile": ""
    },
    on: {
      "click": _vm.next
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-chevron-right ")])], 1), _vm.focus.length ? _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.setToday
    }
  }, [_vm._v(" Ma ")]) : _vm._e(), _vm.cal ? _c(VToolbarTitle, [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]) : _vm._e(), _c(VSpacer), _c(VMenu, {
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('span', [_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]), _c(VIcon, {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" mdi-menu-down ")])], 1)];
      }
    }])
  }, [_c(VList, [_c(VListItem, {
    on: {
      "click": function ($event) {
        _vm.type = 'day';
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Napi nézet")])], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        _vm.type = 'week';
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Heti nézet")])], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        _vm.type = 'month';
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Havi nézet")])], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        _vm.type = '4day';
      }
    }
  }, [_c(VListItemTitle, [_vm._v("4 napos nézet")])], 1)], 1)], 1)], 1), _c(VRow, [_c(VCol, [_c(VSheet, {
    attrs: {
      "max-height": _vm.type === 'month' ? 800 : 24 * 28,
      "height": _vm.calendarHeight
    }
  }, [_c(VCalendar, {
    ref: "calendar",
    attrs: {
      "event-color": _vm.color,
      "event-height": 40,
      "event-name": function (e) {
        return e.name ? e.name : 'Nincs megadva';
      },
      "events": _vm.keyReservations,
      "first-interval": 0,
      "interval-count": 24,
      "interval-height": 24,
      "interval-minutes": 60,
      "last-interval": 24,
      "type": _vm.type,
      "start": _vm.startDate,
      "weekdays": "1,2,3,4,5,6,0",
      "event-end": "end_datetime",
      "event-start": "start_datetime"
    },
    on: {
      "click:event": _vm.showReservation,
      "click:time": _vm.addEvent
    },
    scopedSlots: _vm._u([{
      key: "day-body",
      fn: function (_ref2) {
        var date = _ref2.date,
          week = _ref2.week;
        return [_c('div', {
          staticClass: "v-current-time",
          class: {
            first: date === week[0].date
          },
          style: {
            top: _vm.nowY
          }
        })];
      }
    }, {
      key: "day-label",
      fn: function (_ref3) {
        var day = _ref3.day,
          present = _ref3.present;
        return [present ? _c(VAvatar, {
          staticClass: "white--text font-weight-bold",
          attrs: {
            "rounded": "",
            "size": "40",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(day) + " ")]) : _c(VAvatar, {
          attrs: {
            "size": "40",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(day) + " ")])];
      }
    }, {
      key: "day-label-header",
      fn: function (_ref4) {
        var day = _ref4.day,
          present = _ref4.present;
        return [present ? _c(VAvatar, {
          staticClass: "white--text font-weight-bold",
          attrs: {
            "rounded": "",
            "size": "40",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(day) + " ")]) : _c(VAvatar, {
          attrs: {
            "size": "40",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(day) + " ")])];
      }
    }]),
    model: {
      value: _vm.focus,
      callback: function ($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  })], 1)], 1)], 1)], 1), _c('reservation-dialog', {
    ref: "reservationDialog",
    attrs: {
      "keyId": (_vm$selectedKey = _vm.selectedKey) === null || _vm$selectedKey === void 0 ? void 0 : _vm$selectedKey.id
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }