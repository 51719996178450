import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$root.profilmenu ? _c('div', {
    staticClass: "profile-overlay-menu",
    style: _vm.divStyle
  }, [_c('div', {
    staticClass: "om-content"
  }, [_c('div', {
    staticClass: "user-data d-flex"
  }, [_c(VAvatar, {
    attrs: {
      "color": "grey lighten-4 mr-2",
      "size": "60"
    }
  }, [_c(VIcon, {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-account")])], 1), _c(VList, {
    attrs: {
      "dense": ""
    }
  }, [_c(VListItem, {
    attrs: {
      "to": {
        path: '/change_password'
      }
    }
  }, [_c(VListItemIcon, {
    staticClass: "mr-1"
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-key")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("Jelszó módosítás")])], 1)], 1)], 1)], 1)]), _c('router-link', {
    staticClass: "logout",
    attrs: {
      "to": {
        path: '/logout'
      }
    }
  }, [_c('i', {
    staticClass: "customicon customicon-power-off"
  }), _vm._v("Kijelentkezés")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }