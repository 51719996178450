import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey",
      "dark": "",
      "title": "Vissza"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Zóna")]), _c(VCard, {
    staticClass: "mb-6",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-fence")]), _vm._v(" Alapadatok ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.remark,
      "filled": "",
      "rows": 3,
      "auto-grow": ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-smart-card-reader")]), _vm._v(" Olvasók ")], 1), _c(VCardText, [_c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Megnevezés',
        value: 'name'
      }, {
        text: 'Irány',
        value: 'direction'
      }],
      "items": _vm.readers,
      "item-value": "element_id",
      "item-text": "name",
      "unique-values": "",
      "blank-item-template": {
        element_id: 0
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item,
          items = _ref.items,
          index = _ref.index;
        return [_c(VAutocomplete, {
          attrs: {
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "placeholder": "Válassz",
            "error-messages": _vm.errors[("reader-" + index + "-element_id")],
            "hide-details": !_vm.errors[("reader-" + index + "-element_id")] && !_vm.errors[("reader-" + index + "-direction")],
            "filled": "",
            "dense": "",
            "value-comparator": _vm.$config.comparators.isEqual
          },
          model: {
            value: item.element_id,
            callback: function ($$v) {
              _vm.$set(item, "element_id", $$v);
            },
            expression: "item.element_id"
          }
        })];
      }
    }, {
      key: "item.direction",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [_c(VSelect, {
          attrs: {
            "items": [{
              text: 'Bejárat',
              value: 'I'
            }, {
              text: 'Kijárat',
              value: 'O'
            }],
            "item-text": "text",
            "item-value": "value",
            "placeholder": "Válassz",
            "error-messages": _vm.errors[("reader-" + index + "-direction")],
            "hide-details": !_vm.errors[("reader-" + index + "-element_id")] && !_vm.errors[("reader-" + index + "-direction")],
            "filled": "",
            "dense": ""
          },
          model: {
            value: item.direction,
            callback: function ($$v) {
              _vm.$set(item, "direction", $$v);
            },
            expression: "item.direction"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.readers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "readers", $$v);
      },
      expression: "form.readers"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.errors) + " ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }