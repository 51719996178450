import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VList, [_vm._l(_vm.items, function (item, index) {
    return [_vm.isHeadingVisible(item) ? _c('sidebar-header', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isSectionEmpty(index),
        expression: "!isSectionEmpty(index)"
      }],
      key: 'sidebar-menu-header-' + index,
      class: {
        'grey--text text--lighten-2': _vm.$store.state.darkMode,
        'grey--text text--darken-2': !_vm.$store.state.darkMode
      },
      attrs: {
        "item": item
      }
    }) : _vm.isDividerVisible(item) ? _c(VDivider, {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isSectionEmpty(index),
        expression: "!isSectionEmpty(index)"
      }],
      key: 'sidebar-menu-divider-' + index,
      staticClass: "ma-6"
    }) : _vm.isItemVisible(item) ? _c('sidebar-menu-item', {
      key: 'sidebar-menu-item-' + index,
      attrs: {
        "item": item
      }
    }) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }