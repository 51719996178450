import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "grey",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "accent",
      "width": "60",
      "height": "60",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Csoport")]), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-group")]), _vm._v("Alapadatok")], 1), _c(VCardText, [_c(VRow, {
    staticClass: "m-1"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4,
      "sm": 12
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4,
      "sm": 12
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Csoport típusa",
      "error-messages": _vm.errors.group_type_id,
      "items": _vm.groupTypes,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "hide-details": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "change": _vm.changeGroupType
    },
    model: {
      value: _vm.form.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type_id", $$v);
      },
      expression: "form.type_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4,
      "sm": 12
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Csoporton belül engedélyezett elem típusok",
      "error-messages": _vm.errors.selectedElementType,
      "items": _vm.filteredElementTypes,
      "item-text": "name",
      "item-value": "id",
      "return-object": "",
      "filled": "",
      "hide-details": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.selectedElementType,
      callback: function ($$v) {
        _vm.selectedElementType = $$v;
      },
      expression: "selectedElementType"
    }
  })], 1)], 1)], 1)], 1), _vm.selectedElementType ? _c(VCard, {
    staticClass: "mt-6"
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-select-group")]), _vm._v(" Csoport elemei ")], 1), _c(VCardText, [_c('v-two-panel-multiselect', {
    attrs: {
      "headers": [{
        text: 'Megnevezés',
        value: 'name'
      }, {
        text: 'Típus',
        value: 'type_name'
      }],
      "options": {
        sortBy: ['label']
      },
      "items": _vm.elements,
      "item-value": "element_id"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var search = _ref.search;
        return [_c(VCard, {
          staticClass: "mb-6",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter")]), _vm._v(" Keresés ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Megnevezés",
            "hide-details": ""
          },
          model: {
            value: search.name,
            callback: function ($$v) {
              _vm.$set(search, "name", $$v);
            },
            expression: "search.name"
          }
        })], 1), _vm.selectedElementType.code === 'KARTYA' ? _c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Kártyatulajdonos",
            "hide-details": ""
          },
          model: {
            value: search.subname,
            callback: function ($$v) {
              _vm.$set(search, "subname", $$v);
            },
            expression: "search.subname"
          }
        })], 1) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "item.type_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VChip, {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(_vm._s(item.type_name))])];
      }
    }, {
      key: "item.name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(item.name))]), _c('small', [_vm._v(_vm._s(item.subname))])];
      }
    }], null, true),
    model: {
      value: _vm.form.elements,
      callback: function ($$v) {
        _vm.$set(_vm.form, "elements", $$v);
      },
      expression: "form.elements"
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }