import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VAppBar, {
    staticClass: "flex-column flex-wrap align-start justify-start px-4",
    attrs: {
      "fixed": "",
      "height": _vm.headerHeight,
      "color": "white",
      "elevation": "1",
      "app": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$store.state.drawer,
      expression: "!$store.state.drawer"
    }],
    staticClass: "ml-n4 mr-4",
    attrs: {
      "transition": "slide-x-transition"
    }
  }, [_c(VSheet, {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "color": "primary",
      "dark": "",
      "height": _vm.headerHeight - 4,
      "width": _vm.headerHeight
    }
  }, [_c(VIcon, {
    staticClass: "mx-4",
    attrs: {
      "size": "28"
    }
  }, [_vm._v("mdi-shield-key-outline")])], 1), _c(VSheet, {
    attrs: {
      "width": _vm.headerHeight,
      "height": "4",
      "color": "primary darken-1"
    }
  })], 1), _c(VAppBarNavIcon, {
    staticClass: "rounded-sm",
    on: {
      "click": function ($event) {
        _vm.$store.state.drawer = !_vm.$store.state.drawer;
      }
    }
  }), _c(VBtn, {
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$store.commit('openSearch');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-magnify")])], 1), _c(VSpacer), _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "mx-1 rounded-sm",
          attrs: {
            "text": "",
            "small": "",
            "height": "40",
            "color": _vm.uniomd_unreachable ? 'error' : 'success'
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "d-sm-inline-block d-none"
        }, [_vm._v("Státusz")]), _vm.uniomd_unreachable ? [_c(VIcon, {
          attrs: {
            "right": "",
            "color": "error"
          }
        }, [_vm._v("mdi-alert-circle")])] : [_c(VIcon, {
          attrs: {
            "right": "",
            "color": "success"
          }
        }, [_vm._v("mdi-check-circle")])]], 2)];
      }
    }])
  }, [_c('span', [_vm._v(" Szolgáltatás állapota: " + _vm._s(_vm.uniomd_unreachable ? 'HIBA' : 'OK') + " ")])]), _c(VMenu, {
    attrs: {
      "transition": "slide-y-transition",
      "right": "",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "mx-1 px-1 rounded-sm",
          attrs: {
            "text": "",
            "small": "",
            "height": "40"
          }
        }, 'v-btn', attrs, false), on), [_c(VAvatar, {
          staticClass: "rounded-sm",
          attrs: {
            "color": "grey lighten-4",
            "size": "32"
          }
        }, [_c(VIcon, {
          attrs: {
            "size": "24",
            "color": "grey"
          }
        }, [_vm._v("mdi-account")])], 1), _c('div', {
          staticClass: "data text-left mx-2"
        }, [_c('div', {
          staticClass: "font-weight-bold text-subtitle-2",
          staticStyle: {
            "text-transform": "none"
          }
        }, [_vm._v(" " + _vm._s(_vm.$store.state.user.name) + " ")])]), _c(VIcon, {
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi-chevron-down")])], 1)];
      }
    }])
  }, [_c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VSheet, {
    staticClass: "d-flex pa-2",
    attrs: {
      "color": "white"
    }
  }, [_c(VAvatar, {
    staticClass: "rounded-sm",
    attrs: {
      "color": "grey lighten-4",
      "size": "64"
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "48",
      "color": "grey"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center pa-2 ml-2"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$store.state.user.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$store.state.user.email) + " ")])])], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center pa-2"
  }, [_c(VBtn, {
    staticClass: "mb-1",
    attrs: {
      "text": "",
      "small": "",
      "block": "",
      "to": "/change-password",
      "height": "36"
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key-variant")]), _vm._v(" Jelszó módosítás ")], 1), _c(VBtn, {
    staticClass: "mb-1",
    attrs: {
      "text": "",
      "small": "",
      "block": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.$store.commit('toggleDarkMode');
      }
    }
  }, [_vm.$vuetify.theme.dark ? [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-white-balance-sunny")]), _vm._v(" Váltás világos módra ")] : [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-weather-night")]), _vm._v(" Váltás sötét módra ")]], 2)], 1), _c(VSheet, {
    attrs: {
      "color": "primary",
      "height": "2"
    }
  }), _c(VSheet, {
    staticClass: "pa-2",
    attrs: {
      "color": "muted"
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "block": "",
      "small": ""
    },
    on: {
      "click": _vm.$logout
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-power")]), _vm._v(" Kijelentkezés ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }