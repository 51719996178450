<template>
  <v-container>
    <h1 class="py-6">Szolgáltatás állapota</h1>

    <v-card v-for="process in processes" :key="process.name" class="mb-6">
      <v-card-title>{{ prettyName(process.name) }}</v-card-title>
      <v-card-text>
        <div class="d-flex justify-end flex-row-reverse">
          <v-tooltip
            v-for="status in process.statuses"
            :key="status.id"
            bottom
            :color="statusColor(status)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-alert
                rounded="sm"
                width="20"
                height="32"
                class="pa-0"
                style="margin: 1px"
                :color="statusColor(status)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <div v-if="status.downtime / status.period === 0">Folyamatos működés</div>
            <div v-else-if="status.downtime / status.period < 1">
              Fennakadás
              <small>
                <div>Leállás időtartama: ~{{ status.downtime }}s</div>
                <div>Kilépési kód: {{ status.code }}</div>
              </small>
            </div>
            <div v-else>
              Leállás
              <small>
                <div>Kilépési kód: {{ status.code }}</div>
              </small>
            </div>
            <small>{{ status.created | moment('LLL') }} - {{ status.period / 60 }}m</small>
          </v-tooltip>
        </div>
        <div class="d-flex align-center grey--text mt-3">
          <div>3 napja</div>
          <!-- <div>{{ process.statuses[process.statuses.length-1].created | moment('lll') }}</div> -->
          <v-divider class="ma-2" />
          <div>{{ downTimeInPercents(process) }}% elérhetőség</div>
          <v-divider class="ma-2" />
          <div>Most</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: [],
      processes: [],
      timeout: null,
    };
  },

  mounted() {
    this.fetchStatuses();
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    async fetchStatuses() {
      const response = await this.$http.post('process-statuses/list');
      const processes = [];

      for (const r of response.statuses) {
        const name = r.process;
        if (!processes[name]) {
          processes[name] = {
            name: name,
            statuses: [],
            downtime: 0,
            period: 0,
          };
        }
        if (processes[name].statuses.length >= 72) {
          continue;
        }
        processes[name].downtime += r.downtime;
        processes[name].period += r.period;

        processes[name].statuses.push({
          id: r.id,
          created: r.created,
          status: r.status,
          downtime: r.downtime,
          period: r.period,
          code: r.code,
        });
      }
      this.processes = Object.values(processes);
      setTimeout(() => {
        this.fetchStatuses();
      }, 15 * 1000);
    },

    statusColor(status) {
      const ratio = status.downtime / status.period;
      if (ratio === 0) {
        return 'success';
      }
      if (ratio < 1) {
        return 'warning';
      }
      return 'error';
    },

    downTimeInPercents(item) {
      return Math.round(10000 - (item.downtime / item.period) * 10000) / 100;
    },

    prettyName(process) {
      switch (process) {
        case 'huniomd-controller-sync':
          return 'HikVision beléptető vezérlő szinkronizáció';
        case 'huniomd-remote-control':
          return 'HikVision távoli nyitás';
        case 'huniomd-log-reader':
          return 'HikVision beléptető vezérlő logolás';
      }
    },
  },

  computed: {
    now() {
      return new Date().getTime();
    },
  },
};
</script>
