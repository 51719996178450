import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "title": "Importálás Excel fájlból",
      "disabled": !!_vm.selectedItems.length
    },
    on: {
      "click": function ($event) {
        _vm.importer.dialog = true;
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-excel")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "title": "Mentés",
      "loading": _vm.saving,
      "disabled": !_vm.isDirty
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("QR kód beállítás")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.expansionPanelIndex,
      callback: function ($$v) {
        _vm.expansionPanelIndex = $$v;
      },
      expression: "expansionPanelIndex"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-pencil-box-multiple")]), _vm._v(" Csoportos adatmódosítás ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "flex-grow-1",
    attrs: {
      "label": "Jogosultság",
      "items": _vm.accessLevels,
      "item-value": "element_id",
      "item-text": "name",
      "multiple": "",
      "small-chips": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "input": function ($event) {
        _vm.multiEdit.accessLevelIds.sort(function (a, b) {
          return a - b;
        });
      },
      "change": function ($event) {
        _vm.multiEdit.accessLevelIdsChanged = true;
      }
    },
    model: {
      value: _vm.multiEdit.accessLevelIds,
      callback: function ($$v) {
        _vm.$set(_vm.multiEdit, "accessLevelIds", $$v);
      },
      expression: "multiEdit.accessLevelIds"
    }
  }), _c(VBtn, {
    staticClass: "flex-grow-0",
    attrs: {
      "disabled": !_vm.multiEdit.accessLevelIdsChanged || !_vm.selectedItems.length,
      "icon": "",
      "tile": "",
      "color": "primary",
      "title": "Kijelölt sorok módosítása"
    },
    on: {
      "click": _vm.setAccessLevelIds
    }
  }, [_c(VIcon, [_vm._v("mdi-check")])], 1)], 1), _c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-datetime-picker', {
    staticClass: "flex-grow-1",
    attrs: {
      "label": "Kezdő dátum",
      "time-picker": true,
      "date-picker": true,
      "hide-details": "",
      "clearable": "",
      "dense": ""
    },
    on: {
      "change": function ($event) {
        _vm.multiEdit.startDateTimeChanged = true;
      }
    },
    model: {
      value: _vm.multiEdit.startDateTime,
      callback: function ($$v) {
        _vm.$set(_vm.multiEdit, "startDateTime", $$v);
      },
      expression: "multiEdit.startDateTime"
    }
  }), _c(VBtn, {
    staticClass: "flex-grow-0",
    attrs: {
      "append-icon": "mdi-check",
      "disabled": !_vm.multiEdit.startDateTimeChanged || !_vm.selectedItems.length,
      "icon": "",
      "tile": "",
      "color": "primary",
      "title": "Kijelölt sorok módosítása"
    },
    on: {
      "click": _vm.setStartDateTimes
    }
  }, [_c(VIcon, [_vm._v("mdi-check")])], 1)], 1), _c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-masked-text-field', {
    staticClass: "text-right",
    attrs: {
      "label": "Időtartam",
      "disabled": _vm.lockPeriods,
      "mask": _vm.$config.inputMasks.createNumberMask({
        min: 1,
        max: 168
      }),
      "hide-details": "",
      "dense": "",
      "suffix": "óra"
    },
    model: {
      value: _vm.multiEdit.period,
      callback: function ($$v) {
        _vm.$set(_vm.multiEdit, "period", $$v);
      },
      expression: "multiEdit.period"
    }
  }), _c(VBtn, {
    attrs: {
      "disabled": !_vm.multiEdit.periodChanged || !_vm.selectedItems.length,
      "icon": "",
      "tile": "",
      "color": "primary",
      "title": "Kijelölt sorok módosítása"
    },
    on: {
      "click": _vm.setPeriods
    }
  }, [_c(VIcon, [_vm._v("mdi-check")])], 1)], 1)], 1)], 1)], 1), _c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-email")]), _vm._v(" E-mail küldés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Kiküldés ideje",
      "hide-details": "",
      "dense": "",
      "items": [{
        value: 'immediate',
        text: 'Azonnal'
      }, {
        value: 'specific_time',
        text: 'Választott időpontban'
      }]
    },
    model: {
      value: _vm.email.sendMode,
      callback: function ($$v) {
        _vm.$set(_vm.email, "sendMode", $$v);
      },
      expression: "email.sendMode"
    }
  })], 1), _vm.email.sendMode === 'specific_time' ? _c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-datetime-picker', {
    staticClass: "flex-grow-1",
    attrs: {
      "label": "Időpont",
      "dense": "",
      "time-picker": true,
      "date-picker": true,
      "allowed-from": new Date().setMinutes(new Date().getMinutes() + 10),
      "hide-details": "",
      "clearable": "",
      "elevation": "0",
      "placeholder": "Válasszon",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.email.sendDateTime,
      callback: function ($$v) {
        _vm.$set(_vm.email, "sendDateTime", $$v);
      },
      expression: "email.sendDateTime"
    }
  })], 1) : _vm._e(), _c(VCol, {
    staticClass: "d-flex align-center"
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "large": "",
      "depressed": "",
      "loading": _vm.sending,
      "disabled": !_vm.selectedItems.length || _vm.email.sendMode === 'specific_time' && !_vm.email.sendDateTime
    },
    on: {
      "click": _vm.sendEmails
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-email-fast")]), _vm._v(" Küldés ")], 1)], 1)], 1)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "show-select": "",
      "selectable-key": "is_selectable",
      "calculate-widths": "",
      "disable-sort": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.data-table-select",
      fn: function (_ref) {
        var item = _ref.item,
          isSelected = _ref.isSelected,
          select = _ref.select;
        return [!item.email_sent ? _c(VCheckbox, {
          attrs: {
            "value": isSelected,
            "disabled": !item.is_selectable
          },
          on: {
            "change": select
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.barcode_number",
      fn: function (_ref2) {
        var _item$user, _item$user2;
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "py-1"
        }, [_c('div', [_vm._v(_vm._s(item.rf_id))]), _c('div', [_vm._v(_vm._s(item.barcode_number))]), item.email_sent ? _c('div', [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-email")]), _c('span', [_vm._v(_vm._s((_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) + " (" + _vm._s((_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.email) + ")")])], 1) : _vm._e()])];
      }
    }, {
      key: "item.name",
      fn: function (_ref3) {
        var _vm$errors$item$id;
        var item = _ref3.item;
        return [_c(VTextField, {
          attrs: {
            "disabled": !!item.email_sent,
            "error-messages": (_vm$errors$item$id = _vm.errors[item.id]) === null || _vm$errors$item$id === void 0 ? void 0 : _vm$errors$item$id.name,
            "hide-details": !_vm.errors[item.id],
            "filled": "",
            "dense": "",
            "placeholder": "Név",
            "persistent-placeholder": ""
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })];
      }
    }, {
      key: "item.email",
      fn: function (_ref4) {
        var _vm$errors$item$id2;
        var item = _ref4.item;
        return [_c(VTextField, {
          attrs: {
            "disabled": !!item.email_sent,
            "error-messages": (_vm$errors$item$id2 = _vm.errors[item.id]) === null || _vm$errors$item$id2 === void 0 ? void 0 : _vm$errors$item$id2.email,
            "hide-details": !_vm.errors[item.id],
            "filled": "",
            "dense": "",
            "placeholder": "E-mail cím",
            "persistent-placeholder": ""
          },
          model: {
            value: item.email,
            callback: function ($$v) {
              _vm.$set(item, "email", $$v);
            },
            expression: "item.email"
          }
        })];
      }
    }, {
      key: "item.plate",
      fn: function (_ref5) {
        var _vm$errors$item$id3;
        var item = _ref5.item;
        return [_c('v-masked-text-field', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "disabled": !!item.email_sent,
            "error-messages": (_vm$errors$item$id3 = _vm.errors[item.id]) === null || _vm$errors$item$id3 === void 0 ? void 0 : _vm$errors$item$id3.license_plate,
            "hide-details": !_vm.errors[item.id],
            "mask": _vm.$config.inputMasks.plateNumber,
            "filled": "",
            "dense": "",
            "placeholder": "Rendszám",
            "persistent-placeholder": ""
          },
          model: {
            value: item.license_plate,
            callback: function ($$v) {
              _vm.$set(item, "license_plate", $$v);
            },
            expression: "item.license_plate"
          }
        })];
      }
    }, {
      key: "item.permissions",
      fn: function (_ref6) {
        var _vm$errors$item$id4;
        var item = _ref6.item;
        return [_c(VAutocomplete, {
          attrs: {
            "disabled": !!item.email_sent,
            "error-messages": (_vm$errors$item$id4 = _vm.errors[item.id]) === null || _vm$errors$item$id4 === void 0 ? void 0 : _vm$errors$item$id4.access_level_ids,
            "hide-details": !_vm.errors[item.id],
            "value-comparator": _vm.$config.comparators.isEqual,
            "items": _vm.accessLevels,
            "item-value": "element_id",
            "item-text": "name",
            "multiple": "",
            "filled": "",
            "small-chips": "",
            "dense": ""
          },
          on: {
            "input": function ($event) {
              item.access_level_ids.sort(function (a, b) {
                return a > b ? 1 : -1;
              });
            }
          },
          model: {
            value: item.access_level_ids,
            callback: function ($$v) {
              _vm.$set(item, "access_level_ids", $$v);
            },
            expression: "item.access_level_ids"
          }
        })];
      }
    }, {
      key: "item.start_date_time",
      fn: function (_ref7) {
        var _vm$errors$item$id5;
        var item = _ref7.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "disabled": !!item.email_sent,
            "time-picker": true,
            "date-picker": true,
            "filled": "",
            "error-messages": (_vm$errors$item$id5 = _vm.errors[item.id]) === null || _vm$errors$item$id5 === void 0 ? void 0 : _vm$errors$item$id5.start_date_time,
            "hide-details": !_vm.errors[item.id],
            "clearable": "",
            "dense": "",
            "placeholder": "Kezdő dátum",
            "persistent-placeholder": "",
            "allowed-from": new Date().setHours(new Date().getHours() + 1),
            "allow-seconds": ""
          },
          model: {
            value: item.start_date_time,
            callback: function ($$v) {
              _vm.$set(item, "start_date_time", $$v);
            },
            expression: "item.start_date_time"
          }
        })];
      }
    }, {
      key: "item.period",
      fn: function (_ref8) {
        var _vm$errors$item$id6;
        var item = _ref8.item;
        return [_c('v-masked-text-field', {
          key: item.key,
          staticClass: "text-right",
          attrs: {
            "placeholder": "Időtartam",
            "persistent-placeholder": "",
            "filled": "",
            "disabled": !!item.email_sent || _vm.lockPeriods,
            "error-messages": (_vm$errors$item$id6 = _vm.errors[item.id]) === null || _vm$errors$item$id6 === void 0 ? void 0 : _vm$errors$item$id6.period,
            "hide-details": !_vm.errors[item.id],
            "dense": "",
            "mask": _vm.$config.inputMasks.createNumberMask({
              min: 1,
              max: 168
            }),
            "suffix": item.period ? 'óra' : ''
          },
          model: {
            value: item.period,
            callback: function ($$v) {
              _vm.$set(item, "period", $$v);
            },
            expression: "item.period"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.selectedItems,
      callback: function ($$v) {
        _vm.selectedItems = $$v;
      },
      expression: "selectedItems"
    }
  })], 1), _vm.importer.dialog ? _c(VDialog, {
    attrs: {
      "value": true,
      "persistent": "",
      "max-width": "480px"
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file-import")]), _vm._v(" Vonalkód importálás ")], 1), _c(VCardText, [_c(VBtn, {
    attrs: {
      "href": "/assets/doc/vonalkod_sablon.xls",
      "download": "",
      "large": "",
      "depressed": "",
      "block": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file-download")]), _vm._v(" Sablon XLS fájl letöltése ")], 1), _c(VDivider, {
    staticClass: "ma-4"
  }), _c(VFileInput, {
    attrs: {
      "label": "Vonalkódokat tartalmazó XLS fájl feltöltése",
      "filled": "",
      "prepend-icon": "",
      "prepend-inner-icon": "mdi-file-upload",
      "error-messages": _vm.errors.file
    },
    model: {
      value: _vm.importer.file,
      callback: function ($$v) {
        _vm.$set(_vm.importer, "file", $$v);
      },
      expression: "importer.file"
    }
  })], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.importer.dialog = false;
      }
    }
  }, [_vm._v("Mégse")]), _c(VBtn, {
    attrs: {
      "color": "primary",
      "depressed": "",
      "disabled": _vm.importer.loading,
      "loading": _vm.importer.loading
    },
    on: {
      "click": _vm.importCodes
    }
  }, [_vm._v(" Importálás ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }