import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('FILTER') ? _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Szűrő")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": "",
      "disabled": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 5,
      "md": 4
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Típus",
      "items": _vm.elementTypes,
      "item-text": "name",
      "item-value": "id",
      "hide-details": ""
    },
    model: {
      value: _vm.elementTypeId,
      callback: function ($$v) {
        _vm.elementTypeId = $$v;
      },
      expression: "elementTypeId"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.elementTypeId ? [_c(VAlert, {
    attrs: {
      "border": "bottom",
      "colored-border": "",
      "type": "warning",
      "elevation": "2"
    }
  }, [_c('b', [_vm._v("Figyelem!")]), _vm._v(" Típus váltás előtt menteni kell a beállított értékeket! ")]), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "item-class": _vm.itemClass
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.groups",
      fn: function (_ref) {
        var item = _ref.item;
        return _vm._l(item.groups, function (group) {
          return _c(VChip, {
            key: group.id,
            staticClass: "mr-2",
            attrs: {
              "color": group.color,
              "small": "",
              "label": "",
              "link": "",
              "to": {
                name: 'GroupEditor',
                params: {
                  id: group.element_id
                }
              },
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(group.name) + " ")]);
        });
      }
    }, {
      key: "item.users",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VAutocomplete, {
          attrs: {
            "items": _vm.users,
            "item-text": "name",
            "item-value": "element_id",
            "hide-details": "",
            "multiple": "",
            "chips": "",
            "filled": ""
          },
          model: {
            value: item.user_ids,
            callback: function ($$v) {
              _vm.$set(item, "user_ids", $$v);
            },
            expression: "item.user_ids"
          }
        })];
      }
    }], null, true)
  })], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }