<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn
        tile
        depressed
        color="grey"
        width="60"
        height="60"
        class="ml-1"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        tile
        depressed
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Csoport</h1>

    <v-card :loading="loading">
      <v-card-title><v-icon left>mdi-group</v-icon>Alapadatok</v-card-title>
      <v-card-text>
        <v-row class="m-1">
          <v-col :cols="12" :md="4" :sm="12">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
              hide-details
            />
          </v-col>
          <v-col :cols="12" :md="4" :sm="12">
            <v-select
              label="Csoport típusa"
              :error-messages="errors.group_type_id"
              v-model="form.type_id"
              :items="groupTypes"
              item-text="name"
              item-value="id"
              @change="changeGroupType"
              filled
              hide-details
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="12" :md="4" :sm="12">
            <v-select
              label="Csoporton belül engedélyezett elem típusok"
              :error-messages="errors.selectedElementType"
              v-model="selectedElementType"
              :items="filteredElementTypes"
              item-text="name"
              item-value="id"
              return-object
              filled
              hide-details
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="selectedElementType" class="mt-6">
      <v-card-title>
        <v-icon left>mdi-select-group</v-icon>
        Csoport elemei
      </v-card-title>
      <v-card-text>
        <v-two-panel-multiselect
          :headers="[
            { text: 'Megnevezés', value: 'name' },
            { text: 'Típus', value: 'type_name' },
          ]"
          :options="{ sortBy: ['label'] }"
          :items="elements"
          v-model="form.elements"
          item-value="element_id"
        >
          <template #header="{ search }">
            <v-card outlined class="mb-6">
              <v-card-title>
                <v-icon left>mdi-filter</v-icon>
                Keresés
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-text-field label="Megnevezés" v-model="search.name" hide-details />
                  </v-col>
                  <v-col v-if="selectedElementType.code === 'KARTYA'" :cols="12" :md="6" :lg="3">
                    <v-text-field label="Kártyatulajdonos" v-model="search.subname" hide-details />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template #[`item.type_name`]="{ item }">
            <v-chip label small>{{ item.type_name }}</v-chip>
          </template>
          <template #[`item.name`]="{ item }">
            <div>{{ item.name }}</div>
            <small>{{ item.subname }}</small>
          </template>
        </v-two-panel-multiselect>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        element_id: 0,
        group_type_id: 0,
        name: '',
        elements: [],
      },
      elementTypes: [],
      selectedElementType: null,
      groupTypes: [],
      errors: {},
      loading: false,
    };
  },

  async mounted() {
    this.fetchGroup();
    this.fetchElementTypes();
    this.fetchGroupTypes();
    if (this.form.type_id == 0) this.form.type_id = this.groupTypes[0].value;
  },

  methods: {
    async fetchGroup() {
      try {
        this.loading = true;
        const response = await this.$http.get(`groups/details/${this.$route.params.id || 0}`);
        this.form = response.group;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    // async fetchElements() {
    //   if (this.elementTypeId == 0) {
    //     this.elements = [];
    //     return;
    //   }
    //   try {
    //     const response = await this.$http.post('elements/list', { type_id: this.elementTypeId });
    //     this.elements = response.elements;
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },

    async fetchElementTypes() {
      try {
        const response = await this.$http.get('element-types/list', { is_group: 0 });
        this.elementTypes = response.element_types;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchGroupTypes() {
      try {
        const response = await this.$http.get('group-types/list');
        this.groupTypes = response.group_types;
      } catch (e) {
        console.error(e);
      }
    },

    changeGroupType() {
      this.elements = [];
      this.selectedElements = [];
      this.selectedElementType = null;
      this.form.elements = [];
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('groups/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'GroupEditor') {
            this.$router.push({ name: 'GroupEditor', params: { id: response.group.element_id } });
          }
          this.fetchGroup();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    filteredElementTypes() {
      const enabledElementTypes = [];
      for (const groupType of this.groupTypes) {
        if (groupType.id == this.form.type_id) {
          const groupElementTypes = groupType.element_types.split(',');
          for (const elementType of this.elementTypes) {
            if (groupElementTypes.includes(elementType.code)) {
              enabledElementTypes.push(elementType);
            }
          }
        }
      }
      this.$nextTick(() => {
        this.selectedElementType = enabledElementTypes[0];
      });
      return enabledElementTypes;
    }
  },

  asyncComputed: {
    async elements() {
      if (this.selectedElementType) {
        return (await this.$http.post('elements/listv2', { type_id: this.selectedElementType.id })).elements;
      }
      return [];
    },
  }
};
</script>
