<template>
  <div :style="divStyle" v-if="$root.profilmenu" class="profile-overlay-menu">
    <div class="om-content">
      <div class="user-data d-flex">
        <v-avatar
          color="grey lighten-4 mr-2"
          size="60"
        >
          <v-icon large>mdi-account</v-icon>
        </v-avatar>

        <v-list dense>
          <v-list-item :to="{ path: '/change_password' }">
            <v-list-item-icon class="mr-1">
              <v-icon small>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Jelszó módosítás</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- <ul>
          <li><router-link :to="{ path: '/change_password' }">Jelszó módosítás</router-link></li>
        </ul> -->
      </div>
    </div>
    <router-link class="logout" :to="{ path: '/logout' }"><i class="customicon customicon-power-off"></i>Kijelentkezés</router-link>
  </div>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery';

export default {
  data: function() {
    return {
      left: 0,
      top: 60
    };
  },
  mounted: function() {
    var _this = this;

    Vue.nextTick(function() {
      _this.left = $('.logged-user').offset().left - 15;

      $(window).click(function(event) {
        if ($(event.target).closest('.profile-overlay-menu').length == 0 && !$(event.target).hasClass('toggle') && !$(event.target).parent().hasClass('toggle'))
          _this.$root.profilmenu = false;
      });
    });
  },
  computed: {
    divStyle: function() {
      return {
        left: this.left + 'px',
        top: this.top + 'px'
      }
    },
    photoStyle: function() {
      return {
        'background-image': "url('assets/img/"+(this.$root.user.avatar != undefined ? this.$root.user.avatar : '')+"')"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-overlay-menu {
  position: fixed;
  z-index: 1000;
}
</style>
