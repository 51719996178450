import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('CARD_WRITE') && _vm.hasFeature('CARD_CREATION') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewCard'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kártya lista")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 2
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Kártya tulajdonos",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.owner_name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "owner_name", $$v);
      },
      expression: "search.owner_name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 2
    }
  }, [_c('v-masked-text-field', {
    attrs: {
      "label": "Kártyaszám",
      "mask": _vm.$config.inputMasks.cardNumber,
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "card_number", $$v);
      },
      expression: "search.card_number"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 2
    }
  }, [_c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    attrs: {
      "label": "RFID",
      "mask": _vm.$config.inputMasks.rfId,
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.rfid,
      callback: function ($$v) {
        _vm.$set(_vm.search, "rfid", $$v);
      },
      expression: "search.rfid"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 2
    }
  }, [_c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    attrs: {
      "label": "Rendszám",
      "mask": _vm.$config.inputMasks.plateNumber,
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.plate_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "plate_number", $$v);
      },
      expression: "search.plate_number"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 12,
      "md": 2
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Státusz",
      "items": [{
        text: 'Aktív',
        value: '1'
      }, {
        text: 'Inaktív',
        value: '0'
      }],
      "hide-details": "",
      "clearable": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "change": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.active,
      callback: function ($$v) {
        _vm.$set(_vm.search, "active", $$v);
      },
      expression: "search.active"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "item-class": _vm.itemClass
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.photo",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('Avatar', {
          attrs: {
            "rounded": "",
            "color": "grey",
            "size": 40,
            "hide-picture": !item.photo,
            "hash": item.photo
          }
        })];
      }
    }, {
      key: "item.active",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.active == 1 ? 'Aktív' : 'Inaktív') + " ")];
      }
    }, {
      key: "item.plate_numbers",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._l(item.plate_numbers, function (number) {
          return [number ? _c(VChip, {
            key: ("plate-" + number),
            staticClass: "mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(_vm._s(number))]) : _vm._e()];
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm.hasRight('CARD_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "small": "",
            "fab": "",
            "text": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('CARD_WRITE') && _vm.$root.user.filter_element == 1 ? _c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "small": "",
            "fab": "",
            "text": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0"
          },
          on: {
            "click": _vm.editButtonInfo
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }