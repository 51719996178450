<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" @click="$router.go(-1)">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        v-if="hasRight('KEY_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewKey' }"
        title="Új kulcs"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kulcs</h1>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-key</v-icon>
            Alapadatok
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="12" :md="6">
                <v-text-field
                  label="Megnevezés"
                  :error-messages="errors.name"
                  v-model="form.name"
                  filled
                />

                <v-text-field
                  label="RFID"
                  filled
                  :error-messages="errors.rfid"
                  v-model="form.rfid"
                  :hint="readerDisabled ? '' : 'Érintse a kártyát az olvasóhoz'"
                />

                <v-autocomplete
                  label="Kulcsszekrények"
                  :items="key_cabinets"
                  item-text="name"
                  item-value="id"
                  v-model="form.cabinet_ids"
                  :value-comparator="$config.comparators.isEqual"
                  filled
                  multiple
                  chips
                  deletable-chips
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-textarea
                  label="Megjegyzés"
                  name="name"
                  v-model="form.remark"
                  filled
                  auto-grow
                  :rows="5"
                />

                <v-swatches
                  v-model="form.color"
                  inline
                  :swatches="[
                    '#FF6D00',
                    '#D50000',
                    '#9C27B0',
                    '#673AB7',
                    '#3F51B5',
                    '#2196F3',
                    '#03A9F4',
                    '#00BCD4',
                    '#009688',
                    '#4CAF50',
                    '#8BC34A',
                    '#CDDC39',
                    '#FFEB3B',
                    '#FFC107',
                    '#FF9800',
                    '#795548',
                    '#9E9E9E',
                    '#000000',
                    '#FFFFFF',
                    '',
                  ]"
                  shapes="squares"
                  show-border
                  :spacing-size="4"
                  :swatch-size="32"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="12">
        <v-card :loading="groupsLoading">
          <v-card-title>
            <v-icon left>mdi-group</v-icon>
            Engedélyezett csoportok
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Csoportok"
              :items="groups"
              v-model="form.groups"
              filled
              multiple
              item-text="name"
              item-value="element_id"
              :value-comparator="$config.comparators.isEqual"
              return-object
              hide-details
              chips
              deletable-chips
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  :to="{ name: 'GroupEditor', params: { id: item.element_id } }"
                  target="_blank"
                >
                  <span>{{ item.name }}</span>
                  <v-btn icon x-small @click.prevent="form.groups.splice(index)" class="ml-2">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </template>
            </v-autocomplete>

            <!-- <v-multi-select-list
              :headers="[{ text: 'Rendszám', value: 'name' }]"
              :items="groups"
              v-model="form.groups"
              item-value="element_id"
              item-text="name"
              hide-default-header
              :blank-item-template="{ element_id: '' }"
            >
              <template #[`item.name`]="{ item, index }">
                <v-masked-text-field
                  :label="`#${item.id || index + 1}`"
                  v-model="item.number"
                  :error-messages="errors[`plate-${index}`]"
                  filled
                  dense
                  :hide-details="!item.active_season_ticket"
                  v-uppercase
                  :mask="$config.inputMasks.plateNumber"
                />
              </template>
            </v-multi-select-list> -->
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="12">
        <v-card :loading="cardsLoading">
          <v-card-title>
            <v-icon left>mdi-cards</v-icon>
            Engedélyezett kártyák
          </v-card-title>
          <v-card-text>
            <v-two-panel-multiselect
              :headers="[
                { text: 'Kártyaszám', value: 'card_number' },
                { text: 'Birtokos', value: 'owner_name' },
                { text: 'Email', value: 'owner_email' },
              ]"
              :options="{ sortBy: ['card_number'] }"
              :items="cards"
              v-model="form.cards"
              item-value="element_id"
            >
              <template #header="{ search }">
                <v-card outlined class="mb-6">
                  <v-card-title>
                    <v-icon left>mdi-filter</v-icon>
                    Keresés
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col :cols="12" :md="6" :lg="3">
                        <v-text-field
                          label="Kártyatulajdonos"
                          v-model="search.owner_name"
                          hide-details
                        />
                      </v-col>
                      <v-col :cols="12" :md="6" :lg="3">
                        <v-text-field
                          label="Kártyaszám"
                          v-model="search.card_number"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-two-panel-multiselect>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';

export default {
  components: { VSwatches },

  data() {
    return {
      form: {
        name: '',
        remark: '',
        cards: [],
        groups: [],
        cabinet_ids: [],
        rfid: '',
      },

      readerDisabled: true,
      socket: false,
      socketError: false,

      errors: {},
      loading: false,

      groupsLoading: false,
      cardsLoading: false,
    };
  },

  async mounted() {
    this.watchCardReader();
    this.fetchAll();
  },

  methods: {
    async fetchBuildings() {
      const response = await this.$http.get('buildings/list');
      this.buildings = response.buildings;
    },

    async fetchAll() {
      this.loading = true;
      await Promise.all([this.fetchKey(), this.fetchBuildings()]);
      this.loading = false;
    },

    async fetchKey() {
      try {
        const response = await this.$http.get(`keys/details/${this.$route.params.id || 0}`);
        this.form = response.key;
      } catch (e) {
        console.error(e);
      }
    },

    async save(event) {
      try {
        this.errors = {};
        this.loading = true;
        this.form.color = this.form?.color?.hexa || this.form?.color;
        const response = await this.$http.post('keys/save/', this.form);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewKey') {
            this.$router.push({
              name: 'KeyEditor',
              params: { id: response.key.id },
            });
          } else {
            this.fetchAll();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async watchCardReader() {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }

      try {
        this.socket = new WebSocket('wss://kmr-reader.uninet.hu:8080');

        this.socket.onopen = async (event) => {
          this.readerDisabled = false;
        };

        this.socket.onmessage = async (event) => {
          this.form.rfid = event.data;
          this.form = Object.assign({}, this.form, { rfid: event.data });
        };
      } catch (err) {
        console.error(err);
        this.reader = false;
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    groups: {
      async get() {
        this.groupsLoading = true;
        const response = await this.$http.post('groups/list', {
          type_codes: ['KARTYA'],
        });
        this.groupsLoading = false;
        return response.groups;
      },
      default: [],
    },

    key_cabinets: {
      async get() {
        const response = await this.$http.post('key-cabinets/list', {
          nolimit: true,
        });
        return response.key_cabinets;
      },
      default: [],
    },

    cards: {
      async get() {
        this.cardsLoading = true;
        const response = await this.$http.post('cards/list', { limit: 50000 });
        this.cardsLoading = false;
        return response.cards;
      },
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-swatches__container.vue-swatches--inline {
  background: none !important;
}
</style>
