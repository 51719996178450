<template>
  <div v-if="form.gateway != undefined">
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        color="grey"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="my-6" v-if="$route.name === 'NewGateway'">Új gateway</h1>
    <h1 class="my-6" v-else>Gateway szerkesztése</h1>

    <v-row>
      <v-col :cols="12" :lg="6">
        <v-card>
          <v-card-title>Gateway adatok</v-card-title>
          <v-card-text>
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.gateway.name"
              filled
            />
            <v-select
              label="Épület"
              :error-messages="errors.building_id"
              v-model="form.gateway.building_id"
              :items="buildings"
              item-text="label"
              item-value="value"
              filled
            />
            <v-text-field
              label="Folyamat név"
              :error-messages="errors.process_name"
              v-model="form.gateway.process_name"
              filled
            />
            <v-masked-text-field
              label="Cím"
              :error-messages="errors.address"
              v-model="form.gateway.address"
              :mask="$config.inputMasks.gatewayAdddress"
              filled
            />
            <v-masked-text-field
              label="Port"
              :error-messages="errors.port"
              v-model="form.gateway.port"
              filled
              :mask="$config.inputMasks.integerNumber"
            />
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.gateway.comment"
              filled
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      form: {
      },
      buildings: [],
      errors: {},
      loading: false
    };
  },

  mounted() {
    this.fetchGateway();
  },

  methods: {
    async fetchGateway() {
      this.loading = true;
      try {
        const response = await this.$http.get(`gateways/details/${this.$route.params.id || 0}`);
        this.buildings = [{value: 0, label: '- válassz -'}, ...response.buildings];
        this.$set(this.form, 'gateway', response.gateway);

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      const _this = this;
      this.loading = true;
      this.errors = {};

      try {
        const response = await this.$http.post('gateways/save', this.form.gateway);

        this.loading = false;

        if (Object.keys(response.errors).length) {
          return this.errors = response.errors;
        }
        this.$dialog.notify.success('Sikeres mentés');
        this.$router.replace({ name: 'GatewayList' });

      } catch (e) {
        this.$dialog.notify.error(e);
      }
    },
  },
};
</script>
