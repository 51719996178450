import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _vm.hasRight('KEY_WRITE') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewKey'
      },
      "title": "Új kulcs"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e(), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcs")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key")]), _vm._v(" Alapadatok ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "RFID",
      "filled": "",
      "error-messages": _vm.errors.rfid,
      "hint": _vm.readerDisabled ? '' : 'Érintse a kártyát az olvasóhoz'
    },
    model: {
      value: _vm.form.rfid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rfid", $$v);
      },
      expression: "form.rfid"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Kulcsszekrények",
      "items": _vm.key_cabinets,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "filled": "",
      "multiple": "",
      "chips": "",
      "deletable-chips": ""
    },
    model: {
      value: _vm.form.cabinet_ids,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cabinet_ids", $$v);
      },
      expression: "form.cabinet_ids"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "name": "name",
      "filled": "",
      "auto-grow": "",
      "rows": 5
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  }), _c('v-swatches', {
    attrs: {
      "inline": "",
      "swatches": ['#FF6D00', '#D50000', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#795548', '#9E9E9E', '#000000', '#FFFFFF', ''],
      "shapes": "squares",
      "show-border": "",
      "spacing-size": 4,
      "swatch-size": 32
    },
    model: {
      value: _vm.form.color,
      callback: function ($$v) {
        _vm.$set(_vm.form, "color", $$v);
      },
      expression: "form.color"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, {
    attrs: {
      "loading": _vm.groupsLoading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-group")]), _vm._v(" Engedélyezett csoportok ")], 1), _c(VCardText, [_c(VAutocomplete, {
    attrs: {
      "label": "Csoportok",
      "items": _vm.groups,
      "filled": "",
      "multiple": "",
      "item-text": "name",
      "item-value": "element_id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "return-object": "",
      "hide-details": "",
      "chips": "",
      "deletable-chips": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c(VChip, {
          attrs: {
            "to": {
              name: 'GroupEditor',
              params: {
                id: item.element_id
              }
            },
            "target": "_blank"
          }
        }, [_c('span', [_vm._v(_vm._s(item.name))]), _c(VBtn, {
          staticClass: "ml-2",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.form.groups.splice(index);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.form.groups,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groups", $$v);
      },
      expression: "form.groups"
    }
  })], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, {
    attrs: {
      "loading": _vm.cardsLoading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cards")]), _vm._v(" Engedélyezett kártyák ")], 1), _c(VCardText, [_c('v-two-panel-multiselect', {
    attrs: {
      "headers": [{
        text: 'Kártyaszám',
        value: 'card_number'
      }, {
        text: 'Birtokos',
        value: 'owner_name'
      }, {
        text: 'Email',
        value: 'owner_email'
      }],
      "options": {
        sortBy: ['card_number']
      },
      "items": _vm.cards,
      "item-value": "element_id"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref2) {
        var search = _ref2.search;
        return [_c(VCard, {
          staticClass: "mb-6",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter")]), _vm._v(" Keresés ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Kártyatulajdonos",
            "hide-details": ""
          },
          model: {
            value: search.owner_name,
            callback: function ($$v) {
              _vm.$set(search, "owner_name", $$v);
            },
            expression: "search.owner_name"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": 12,
            "md": 6,
            "lg": 3
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Kártyaszám",
            "hide-details": ""
          },
          model: {
            value: search.card_number,
            callback: function ($$v) {
              _vm.$set(search, "card_number", $$v);
            },
            expression: "search.card_number"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.form.cards,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cards", $$v);
      },
      expression: "form.cards"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }