import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60"
    },
    on: {
      "click": _vm.save
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "pt-0"
  }, [_vm._v("Jelszó módosítás")]), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6,
      "lg": 4
    }
  }, [_c(VCard, [_c(VCardText, [_c(VTextField, {
    attrs: {
      "filled": "",
      "type": "password",
      "label": "Jelszó",
      "error-messages": _vm.errors.password,
      "name": "password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c(VTextField, {
    attrs: {
      "filled": "",
      "type": "password",
      "label": "Jelszó megerősítése",
      "error-messages": _vm.errors.password_,
      "name": "password_"
    },
    model: {
      value: _vm.password_,
      callback: function ($$v) {
        _vm.password_ = $$v;
      },
      expression: "password_"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }