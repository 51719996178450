import Vue from 'vue';
import http from '@/http';
import Bowser from 'bowser';

Vue.config.errorHandler = (err, vm, info) => {
  console.error(`[Vue Error]`, err, info);
  reportError('Vue Error', err.toString(), err.stack);
};

Vue.config.warnHandler = (err, vm, trace) => {
  console.warn(`[Vue Warning]`, err, trace);
  reportError('Vue Warning', err.toString(), trace);
};

window.addEventListener('error', (event) => {
  console.error(`[Error]`, event);
  reportError(
    'Error',
    event.message,
    JSON.stringify(event.error, Object.getOwnPropertyNames(event.error))
  );
});

// window.addEventListener('unhandledrejection', (event) => {
//   if (event.skipReporting) {
//     console.debug("skip reporting");
//     return;
//   }
//   console.error(`[PromiseRejection]`, event);
//   reportError('PromiseRejection', event.reason.toString(), event.reason.stack);
// });

const reportError = async (type, message, params = null) => {
  await http.post('debug/report/error', {
    type,
    message,
    params,
    location: window.location.href,
    browser: Bowser.getParser(window.navigator.userAgent),
  });
  console.info('Error reported.');
};
