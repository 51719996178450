import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$route.params.id == undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "title": "Új igénylés"
    },
    on: {
      "click": _vm.newBarcode
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Vonalkód igénylések")]), _vm.requests.length > 0 ? _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.requests,
      "disable-sort": true,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.amount",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.barcode_email_sent_amount) + " / " + _vm._s(item.amount) + " ")];
      }
    }, {
      key: "item.approve",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.approved == 0 ? _c(VIcon, [_vm._v(" mdi-minus ")]) : _vm._e(), item.approved == 1 ? _c(VIcon, [_vm._v(" mdi-plus ")]) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.approved == 0 && item.user_id == _vm.$root.user.element_id ? _c(VBtn, {
          staticClass: "rounded-sm m-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "title": "Igénylés módosítása"
          },
          on: {
            "click": function ($event) {
              _vm.requestId = item.id;
              _vm.popup.request = true;
              _vm.dialog = true;
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), item.approved == 1 ? _c(VBtn, {
          staticClass: "rounded-sm m-1",
          attrs: {
            "title": "Vonalkódok beállítása",
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0"
          },
          on: {
            "click": function ($event) {
              return _vm.setBarcodes(item.id);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-cog")])], 1) : _vm._e(), item.approved == 0 && item.user_id == _vm.$root.user.element_id ? _c(VBtn, {
          staticClass: "rounded-sm m-1",
          attrs: {
            "title": "Igénylés törlése",
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0"
          },
          on: {
            "click": function ($event) {
              return _vm.del(item.id);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1) : _c(VRow, [_vm._v("Még nem igényelt vonalkódokat.")]), _vm.popup.request ? _c('BarcodeRequestPopup', {
    attrs: {
      "id": _vm.requestId,
      "dialog": _vm.popup.request
    },
    on: {
      "success": _vm.getRequests,
      "show": _vm.showDialog
    }
  }) : _vm._e(), this.$route.params.id != undefined ? _c('BarcodeRequestSetup', {
    on: {
      "save_start": function ($event) {
        _vm.isSave = true;
      },
      "save_finish": function ($event) {
        _vm.isSave = false;
      }
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }