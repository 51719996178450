<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewController' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kontrollerek</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-select
                label="Épület"
                :items="buildings"
                item-text="name"
                item-value="id"
                @change="searchEventHandler"
                v-model="search.building_id"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Épület szint"
                :items="buildingLevels"
                item-text="name"
                item-value="id"
                @change="searchEventHandler"
                v-model="search.building_level_id"
                clearable
                hide-details
                :placeholder="search.building_id ? 'Összes szint' : 'Épület kötelező'"
                :readonly="!search.building_id"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Kontroller típus"
                :items="controllerTypes"
                item-text="name"
                item-value="id"
                @change="searchEventHandler"
                v-model="search.controller_type_id"
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            small
            text
            fab
            dark
            depressed
            color="secondary"
            class="rounded-sm"
            :to="{ name: 'ControllerEditor', params: { id: item.element_id } }"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            text
            fab
            small
            color="secondary"
            class="rounded-sm m-3"
            @click="deleteConnection(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            text
            fab
            small
            color="secondary"
            class="rounded-sm m-3"
            @click="
              uploadCards(item)
            "
            title="Kártyák feltöltése"
          >
            <v-icon>mdi-package-up</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ControllerList',
      fetchItems: this.fetchControllers,
      dataTable: {
        options: {
          sortBy: ['controller_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'controller_name' },
          { text: 'Alias', value: 'alias' },
          { text: 'Épület', value: 'building_name' },
          { text: 'Épület szint', value: 'building_level_name' },
          { text: 'Serial szám', value: 'serial_number' },
          { text: 'Típus', value: 'controller_type' },
          { text: 'Connection', value: 'connection_name' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      buildings: [],
      buildingLevels: [],
      controllerTypes: [],
      search: {
        name: '',
        building_id: 0,
        building_level_id: 0,
        controller_type_id: 0,
      },
    };
  },

  watch: {
    async 'search.building_id'() {
      try {
        const response = await this.$http.post(`building-levels/list/`, {
          building_id: this.search.building_id,
        });
        this.buildingLevels = response.building_levels;
        this.search.building_level_id = 0;
      } catch (e) {
        console.error(e);
      }
    },
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        building_id: this.$route.query.building_id,
        building_level_id: this.$route.query.building_level_id,
        controller_type_id: this.$route.query.controller_type_id,
      };
    },

    async uploadCards(item) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: `Ez a parancs feltölti az összes kártyát a következő kontrollerre: <b>${item.name}</b>.<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      const response = await this.$http.post('controllers/upload/cards', {
        element_id: item.element_id
      });

      if (response.status === 'OK') {
        this.$dialog.notify.info('Kártyafeltöltés parancs kiadva.');
      }
    },

    async controllerOperation(id, operation_type, text) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: text,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.post('operations/add_in', {
          operation_type: operation_type,
          params: {
            id: id,
          },
        });
        if (response.status === 'OK') {
          this.$dialog.notify.info('A művelet kiadva.');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.get('buildings/list');
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllerTypes() {
      try {
        const response = await this.$http.get('controller-types/list');
        this.controllerTypes = response.controller_types;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllers() {
      this.loading = true;
      try {
        const response = await this.$http.post('controllers/list', this.options);
        this.dataTable.items = response.controllers;
        this.dataTable.itemsLength = response.controllers_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;

        this.fetchControllerTypes();
        this.fetchBuildings();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteConnection(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> kontroller törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`controllers/delete/${item.element_id}`);
        this.fetchControllers();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A kontroller törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        building_id: this.search.building_id || 0,
        building_level_id: this.search.building_level_id || 0,
        controller_type_id: this.search.controller_type_id || 0,
      };
    },
  },
};
</script>
