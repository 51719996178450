import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("QR kódok jóváhagyása")]), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "item-class": _vm.itemClass,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.user",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.user.name)), _c('br'), _vm._v("(" + _vm._s(item.user.email) + ") ")];
      }
    }, {
      key: "item.groups",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VChipGroup, _vm._l(item.groups, function (group) {
          return _c(VChip, {
            key: group.id,
            staticClass: "ma-1",
            attrs: {
              "small": "",
              "label": "",
              "link": "",
              "color": group.color,
              "to": {
                name: 'GroupEditor',
                params: {
                  id: group.element_id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(group.name) + " ")]);
        }), 1)];
      }
    }, {
      key: "item.permissions",
      fn: function (_ref3) {
        var _vm$errors$index;
        var item = _ref3.item,
          index = _ref3.index;
        return [_c(VAutocomplete, {
          attrs: {
            "disabled": item.approved == 1,
            "error-messages": (_vm$errors$index = _vm.errors[index]) === null || _vm$errors$index === void 0 ? void 0 : _vm$errors$index.access_level_ids,
            "hide-details": !_vm.errors[index],
            "items": item.access_levels,
            "item-value": "element_id",
            "item-text": "name",
            "multiple": "",
            "filled": "",
            "small-chips": "",
            "dense": "",
            "value-comparator": _vm.$config.comparators.isEqual
          },
          on: {
            "input": function ($event) {
              item.access_level_ids.sort(function (a, b) {
                return a > b ? 1 : -1;
              });
            }
          },
          model: {
            value: item.access_level_ids,
            callback: function ($$v) {
              _vm.$set(item, "access_level_ids", $$v);
            },
            expression: "item.access_level_ids"
          }
        })];
      }
    }, {
      key: "item.amount",
      fn: function (_ref4) {
        var _vm$errors$index2;
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('v-masked-text-field', {
          attrs: {
            "disabled": item.approved == 1,
            "dense": "",
            "filled": "",
            "error-messages": (_vm$errors$index2 = _vm.errors[index]) === null || _vm$errors$index2 === void 0 ? void 0 : _vm$errors$index2.amount,
            "hide-details": !_vm.errors[index],
            "mask": _vm.$config.inputMasks.createNumberMask({
              max: 1000
            })
          },
          model: {
            value: item.amount,
            callback: function ($$v) {
              _vm.$set(item, "amount", $$v);
            },
            expression: "item.amount"
          }
        })];
      }
    }, {
      key: "item.approved",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.approved == 1 ? _c(VIcon, {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("mdi-checkbox-marked")]) : _c(VIcon, {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-close-box")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref6) {
        var item = _ref6.item,
          index = _ref6.index;
        return [item.approved == 0 ? _c(VBtn, {
          staticClass: "rounded-sm ma-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "text": "",
            "title": "Igénylés jóváhagyása"
          },
          on: {
            "click": function ($event) {
              return _vm.approve(item, index);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-check")])], 1) : _vm._e(), item.approved == 0 ? _c(VBtn, {
          staticClass: "rounded-sm ma-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "text": "",
            "title": "Igénylés törlése"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteRequest(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }