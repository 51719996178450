<template>
  <div v-if="this.$route.params.id == undefined">
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="newBarcode"
        title="Új igénylés"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Vonalkód igénylések</h1>
    <v-card :loading="loading" v-if="requests.length > 0">
      <v-data-table
        :headers="headers"
        :items="requests"
        :disable-sort="true"
        hide-default-footer
      >
        <template #[`item.amount`]="{ item }">
          {{ item.barcode_email_sent_amount }} / {{ item.amount }}
        </template>
        <template #[`item.approve`]="{ item }">
          <v-icon v-if="item.approved == 0"> mdi-minus </v-icon>
          <v-icon v-if="item.approved == 1"> mdi-plus </v-icon>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="item.approved == 0 && item.user_id == $root.user.element_id"
            @click="
              requestId = item.id;
              popup.request = true;
              dialog = true;
            "
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-1"
            title="Igénylés módosítása"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="item.approved == 1"
            @click="setBarcodes(item.id)"
            title="Vonalkódok beállítása"
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-1"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn
            v-if="item.approved == 0 && item.user_id == $root.user.element_id"
            @click="del(item.id)"
            title="Igénylés törlése"
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-1"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-row v-else>Még nem igényelt vonalkódokat.</v-row>
    <BarcodeRequestPopup
      :id="requestId"
      v-if="popup.request"
      :dialog="popup.request"
      @success="getRequests"
      @show="showDialog"
    />
    <BarcodeRequestSetup
      @save_start="isSave = true"
      @save_finish="isSave = false"
      v-if="this.$route.params.id != undefined"
    />
  </div>
</template>

<script>
// import BarcodeRequestPopup from "../qr-codes/popup.vue";
// import BarcodeRequestSetup from "../qr-codes/setup.vue";

export default {
  components: {
    BarcodeRequestPopup,
    BarcodeRequestSetup,
  },
  data() {
    return {
      requests: [],
      unapprovedAmount: 0,
      requestId: 0,
      isSave: false,
      popup: {
        request: false,
      },
      loading: false,
      headers: [
        { text: "Igénylő", value: "username" },
        { text: "Igénylés dátuma", value: "request_date" },
        { text: "Csoport", value: "groupname" },
        { text: "Mennyiség", value: "amount" },
        { text: "Megjegyzés", value: "comment" },
        { text: "Jóváhagyva", value: "approve", align: "center" },
        { text: "Jováhagyás dátuma", value: "approved_date" },
        { text: "", value: "actions", align: "end" },
      ],
      dialog: false,
    };
  },
  mounted() {
    this.getRequests();
    this.$root.user.element_id = "1"; //Nem tudja lekérni a user.element id-t és a tesztelés miatt beraktam, hogy működjön.
  },
  methods: {
    showDialog(value){
      this.popup.request = value;
    },
    setBarcodes(id) {
      this.$router.push({
        path: "/barcode/request",
        query: {
          id: id,
        },
      });
    },

    async getRequests() {
      var _this = this;
      this.loading = true;
      this.requestId = 0;
      try {
        const response = await this.$http.get("barcodeRequests/");
        _this.requests = response.requests;
        _this.unapprovedAmount = response.unapproved_amount;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async del(id) {
      try {
        const response = await this.$http.get("barcodeRequest/del/" + id);
        this.getRequests();
      } catch (e) {
        console.error(e);
      }
    },
    newBarcode(){
      //Ezt azért raktam ide mert és inkább ezt hívja meg a függvény, mert ha előbb rámegy a szerkesztésre és után a plusz gombra, akkor a popup-ban bent maradnak az adatok.
      var _this = this;
      this.requestId = 0;
      this.popup.request = true;
      this.dialog = true;
    }
  },
};
</script>
