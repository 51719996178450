import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "title": "Vissza"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Forgóvilla")]), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-turnstile-outline")]), _vm._v(" Alapadatok ")], 1), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Kontroller",
      "error-messages": _vm.errors.controller_id,
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "controller_id", $$v);
      },
      expression: "form.controller_id"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Olvasó",
      "error-messages": _vm.errors.reader_id,
      "items": _vm.readers,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "disabled": !_vm.form.controller_id,
      "persistent-hint": !_vm.form.controller_id,
      "hint": _vm.form.controller_id ? '' : 'Kontroller kötelező'
    },
    model: {
      value: _vm.form.reader_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reader_id", $$v);
      },
      expression: "form.reader_id"
    }
  }), _c(VAutocomplete, {
    attrs: {
      "label": "Relé",
      "error-messages": _vm.errors.relay_id,
      "items": _vm.relays,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "disabled": !_vm.form.controller_id,
      "persistent-hint": !_vm.form.controller_id,
      "hint": _vm.form.controller_id ? '' : 'Kontroller kötelező'
    },
    model: {
      value: _vm.form.relay_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "relay_id", $$v);
      },
      expression: "form.relay_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "rows": 3,
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _c(VCheckbox, {
    attrs: {
      "label": "Kiegészítő eszköz"
    },
    model: {
      value: _vm.form.additional,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additional", $$v);
      },
      expression: "form.additional"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Megnevezés kiegészítés",
      "error-messages": _vm.errors.name_addition,
      "filled": ""
    },
    model: {
      value: _vm.form.name_addition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name_addition", $$v);
      },
      expression: "form.name_addition"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Épület szint",
      "error-messages": _vm.errors.building_level_id,
      "items": _vm.buildingLevels,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "disabled": !_vm.form.building_id,
      "persistent-hint": !_vm.form.building_id,
      "hint": _vm.form.building_id ? '' : 'Épület kötelező'
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }