<template>
  <v-expansion-panels class="mt-4">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span>
          <v-icon left>mdi-cards-outline</v-icon>
          Kártyák
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="m-1">
          <v-col :cols="12" :sm="12" :md="3">
            <v-text-field
              v-model="search.owner_name"
              @input="searchHandler"
              label="Kártya tulajdonos"
              clearable
              hide-details
              class="my-4 my-sm-0"
            />
          </v-col>
          <v-col :cols="12" :sm="12" :md="3">
            <v-text-field
              v-model="search.card_number"
              @input="searchHandler"
              label="Kártyaszám"
              clearable
              hide-details
              class="my-4 my-sm-0"
            />
          </v-col>
          <v-col :cols="12" :sm="12" :md="3">
            <v-text-field
              v-model="search.rfid"
              @input="searchHandler"
              label="RFID"
              clearable
              hide-details
              maxlength="8"
              class="my-4 my-sm-0"
            />
          </v-col>
          <v-col :cols="12" :sm="12" :md="3">
            <v-text-field
              v-model="search.plate_number"
              @input="searchHandler"
              label="Rendszám"
              clearable
              hide-details
              maxlength="10"
              class="my-4 my-sm-0"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col :cols="12" :lg="6" :md="12">
            <v-card outlined>
              <v-card-title>
                Hozzáférési szinthez rendelt kártyák
              </v-card-title>
              <v-data-table
                :headers="dataTable.headers"
                :items="dataTable.assignedCards"
                :items-per-page="parseInt(paginationAccessLevelCards.limit)"
                :server-items-length="parseInt(paginationAccessLevelCards.amount)"
                :page="parseInt(paginationAccessLevelCards.page)"
              >
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    text
                    fab
                    small
                    dark
                    color="secondary"
                    class="rounded-sm ml-1"
                    :to="{ name: 'CardEditor', params: { id: item.element_id } }"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    fab
                    small
                    dark
                    color="secondary"
                    class="rounded-sm ml-1"
                    @click="removeAccessLevel(item.element_id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <pagination
                  v-if="assignedCards.length"
                  showcombo="1"
                  compact="1"
                  :limit="paginationAccessLevelCards.limit"
                  :amount="paginationAccessLevelCards.amount"
                  @pageChanged="fetchAssignedCards"
                  v-model="paginationAccessLevelCards.page"
                />
              </v-data-table>
            </v-card>
          </v-col>
          <!--
          <v-col :cols="12" :lg="6" :md="12">
            <v-card outlined>
              <v-card-title>
                Hozzáférési szinthez nem rendelt kártyák
              </v-card-title>
              <v-data-table
                :headers="dataTable.headers"
                :items="dataTable.unassignedCards"
                :items-per-page="parseInt(paginationNonAccessLevelCards.limit)"
                :server-items-length="parseInt(paginationNonAccessLevelCards.amount)"
                :page="parseInt(paginationNonAccessLevelCards.page)"
              >
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    text
                    fab
                    small
                    dark
                    color="secondary"
                    class="rounded-sm ml-1"
                    :to="{ name: 'CardEditor', params: { id: item.element_id } }"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    fab
                    small
                    dark
                    color="secondary"
                    class="rounded-sm ml-2"
                    @click="removeAccessLevel(item.element_id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <pagination
                  v-if="unassignedCards.length"
                  showcombo="1"
                  compact="1"
                  :limit="paginationNonAccessLevelCards.limit"
                  :amount="paginationNonAccessLevelCards.amount"
                  @pageChanged="getAccessLevelCards"
                  v-model="paginationNonAccessLevelCards.page"
                />
              </v-data-table>
            </v-card>
          </v-col>
          -->
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      paginationAccessLevelCards: {
        page: 1,
        limit: 10,
        amount: 0,
      },
      paginationNonAccessLevelCards: {
        page: 1,
        limit: 10,
        amount: 0,
      },
      dataTable: {
        headers: [
          { text: 'Tulajdonos', value: 'owner_name', sortable: false },
          { text: 'Kártyaszám', value: 'card_number', sortable: false },
          { text: 'RFID', value: 'rf_id', sortable: false },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      assignedCards: [],
      unassignedCards: [],
      search: {
        card_number: '',
        owner_name: '',
        rfid: '',
        plate_number: '',
        timeout: null,
      },
    }
  },

  mounted() {
    this.fetchAssignedCards();
    this.fetchUnassignedCards();
  },

  methods: {
    searchHandler(val) {
      this.loading = true;
      if (val.length > 0 && val.length < 3) {
        return;
      }
      clearTimeout(this.search.timeout);
      this.search.timeout = setTimeout(() => {
        this.paginationAccessLevelCards.page = 1;
        this.paginationNonAccessLevelCards.page = 1;
        this.fetchAssignedCards();
        this.fetchUnassignedCards();
      }, 500);
    },

    async fetchAssignedCards(val) {
      this.loading = true;
      this.search.page = val;
      const data = JSON.parse(JSON.stringify(this.search));
      data.access_level_ids = [this.$route.query.accesslevelID || 0];
      data.limit = this.paginationAccessLevelCards.limit;
      data.called_from = 'access_level';
      try {
        const response = await this.$http.post('cards/0/0', data);
        this.assignedCards = response.cards;
        this.paginationAccessLevelCards.amount = response.amount;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchUnassignedCards(val) {
      this.loading = true;
      this.search.page = val;
      const data = JSON.parse(JSON.stringify(this.search));
      data.not_access_level_ids = [this.$route.query.accesslevelID];
      data.limit = this.paginationNonAccessLevelCards.limit;
      data.called_from = 'access_level';
      try {
        const response = await this.$http.post('cards/0/0', data);
        this.unassignedCards = response.cards;
        this.paginationNonAccessLevelCards.amount = response.amount;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async addAccessLevel(card_element_id) {
      this.loading = true;
      const data = {
        access_level_id: this.$route.query.accesslevelID,
        to_element_id: card_element_id,
      };
      try {
        const response = await this.$http.post(
          'cards/addaccesslevel/0/0',
          data
        );
        this.fetchAssignedCards();
        this.fetchUnassignedCards();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async removeAccessLevel(card_element_id) {
      this.loading = true;
      const _this = this;
      const data = {
        access_level_id: _this.$route.query.accesslevelID,
        to_element_id: card_element_id,
      };
      try {
        const response = await this.$http.post(
          'cards/removeaccesslevel/0/0',
          data
        );
        this.fetchAssignedCards();
        this.fetchUnassignedCards();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

  }

}
</script>
