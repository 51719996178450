import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('USER_WRITE') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewUser'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Adminisztrátorok")]), _c('list-filter', {
    attrs: {
      "search": _vm.search
    }
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Név / E-mail",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.keyword,
      callback: function ($$v) {
        _vm.$set(_vm.search, "keyword", $$v);
      },
      expression: "search.keyword"
    }
  })], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "item-class": _vm.itemClass
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(VAvatar, {
          staticClass: "rounded-sm mr-2",
          attrs: {
            "color": "grey lighten-4",
            "size": "32"
          }
        }, [_c(VIcon, {
          attrs: {
            "size": "24",
            "color": "grey"
          }
        }, [_vm._v("mdi-account")])], 1), _vm._v(" " + _vm._s(item.name) + " "), !!Number(item.superadmin) ? _c(VAvatar, {
          staticClass: "ml-1",
          attrs: {
            "color": "primary",
            "size": "20",
            "title": "Superadmin"
          }
        }, [_c(VIcon, {
          attrs: {
            "size": "14",
            "color": "white"
          }
        }, [_vm._v(" mdi-star ")])], 1) : _vm._e(), !!Number(item.apiuser) ? _c(VAvatar, {
          staticClass: "ml-1",
          attrs: {
            "color": "grey lighten-2",
            "size": "20",
            "title": "API felhasználó"
          }
        }, [_c(VIcon, {
          attrs: {
            "size": "14",
            "color": "white"
          }
        }, [_vm._v(" mdi-chip ")])], 1) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.hasRight('USER_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm m-3",
          attrs: {
            "disabled": !!(!Number(_vm.$store.state.user.superadmin) && Number(item.superadmin)),
            "text": "",
            "fab": "",
            "small": "",
            "color": "secondary",
            "to": {
              name: 'UserEditor',
              params: {
                id: item.element_id
              }
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('USER_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm m-3",
          attrs: {
            "disabled": !!(!Number(_vm.$store.state.user.superadmin) && Number(item.superadmin)),
            "text": "",
            "fab": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteUser(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }