<template>
  <div>
    <title-row title="Admin napló"> </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                v-model="search.type_id"
                @change="searchEventHandler"
                label="Típus"
                :items="logTypes"
                item-text="name"
                item-value="id"
                dense
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                v-model="search.writer_id"
                @change="searchEventHandler"
                label="Létrehozó"
                :items="logWriters"
                item-text="name"
                item-value="id"
                dense
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.params"
                @input="searchEventHandler"
                label="Paraméterek"
                dense
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
        show-expand
        single-expand
      >
        <!-- <template #[`item.params`]="{ item }">
          <vue-json-pretty
            path="res"
            :data="JSON.parse(item.params)"
            collapsedOnClickBrackets
            showLine
            showLength
            :deep="1"
          />
        </template> -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="pa-4">
              <span class="text-subtitle-1 font-weight-bold">{{ item.type }}</span>
              <vue-json-pretty
                path="res"
                :data="JSON.parse(item.params)"
                collapsedOnClickBrackets
                showLine
                showLength
                :deep="1"
              />
            </div>
          </td>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            @click="showParamsDialog(item)"
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            icon
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <div class="text-center">
      <v-dialog v-if="paramsDialog.item" v-model="paramsDialog.visible" width="500">
        <v-card>
          <v-card-title>
            Paraméterek
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="hideParamsDialog" small fab>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <vue-json-pretty
              path="res"
              :data="JSON.parse(paramsDialog.item.params)"
              collapsedOnClickBrackets
              showLine
              showLength
              :deep="1"
            />
          </v-card-text>

          <v-divider class="mb-0 pb-0"></v-divider>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  components: {
    VueJsonPretty,
  },

  data() {
    return {
      routeName: 'AdminLog',
      fetchItems: this.fetchAdminLog,
      dataTable: {
        expanded: [],
        options: {
          sortBy: ['created'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Létrehozó típus', value: 'writer_type' },
          { text: 'Létrehozó ID', value: 'write_element_id' },
          { text: 'Létrehozó Név', value: 'name' },
          { text: 'Létrehozva', value: 'created' },
          { text: 'Típus', value: 'type' },
          { text: 'Komment', value: 'comment' },
          // { text: 'Paraméterek', value: 'params', sortable: false },
          // { text: '', value: 'actions', sortable: false },
          { value: 'data-table-expand' },
        ],
      },
      search: {
        type_id: null,
        writer_id: null,
      },
      logTypes: [],
      logWriters: [],
      paramsDialog: {
        msg: '',
        item: null,
        visible: false,
      },
    };
  },

  mounted() {
    this.fetchTypes();
    this.fetchWriters();
  },

  methods: {
    parseSearchQueries() {
      return {
        params: this.$route.query.params,
        writer_id: Number(this.$route.query.writer_id),
        type_id: Number(this.$route.query.type_id),
      };
    },

    hideParamsDialog() {
      this.paramsDialog.visible = false;
      this.paramsDialog.item = null;
    },

    showParamsDialog(item) {
      this.paramsDialog.visible = true;
      this.paramsDialog.item = item;
    },

    tdStyle(log) {
      if (log.row_background_color === '#ff5151') {
        return 'redback';
      } else {
        return 'whiteback';
      }
    },

    async fetchTypes() {
      try {
        const response = await this.$http.get('log-types/list');
        this.logTypes = response.log_types;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchWriters() {
      try {
        const response = await this.$http.get('logs/writers/list');
        this.logWriters = response.log_writers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchAdminLog() {
      this.loading = true;
      try {
        const response = await this.$http.post('logs/list', this.options);
        this.dataTable.items = response.logs;
        this.dataTable.itemsLength = response.logs_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        params: this.search.params || '',
        type_id: this.search.type_id || 0,
        writer_id: this.search.writer_id || 0,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.redback {
  background-color: #ff5151;
  color: #fff;
}
.whiteback {
  background-color: #fff;
  color: #000;
}
.vjs-value .vjs-value__string {
  color: red !important;
}
</style>
