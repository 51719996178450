import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": true,
      "width": "1280",
      "persistent": true,
      "overlay-opacity": 0.9,
      "dark": ""
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.barrier.name) + " "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "x-small": "",
      "fab": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c('stream-player', {
    attrs: {
      "url": ((_vm.barrier.camera_video_stream) + "?key=" + (_vm.$store.state.token))
    }
  }), _c(VDivider, {
    staticClass: "mb-0 pb-0"
  }), _c(VCardActions, {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c(VSpacer), !_vm.permanentOpen ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "title": "Kinyitás"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.openEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock-open")]), _vm._v(" Nyitás ")], 1) : _vm._e(), !_vm.permanentOpen ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "title": "Tartós nyitás"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.permanentOpenEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock-open-variant")]), _vm._v(" Tartós nyitás ")], 1) : _vm._e(), _vm.permanentOpen ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "title": "Normál üzem időpont"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.normalOperationDatetimeEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem ütemzése ")], 1) : _vm._e(), _vm.permanentOpen ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "title": "Normál üzem azonnal"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.normalOperationNowEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem azonnal ")], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }