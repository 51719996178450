import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm$groupEditor$show;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "title": "Importálás Excel fájlból",
      "disabled": _vm.hasSelect()
    },
    on: {
      "click": function ($event) {
        _vm.popup.import = true;
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-excel")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "title": "Mentés",
      "disabled": _vm.hasSelect()
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1), _vm.hasSelect() ? _c(VBtn, _vm._b({
    attrs: {
      "title": "Email küldés"
    },
    on: {
      "click": _vm.mail
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-email-outline")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Vonalkód beállítás")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "value": _vm.selectedRows.length ? (_vm$groupEditor$show = _vm.groupEditor.show) !== null && _vm$groupEditor$show !== void 0 ? _vm$groupEditor$show : 0 : null
    },
    model: {
      value: _vm.groupEditor.show,
      callback: function ($$v) {
        _vm.$set(_vm.groupEditor, "show", $$v);
      },
      expression: "groupEditor.show"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-checkbox-multiple-outline")]), _vm._v(" Csoportos beállítás ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3
    }
  }, [_c(VSelect, {
    attrs: {
      "disabled": !_vm.selectedRows.length,
      "label": "Jogosultság",
      "items": _vm.accessLevels,
      "item-value": "element_id",
      "item-text": "name",
      "chips": "",
      "small-chips": "",
      "clearable": "",
      "multiple": "",
      "hide-details": ""
    },
    model: {
      value: _vm.groupEditor.access_level_ids,
      callback: function ($$v) {
        _vm.$set(_vm.groupEditor, "access_level_ids", $$v);
      },
      expression: "groupEditor.access_level_ids"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4,
      "lg": 2
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "disabled": !_vm.selectedRows.length,
      "label": "Kezdő dátum",
      "text-field-props": {
        filled: false,
        hideDetails: true
      },
      "date-picker": true,
      "time-picker": false,
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.allStartDateTimeChange
    },
    model: {
      value: _vm.allStartDateTime,
      callback: function ($$v) {
        _vm.allStartDateTime = $$v;
      },
      expression: "allStartDateTime"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4,
      "lg": 1
    }
  }, [_vm.$store.state.user.barcode_period_fix == 0 ? _c('v-masked-text-field', {
    attrs: {
      "disabled": !_vm.selectedRows.length,
      "label": "Időtartam",
      "mask": '###',
      "hide-details": ""
    },
    on: {
      "input": _vm.allPeriodChange
    },
    model: {
      value: _vm.allPeriod,
      callback: function ($$v) {
        _vm.allPeriod = $$v;
      },
      expression: "allPeriod"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.qrCodes,
      "single-select": false,
      "show-select": "",
      "calculate-widths": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "item.rfid",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_c('small', [_vm._v(_vm._s(item.rf_id))]), _c('br'), _vm._v(_vm._s(item.code))]), item.email_sent == 1 ? _c(VRow, {
          staticClass: "m-1"
        }, [_c(VIcon, [_vm._v("mdi-email-outline")]), _c('span', [_vm._v(_vm._s(item.email_sent_user_name) + " (" + _vm._s(item.email_sent_user_email) + ")")])], 1) : _vm._e()];
      }
    }, {
      key: "item.emailData",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VTextField, {
          attrs: {
            "placeholder": "Kedvezményezett neve",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_name_' + item.id],
            "hide-details": !_vm.errors['barcode_name_' + item.id],
            "filled": "",
            "dense": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        }), _c(VTextField, {
          attrs: {
            "placeholder": "Kedvezményezett e-mail címe",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_email_' + item.id],
            "hide-details": !_vm.errors['barcode_email_' + item.id],
            "filled": "",
            "dense": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.email,
            callback: function ($$v) {
              _vm.$set(item, "email", $$v);
            },
            expression: "item.email"
          }
        })];
      }
    }, {
      key: "item.plate",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-masked-text-field', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "placeholder": "Rendszám",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_license_plate_' + item.id],
            "hide-details": !_vm.errors['barcode_license_plate_' + item.id],
            "mask": _vm.$config.inputMasks.plateNumber,
            "filled": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            }
          },
          model: {
            value: item.license_plate,
            callback: function ($$v) {
              _vm.$set(item, "license_plate", $$v);
            },
            expression: "item.license_plate"
          }
        })];
      }
    }, {
      key: "item.permissions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c(VAutocomplete, {
          attrs: {
            "placeholder": "Jogosultság",
            "disabled": !item.editable,
            "error-messages": _vm.errors['barcode_access_level_' + item.id],
            "hide-details": !_vm.errors['barcode_access_level_' + item.id],
            "items": _vm.accessLevels,
            "item-value": "element_id",
            "item-text": "name",
            "multiple": "",
            "filled": "",
            "small-chips": ""
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
              _vm.all_select = false;
            },
            "input": function ($event) {
              item.access_level_ids.sort(function (a, b) {
                return a > b ? 1 : -1;
              });
            }
          },
          model: {
            value: item.access_level_ids,
            callback: function ($$v) {
              _vm.$set(item, "access_level_ids", $$v);
            },
            expression: "item.access_level_ids"
          }
        })];
      }
    }, {
      key: "item.startDate",
      fn: function (_ref5) {
        var _vm$errors$startDate;
        var item = _ref5.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "disabled": !item.editable,
            "time-picker": true,
            "date-picker": true,
            "filled": "",
            "placeholder": "Dátum",
            "hide-details": !((_vm$errors$startDate = _vm.errors.startDate) !== null && _vm$errors$startDate !== void 0 && _vm$errors$startDate.length),
            "error-messages": _vm.errors['barcode_start_date_time_' + item.id]
          },
          on: {
            "input": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
            }
          },
          model: {
            value: item.start_date_time,
            callback: function ($$v) {
              _vm.$set(item, "start_date_time", $$v);
            },
            expression: "item.start_date_time"
          }
        })];
      }
    }, {
      key: "item.period",
      fn: function (_ref6) {
        var _vm$errors$item$id, _vm$errors$item$id2;
        var item = _ref6.item;
        return [_c('v-masked-text-field', {
          attrs: {
            "placeholder": "Időtartam",
            "filled": "",
            "disabled": !item.editable,
            "error-messages": (_vm$errors$item$id = _vm.errors[item.id]) === null || _vm$errors$item$id === void 0 ? void 0 : _vm$errors$item$id.period,
            "hide-details": !((_vm$errors$item$id2 = _vm.errors[item.id]) !== null && _vm$errors$item$id2 !== void 0 && _vm$errors$item$id2.period),
            "mask": '###'
          },
          on: {
            "keydown": function ($event) {
              item.email_can_be_sent = false;
              item.selected = false;
            },
            "keyup": function ($event) {
              return _vm.periodKeyup(item);
            }
          },
          model: {
            value: item.period,
            callback: function ($$v) {
              _vm.$set(item, "period", $$v);
            },
            expression: "item.period"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function ($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }