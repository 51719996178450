import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.exportDialog ? _c('export-dialog', {
    attrs: {
      "api-endpoint": "/access-levels/export",
      "filename": "Hozzáférési szintek kártyái",
      "worksheet-options": _vm.exportWorksheetOptions,
      "worksheet-options-item-text": "name",
      "worksheet-options-item-value": "element_id"
    },
    on: {
      "close": function ($event) {
        _vm.exportDialog = false;
      }
    }
  }) : _vm._e(), _c(VBtn, _vm._b({
    attrs: {
      "title": "Exportálás"
    },
    on: {
      "click": function ($event) {
        _vm.exportDialog = true;
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-file-export")])], 1), _vm.hasRight('ACCESS_LEVEL_WRITE') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewAccessLevel'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Hozzáférési szintek")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Csoport",
      "items": _vm.groups,
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "group_id", $$v);
      },
      expression: "search.group_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.groups",
      fn: function (_ref) {
        var item = _ref.item;
        return _vm._l(item.groups, function (group, index) {
          return _c(VChip, {
            key: ("group-" + (group.element_id) + "-" + index),
            staticClass: "ma-1",
            attrs: {
              "link": "",
              "label": "",
              "small": "",
              "to": {
                name: 'GroupEditor',
                params: {
                  id: group.element_id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(group.name) + " ")]);
        });
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.hasRight('ACCESS_LEVEL_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm mr-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "to": {
              name: 'AccessLevelEditor',
              params: {
                id: item.element_id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('ACCESS_LEVEL_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm mr-1",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "disabled": !item.erasable,
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteAccessLevel(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }