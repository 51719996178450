<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        depressed
        color="grey"
        width="60"
        height="60"
        class="ml-1"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        tile
        depressed
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Ütemzés</h1>

    <v-card :loading="loading">
      <v-card-text>
        <v-row class="m-1">
          <v-col :cols="12" :md="10" :sm="10" class="mb-0 pb-0">
            <v-text-field
              label="Megnevezés"
              v-model="form.name"
              :error-messages="errors.name"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="2" class="d-flex align-items-center justify-end">
            <v-btn
              fab
              dark
              depressed
              color="secondary"
              class="rounded-sm"
              @click="add"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="form.items"
        item-key="id"
        hide-default-footer
      >
        <template #[`item.v`]="{ index }">{{ index + 1 }}.</template>
        <template #[`item.start_time`]="{ item, i }">
          <v-masked-text-field
            v-model="item.start_time"
            :error-messages="errors[`start_time_${i}`]"
            :mask="$config.inputMasks.time"
          />
        </template>
        <template #[`item.end_time`]="{ item, i }">
          <v-masked-text-field
            v-model="item.end_time"
            :error-messages="errors[`end_time_${i}`]"
            :mask="$config.inputMasks.time"
          />
        </template>
        <template #[`item.monday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.monday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.tuesday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.tuesday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.wednesday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.wednesday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.thursday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.thursday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.friday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.friday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.saturday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.saturday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.sunday.selected`]="{ item }">
          <v-simple-checkbox
            v-model="item.sunday.selected"
            color="orange"
            :ripple="false"
          />
        </template>
        <template #[`item.date_interval_id`]="{ item, i }">
          <v-combobox
            @change="dateChange(item, i)"
            v-model="item.date_interval_id"
            :items="dateIntervals"
            item-text="label"
            item-value="value"
          />
        </template>
        <template #[`item.actions`]="{ index }">
          <v-btn
            @click="del(index)"
            fab
            small
            color="secondary"
            dark
            depressed
            class="rounded-sm"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        items: [],
      },

      dateIntervals: [
        {
          value: 0,
          label: '- válassz -',
        },
      ],
      errors: [],
      loading: false,
      headers: [
        { text: '', value: 'v', sortable: false },
        {
          text: 'Kezdő időpont (00:00:00)',
          value: 'start_time',
          sortable: false,
        },
        { text: 'Záró időpont (00:00:00)', value: 'end_time', sortable: false },
        { text: 'Hétfő', value: 'monday.selected', sortable: false },
        { text: 'Kedd', value: 'tuesday.selected', sortable: false },
        { text: 'Szerda', value: 'wednesday.selected', sortable: false },
        { text: 'Csütörtök', value: 'thursday.selected', sortable: false },
        { text: 'Péntek', value: 'friday.selected', sortable: false },
        { text: 'Szombat', value: 'saturday.selected', sortable: false },
        { text: 'Vasárnap', value: 'sunday.selected', sortable: false },
        { text: 'Dátum', value: 'date_interval_id', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      timrule: [
        (v) =>
          /^(([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$/.test(v) ||
          'Nem megfelelő formátum',
      ],
    };
  },

  async mounted() {
    try {
      const response = await this.$http.get(`schedules/details/${ this.$route.params.id || 0 }`);
      if (response.msg) {
        throw response.msg;
      }
      this.form = response.schedule;
      this.getDateIntervals();
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    dateChange(index, value) {
      console.log(index);
      //var item = this.form.items[index];
      var item = index;
      console.log(item);
      item.start_time = '00:00:00';
      item.end_time = '23:59:59';
      item.monday.selected = false;
      item.tuesday.selected = false;
      item.wednesday.selected = false;
      item.thursday.selected = false;
      item.friday.selected = false;
      item.saturday.selected = false;
      item.sunday.selected = false;
    },

    async getDateIntervals() {
      var _this = this;
      try {
        const response = await this.$http.post('date_intervals/0/0');
        response.date_intervals.map(function (di) {
          _this.dateIntervals.push({
            value: di.id,
            label: di.name,
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    del(item) {
      this.form.items.splice(item, 1);
    },

    add() {
      this.form.items.push({
        start_time: '00:00:00',
        end_time: '23:59:59',
        monday: {
          value: 1,
          selected: false,
        },
        tuesday: {
          value: 1,
          selected: false,
        },
        wednesday: {
          value: 1,
          selected: false,
        },
        thursday: {
          value: 1,
          selected: false,
        },
        friday: {
          value: 1,
          selected: false,
        },
        saturday: {
          value: 1,
          selected: false,
        },
        sunday: {
          value: 1,
          selected: false,
        },
      });
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('schedules/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$router.push({ name: 'ScheduleList' });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
