<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        :to="{ name: 'TurnstileEditor' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>
    <h1 class="py-6">Forgóvillák</h1>
    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-combobox
                label="Épület"
                v-model="search.building_id"
                @change="searchEventHandler"
                :items="buildings"
                item-text="label"
                item-value="value"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-combobox
                label="Épület szint"
                v-model="search.building_level_id"
                @change="searchEventHandler"
                :items="buildingLevels"
                item-text="label"
                item-value="value"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card :loading="loading">
      <v-data-table
        :headers="headers"
        :items="turnstiles"
        :items-per-page="parseInt(search.limit)"
        :server-items-length="parseInt(search.amount)"
        :page="parseInt(search.page)"
        :options.sync="options"
        @update:sort-by="sortChanged"
        @update:sort-desc="sortChanged"
        hide-default-footer
      >
        <template #[`item.k`]="{ item }"
          >{{ item.x_coord }}, {{ item.y_coord }}</template
        >
        <template
          #[`item.actions`]="{ item }"
          class="d-flex justify-content-end"
          text-end
        >
          <v-btn
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            :to="`/devices/turnstiles/edit/${item.element_id}`"
            title="Módosítás"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            @click="del(item.element_id)"
            title="Törlés"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider class="mt-0"></v-divider>
      <div class="px-4">
        <pagination
          v-if="turnstiles.length > 0"
          showcombo="1"
          :limit="search.limit"
          :amount="search.amount"
          v-on:pageChanged="pageChanged"
          v-model="search.page"
        ></pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      search: {
        building_id: 0,
        building_level_id: 0,
        name: "",
        page: 1,
        limit: 100,
        amount: 0,
      },

      turnstiles: [],
      buildings: [],
      buildingLevels: [
        {
          label: "- összes -",
          value: 0,
        },
      ],
      loading: false,
      searchTimeout: null,
      options: {
        sortBy: ["name"],
      },
      headers: [
        { text: "Megnevezés", value: "name" },
        { text: "Épület", value: "building" },
        { text: "Épület szint", value: "building_level" },
        { text: "Kontroller", value: "controller" },
        { text: "Koordináta", value: "k" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
    };
  },

  created() {
    this.routerChange();
  },

  mounted() {
    this.getBuildings();

    if (this.search.building_id != 0) {
      this.getBuildingLevels();
    }
  },

  methods: {
    del(id) {
      var _this = this;

      swal({
        title: "Törli a forgóvillát?",
        text: "",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Igen",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "Nem",
            value: false,
            visible: true,
            closeModal: true,
          },
        },
      }).then(async function (result) {
        if (result) {
          try {
            const response = await this.$http.get("turnstile/delete/" + id);
            this.getItems();
          } catch (e) {
            console.error(e);
          }
        }
      });
    },

    buildingChange(v) {
      var _this = this;

      if (_this.search.building_id == 0) {
        _this.buildingLevels = [
          {
            label: "- összes -",
            value: 0,
          },
        ];
        _this.search.building_level_id = 0;
        return;
      }

      this.getBuildingLevels();
      this.search.building_level_id = 0;
    },

    async getBuildings() {
      var _this = this;
      try {
        const response = await this.$http.post("buildings/1");
        var tmp = [
          {
            label: "- összes -",
            value: 0,
          },
        ];

        response.buildings.map(function (building) {
          tmp.push({
            label: building.name,
            value: building.id,
          });
        });
        _this.buildings = tmp;
      } catch (e) {
        console.error(e);
      }
    },

    async getBuildingLevels() {
      var _this = this;
      try {
        const response = await this.$http.post(
          "building_levels/1",
          _this.search.building_id
        );
        var tmp = [
          {
            label: "- összes -",
            value: 0,
          },
        ];
        response.levels.map(function (level) {
          tmp.push({
            label: level.name,
            value: level.id,
          });
        });
        _this.buildingLevels = tmp;
      } catch (e) {
        console.error(e);
      }
    },

    routerChange() {
      if (Object.keys(this.$route.query).length != 0) {
        this.search = {
          ...this.search,
          ...this.$route.query,
        };
      }
      this.getItems();
    },

    searchEventHandler() {
      this.loading = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search.page = 1;
        this.$router.replace({ name: "TurnstileList", query: this.search });
        this.getItems();
      }, 500);
    },

    sortChanged() {
      this.search.page = 1;
      this.getItems();
    },

    pageChanged(val) {
      this.search.page = val;
      this.$router.replace({ name: "TurnstileList", query: this.search });
      this.getItems();
    },
    async getItems() {
      var _this = this;
      this.loading = true;
      try {
        const data = JSON.parse(JSON.stringify(this.search));
        data.sort_by = this.options.sortBy || [""];
        data.sort_desc = this.options.sortDesc || [false];
        const response = await this.$http.post("turnstiles/0/0", data);
        this.turnstiles = response.turnstiles;
        this.search.amount = response.amount;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
