<template>
  <div>
    <title-row title="Távoli nyitás">
      <template #buttons>
        <title-row-button
          @click="showStreams = !showStreams"
          :color="showStreams ? 'primary' : 'muted'"
        >
          <v-icon left>{{ showStreams ? 'mdi-cctv' : 'mdi-cctv' }}</v-icon>
          Kameraképek
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :md="3" :sm="6">
              <v-select
                label="Típus"
                v-model="search.model_names"
                :items="deviceTypes"
                item-text="name"
                item-value="model_name"
                @change="searchEventHandler"
                multiple
                hide-details
              />
            </v-col>
            <v-col :cols="12" :md="3" :sm="6">
              <v-autocomplete
                label="Épület"
                v-model="search.building_id"
                :items="buildings"
                item-text="name"
                item-value="id"
                @input="searchEventHandler"
                hide-details
                clearable
                :value-comparator="$config.comparators.isEqual"
              />
            </v-col>
            <v-col :cols="12" :md="3" :sm="6">
              <v-text-field
                label="Megnevezés"
                v-model="search.element_name"
                @input="searchEventHandler"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.model_name`]="{ item }">
          {{ gateType(item.model_name).name }}
        </template>
        <template #[`item.stream`]="{ item }">
          <v-sheet
            v-if="showStreams && item.stream_id"
            width="290"
            height="164"
            outlined
            rounded
            @click="barrier = item"
            style="cursor: pointer"
            color="grey darken-1"
          >
            <stream-player
              v-if="item.stream_id"
              :key="item.stream_id"
              :server-address="item.stream_server_address"
              :stream-id="item.stream_id"
              :channel="2"
              :css-filters="item.css_filters"
              :url="`${item.stream_id}?key=${$store.state.token}`"
            />
          </v-sheet>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="my-2">
            <template v-if="item && item.camera_video_stream">
              <v-btn
                text
                block
                small
                class="my-2 rounded-sm"
                color="secondary"
                @click="barrier = item"
              >
                <v-icon left>mdi-cctv</v-icon>
                Kamerakép
              </v-btn>
            </template>
            <template v-if="item.permanent_open == 0">
              <v-btn text block small class="my-2 rounded-sm" color="secondary" @click="open(item)">
                <v-icon left>mdi-lock-open</v-icon>
                Nyitás
              </v-btn>
              <v-btn
                v-if="!item.nciom_control_seq_id"
                text
                block
                small
                class="my-2 rounded-sm"
                color="secondary"
                @click="permanentOpen(item)"
              >
                <v-icon left>mdi-lock-open-variant</v-icon>
                Nyitva tartás
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                text
                block
                small
                class="my-2 rounded-sm"
                color="secondary"
                @click="normalOperationDatetime(item)"
              >
                <v-icon left>mdi-lock</v-icon>
                Normál üzem ütemzése
              </v-btn>
              <v-btn
                text
                block
                small
                class="my-2 rounded-sm"
                color="secondary"
                @click="normalOperationNow(item)"
              >
                <v-icon left>mdi-lock</v-icon>
                Normál üzem azonnal
              </v-btn>
            </template>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <camera-view-dialog
      v-if="barrier"
      :openEventHandler="open"
      :permanentOpenEventHandler="permanentOpen"
      :normalOperationDatetimeEventHandler="normalOperationDatetime"
      :normalOperationNowEventHandler="normalOperationNow"
      :barrier="barrier"
      @close="barrier = null"
    />

    <normal-mode-dialog
      v-if="normalModeDialogElementId"
      @saved="normalOperationDateTimeSaved"
      :elementId="normalModeDialogElementId"
      @close="normalModeDialogElementId = null"
    />
  </div>
</template>

<script>
import StreamPlayer from '@/components/StreamPlayerMSE.vue';

import CameraViewDialog from './CameraViewDialog.vue';
import NormalModeDialog from './NormalModeDialog.vue';
import { dataTablePage } from '@/mixins';

export default {
  components: {
    CameraViewDialog,
    NormalModeDialog,
    StreamPlayer,
  },

  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'RemoteOpen',
      fetchItems: this.fetchGates,
      dataTable: {
        options: {
          sortBy: ['element_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'element_name', width: '160px', cellClass: 'font-weight-bold' },
          { text: 'Épület', value: 'building_name', width: '160px' },
          { text: 'Típus', value: 'model_name', width: '80px' },
          { text: '', value: 'stream', width: '200px', sortable: false },
          { text: '', value: 'actions', sortable: false, align: 'end', width: '100px' },
        ],
      },
      search: {
        building_id: 0,
        element_name: '',
        model_names: ['Doors', 'Barriers', 'Turnstiles'],
      },
      deviceTypes: [
        {
          name: 'Ajtó',
          model_name: 'Doors',
        },
        {
          name: 'Sorompó',
          model_name: 'Barriers',
        },
        {
          name: 'Forgóvilla',
          model_name: 'Turnstiles',
        },
      ],
      barrier: 0,
      normalModeDialogElementId: 0,
      showStreams: false,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        element_name: this.$route.query.element_name || '',
        building_id: Number(this.$route.query.building_id) || 0,
        model_names: this.$route.query.model_names || '',
      };
    },

    async fetchGates() {
      this.loading = true;
      try {
        const response = await this.$http.post('remote-open/elements/list', this.options);
        this.dataTable.items = response.gates;
        this.dataTable.itemsLength = response.gates_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async open(item) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: `<b>${item.name}</b> kinyitására készül! Nyitás után automatikusan visszazáródik.<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        return;
      }

      try {
        const response = await this.$http.get(`remote-open/open/${item.element_id}`);
        if (response.status === 'OK') {
          this.$dialog.notify.success('Nyitás parancs elküldve!');
          this.fetchGates();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async permanentOpen(item, openWarning = false) {
      const description = openWarning
        ? 'FIGYELEM! Ebben az ajtóban olyan zár található, melynek hosszan nyitva tartása annak meghibásodását okozhatja. Ennek az opciónak a használata itt nem javasolt, biztosan folytatja?'
        : 'Nyitás után nem fog visszazárni automatikusan.';
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> tartós kinyitására készül!<br>${description}<br><br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        return;
      }

      try {
        const response = await this.$http.get(`remote-open/open/permanent/${item.element_id}`);
        if (response.status === 'OK') {
          this.$dialog.notify.success('Tartós nyitás parancs elküldve!');
          this.fetchGates();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async normalOperationNow(item) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: `<b>${item.name}</b> alapértelmezett működésének visszaállítására készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        return;
      }

      try {
        const response = await this.$http.post(
          `remote-open/default-operating-mode/${item.element_id}`,
          { immediate: true }
        );
        if (response.status === 'OK') {
          this.$dialog.notify.success('Normál működés parancs elküldve!');
          this.fetchGates();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async normalOperationDatetime(item) {
      return new Promise((resolve, reject) => {
        this.normalModeDialogElementId = item.element_id;
        const interval = setInterval(() => {
          if (!this.normalModeDialogElementId) {
            clearInterval(interval);
            this.$dialog.notify.success('Normál működés parancs ütemezve!');
            resolve();
          }
        }, 100);
      });
    },

    normalOperationDateTimeSaved() {
      this.normalModeDialogElementId = null;
      this.fetchGates();
    },

    gateType(modelName) {
      return this.deviceTypes.find((e) => e.model_name === modelName);
    },
  },

  computed: {
    searchQueries() {
      return {
        building_id: this.search.building_id || 0,
        element_name: this.search.element_name || '',
        model_names: this.search.model_names || '',
      };
    },
  },

  asyncComputed: {
    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list', {
          type: this.search.model_names === 'Barriers' ? 'P' : null,
        });
        return response.buildings;
      },
      default: [],
    },
  },
};
</script>
