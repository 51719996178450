import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": "Távoli nyitás"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "color": _vm.showStreams ? 'primary' : 'muted'
          },
          on: {
            "click": function ($event) {
              _vm.showStreams = !_vm.showStreams;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v(_vm._s(_vm.showStreams ? 'mdi-cctv' : 'mdi-cctv'))]), _vm._v(" Kameraképek ")], 1)];
      },
      proxy: true
    }])
  }), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Típus",
      "items": _vm.deviceTypes,
      "item-text": "name",
      "item-value": "model_name",
      "multiple": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.model_names,
      callback: function ($$v) {
        _vm.$set(_vm.search, "model_names", $$v);
      },
      expression: "search.model_names"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Épület",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      "clearable": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "building_id", $$v);
      },
      expression: "search.building_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.element_name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "element_name", $$v);
      },
      expression: "search.element_name"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.model_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.gateType(item.model_name).name) + " ")];
      }
    }, {
      key: "item.stream",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.showStreams && item.stream_id ? _c(VSheet, {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "width": "290",
            "height": "164",
            "outlined": "",
            "rounded": "",
            "color": "grey darken-1"
          },
          on: {
            "click": function ($event) {
              _vm.barrier = item;
            }
          }
        }, [item.stream_id ? _c('stream-player', {
          key: item.stream_id,
          attrs: {
            "server-address": item.stream_server_address,
            "stream-id": item.stream_id,
            "channel": 2,
            "css-filters": item.css_filters,
            "url": ((item.stream_id) + "?key=" + (_vm.$store.state.token))
          }
        }) : _vm._e()], 1) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "my-2"
        }, [item && item.camera_video_stream ? [_c(VBtn, {
          staticClass: "my-2 rounded-sm",
          attrs: {
            "text": "",
            "block": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              _vm.barrier = item;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-cctv")]), _vm._v(" Kamerakép ")], 1)] : _vm._e(), item.permanent_open == 0 ? [_c(VBtn, {
          staticClass: "my-2 rounded-sm",
          attrs: {
            "text": "",
            "block": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.open(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-lock-open")]), _vm._v(" Nyitás ")], 1), !item.nciom_control_seq_id ? _c(VBtn, {
          staticClass: "my-2 rounded-sm",
          attrs: {
            "text": "",
            "block": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.permanentOpen(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-lock-open-variant")]), _vm._v(" Nyitva tartás ")], 1) : _vm._e()] : [_c(VBtn, {
          staticClass: "my-2 rounded-sm",
          attrs: {
            "text": "",
            "block": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.normalOperationDatetime(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem ütemzése ")], 1), _c(VBtn, {
          staticClass: "my-2 rounded-sm",
          attrs: {
            "text": "",
            "block": "",
            "small": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.normalOperationNow(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem azonnal ")], 1)]], 2)];
      }
    }], null, true)
  })], 1), _vm.barrier ? _c('camera-view-dialog', {
    attrs: {
      "openEventHandler": _vm.open,
      "permanentOpenEventHandler": _vm.permanentOpen,
      "normalOperationDatetimeEventHandler": _vm.normalOperationDatetime,
      "normalOperationNowEventHandler": _vm.normalOperationNow,
      "barrier": _vm.barrier
    },
    on: {
      "close": function ($event) {
        _vm.barrier = null;
      }
    }
  }) : _vm._e(), _vm.normalModeDialogElementId ? _c('normal-mode-dialog', {
    attrs: {
      "elementId": _vm.normalModeDialogElementId
    },
    on: {
      "saved": _vm.normalOperationDateTimeSaved,
      "close": function ($event) {
        _vm.normalModeDialogElementId = null;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }