<template>
  <v-dialog v-if="value" :value="true" persistent max-width="480px">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-calendar-plus</v-icon>
        <template v-if="!created">Új foglalás</template>
        <template v-else>Foglalás módosítása</template>
      </v-card-title>

      <v-card-text class="mt-12">
        <v-text-field v-model="name" label="Megnevezés" :error-messages="errors.name" filled/>
        <!-- <v-row>
          <v-col>
          </v-col>
          <v-col>
          </v-col>
        </v-row> -->
        <v-datetime-picker v-model="start_datetime" label="Foglalás kezdete" :error-messages="errors.start_datetime" filled/>
        <v-datetime-picker v-model="end_datetime" label="Foglalás vége" :error-messages="errors.end_datetime" filled/>
        <v-textarea v-model="remark" filled label="Megjegyzés" rows="2" auto-grow/>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="close">Mégse</v-btn>
        <v-btn color="primary" depressed :disabled="loading" :loading="loading" @click="submit">
          Importálás
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    keyId: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      value: false,
      loading: false,
      errors: {},

      name: '',
      start_datetime: '',
      end_datetime: '',
      remark: '',
      created: null,
      modified: null,
    }
  },

  methods: {
    // open(event, reservation) {
    //   this.name = reservation.name || '';
    //   this.start_datetime = reservation.start_datetime || `${event.date} ${event.time}`;
    //   this.end_datetime = reservation.end_datetime || '';
    //   this.created = reservation.created || null;
    //   this.modified = reservation.modified || null;

    //   this.value = true;
    // },

    edit(e) {
      if (this.value) {
        return;
      }

      this.name = e.name || '';
      this.start_datetime = e.start_datetime || '';
      this.end_datetime = e.end_datetime || '';
      this.created = e.created || null;
      this.modified = e.modified || null;

      this.value = true;
    },

    create(e) {
      if (this.value) {
        return;
      }

      this.start_datetime = `${e.date} ${String(e.hour).padStart(2, '0')}:00`;

      this.value = true;
    },

    close() {
      this.value = false;
    },

    async submit() {
      try {
        this.errors = {};
        this.loading = true;

        const response = await this.$http.post('keys/reservations/save', {
          key_id: this.keyId,
          name: this.name,
          start_datetime: this.start_datetime,
          end_datetime: this.end_datetime,
          remark: this.remark,
        });

        if (response.errors) {
          this.errors = response.errors;
        }
      }
      catch(e) {
        console.errors(e);
      } finally {
        this.loading = false;
      }

      this.$emit('success');
    }
  }
}
</script>
