var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "floating-buttons"
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: this.query.page == 1,
      expression: "this.query.page == 1"
    }],
    staticClass: "fl-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.refreshingChange();
      }
    }
  }, [_c('i', {
    class: this.refreshing_icon
  })])]), _vm.$route.params.type === 'Building' ? _c('h1', [_vm._v("Épület log")]) : _vm.$route.params.type === 'ParkingLot' ? _c('h1', [_vm._v("Parkoló log")]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-xs-2"
  }, [_c('comboboxV2', {
    attrs: {
      "id": "type",
      "label": "Típus",
      "name": "type",
      "options": _vm.search.type.items,
      "value": "enter_identified"
    },
    on: {
      "input": _vm.typeChange
    }
  })], 1), _c('div', {
    staticClass: "col-xs-2"
  }, [_c('comboboxV2', {
    attrs: {
      "id": "buildings",
      "label": _vm.building_type == 'P' ? 'Parkoló' : 'Épület',
      "name": "buildings",
      "options": _vm.search.buildings.items
    },
    on: {
      "input": _vm.buildingChange
    }
  })], 1), _c('div', {
    staticClass: "col-xs-2"
  }, [_c('comboboxV2', {
    attrs: {
      "id": "time_limit",
      "label": "Időkorlát",
      "name": "time_limit",
      "options": _vm.search.time_limits.items,
      "value": "1_DAY"
    },
    on: {
      "input": _vm.timeLimitChange
    }
  })], 1), _c('div', {
    staticClass: "col-xs-2"
  }, [_c('comboboxV2', {
    attrs: {
      "id": "controller",
      "label": "Kontroller",
      "name": "controller",
      "options": _vm.search.controllers.items,
      "value": "1_DAY"
    },
    on: {
      "input": _vm.controllerChange
    }
  })], 1), _c('div', {
    staticClass: "col-xs-4"
  }, [_c('inputbox', {
    attrs: {
      "id": "kerkif",
      "label": _vm.building_type == 'P' ? 'Név / Kártyaszám / Vonalkód száma / Rendszám' : 'Név / Kártyaszám / Vonalkód száma',
      "name": "kerkif"
    },
    on: {
      "keyup": _vm.kerkifChange
    },
    model: {
      value: _vm.query.kerkif,
      callback: function ($$v) {
        _vm.$set(_vm.query, "kerkif", $$v);
      },
      expression: "query.kerkif"
    }
  })], 1)]), _vm.logs.length > 0 ? _c('div', {
    staticClass: "table-with-title"
  }, [_c('h2', [_vm._v("Log")]), _c('table', {
    staticClass: "list"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.logs, function (log, i) {
    return _c('tr', {
      key: i
    }, [_c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.timestamp))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.creator_name))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(" " + _vm._s(log.card_number ? log.card_rule_prefix + log.card_number : log.barcode_number) + " ")]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.gatename))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.stationid))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.gateid))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.type))]), _c('td', {
      style: _vm.tdStyle(log)
    }, [_vm._v(_vm._s(log.message))]), log.rf_id ? _c('td', {
      style: _vm.tdStyle(log)
    }, [_c('i', {
      staticClass: "small-button customicon customicon-search",
      staticStyle: {
        "font-size": "18px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.cardSearch(log.element_id);
        }
      }
    })]) : _c('td')]);
  }), 0)])]) : _vm._e(), _vm.logs.length == 0 ? _c('div', {
    staticClass: "normal-text"
  }, [_vm._v(" Nem található log vagy nincs jogosultsága a megtekintéshez. ")]) : _vm._e(), _vm.logs.length > 0 ? _c('pagination', {
    attrs: {
      "showcombo": "1",
      "limit": _vm.query.limit,
      "amount": _vm.query.amount
    },
    on: {
      "pageChanged": _vm.pageChanged
    },
    model: {
      value: _vm.query.page,
      callback: function ($$v) {
        _vm.$set(_vm.query, "page", $$v);
      },
      expression: "query.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xs-12"
  }, [_c('h2', [_vm._v("Kereső")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Idő")]), _c('th', [_vm._v("Név")]), _c('th', [_vm._v("Kártyaszám")]), _c('th', [_vm._v("Hely")]), _c('th', [_vm._v("Station ID")]), _c('th', [_vm._v("Gate ID")]), _c('th', [_vm._v("Típus")]), _c('th', [_vm._v("Esemény")]), _c('th')])]);
}]

export { render, staticRenderFns }