<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="pt-0">Jelszó módosítás</h1>

    <v-row>
      <v-col :cols="12" :md="6" :lg="4">
        <v-card>
          <v-card-text>
            <v-text-field
              filled
              type="password"
              label="Jelszó"
              :error-messages="errors.password"
              name="password"
              v-model="password"
            />
            <v-text-field
              filled
              type="password"
              label="Jelszó megerősítése"
              :error-messages="errors.password_"
              name="password_"
              v-model="password_"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: '',
    password_: '',
    errors: [],
    loading: false,
  }),
  methods: {
    async save() {
      const data = {
        password: this.password,
        password_: this.password_,
      };

      this.errors = {};
      this.loading = true;
      const response = await this.$http.post('users/change_password/', data);
      this.loading = false;

      if (Object.keys(response.errors).length > 0) {
        this.errors = response.errors;
      } else {
        this.password = '';
        this.password_ = '';

        this.$dialog.notify.success('A jelszó megváltozott!');
      }
    },
  },
};
</script>
