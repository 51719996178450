<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="form.status?.name !== 'permInactive'"
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
        title="Mentés"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status?.name === 'idReading'"
        tile
        depressed
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="setRFID()"
        :disabled="loading"
        title="Kártya beolvasása"
        dark
      >
        <v-icon>mdi-card-search</v-icon>
      </v-btn>
    </div>

    <h1 class="my-6" v-if="$route.name === 'NewCard'">Új kártya</h1>
    <h1 class="my-6" v-else>Kártya szerkesztése</h1>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12" :md="6" :lg="4">
        <v-card>
          <v-card-title><v-icon left>mdi-badge-account-horizontal</v-icon>Alapadatok</v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Kártyabirtokos"
              :error-messages="errors.owner_id"
              v-model="form.owner_id"
              :items="cardOwners"
              :search-input.sync="search"
              cache-items
              item-value="element_id"
              filled
              :hint="this.form.owner_id ? 'Nem módosítható' : ''"
              persistent-hint
              :readonly="
                !!(
                  this.$route.params.ownerId ||
                  form.element_id !== 0 ||
                  $route.params.id !== undefined
                )
              "
            >
              <template #selection="{ item }">
                {{ item.name }} <span class="ml-2" v-if="item.email">({{ item.email }})</span>
              </template>
              <template #item="{ item }">
                {{ item.name }} <span class="ml-2" v-if="item.email">({{ item.email }})</span>
              </template>
            </v-autocomplete>
            <v-masked-text-field
              v-if="form.element_id != 0"
              label="Kártyaszám"
              v-model="form.card_number"
              :mask="$config.inputMasks.cardNumber"
              readonly
              hint="Nem módosítható"
              persistent-hint
              filled
            />
            <v-text-field
              v-if="form.element_id != 0"
              label="Státusz"
              :value="form.status?.name"
              readonly
              hint="Nem módosítható"
              persistent-hint
              filled
            />
            <!-- <v-autocomplete
              label="Sablon"
              :error-messages="errors.template_id"
              v-model="form.template_id"
              :items="cardTemplates"
              item-text="name"
              item-value="id"
              @change="showPrintIcon = false"
              :readonly="form.status.name_ && form.status.name_ !== 'printReady'"
              :hint="
                form.status.name_ && form.status.name_ !== 'printReady' ? 'Nem módosítható' : ''
              "
              persistent-hint
              filled
            />
            <v-text-field
              :label="
                form.status === 'idReading'
                  ? 'RF ID (Beolvasáshoz érintse a kártyát az olvasóhoz)'
                  : 'RF ID'
              "
              :error-messages="errors.rf_id"
              v-model="form.rf_id"
              readonly
              :disabled="form.status != 'idReading'"
              v-if="['active', 'tempInactive', 'permInactive', 'idReading'].includes(form.status)"
              filled
            /> -->
          </v-card-text>
        </v-card>
        <v-card class="mt-6">
          <v-card-title><v-icon left>mdi-account-school</v-icon>Neptun képzések</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              v-if="neptunCourses === null"
              class="mx-auto"
              type="list-item-three-line"
            ></v-skeleton-loader>
            <v-alert
              v-if="neptunCourses && neptunCourses.length === 0"
              icon="mdi-alert"
              color="muted"
            >
              Nem található képzés a rendszerben.
            </v-alert>

            <v-list-item v-else v-for="course in neptunCourses" :key="course.szakkod">
              <v-list-item-icon>
                <v-icon v-if="course.statusz === 'Aktív'" color="success" :title="course.statusz"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else color="grey darken-1" :title="`Inaktív (${course.statusz})`"
                  >mdi-close-circle-outline</v-icon
                >
              </v-list-item-icon>
              <div>
                <div>{{ course.szak }}</div>
                <div>{{ course.tagozat }} ({{ course.szakkod }})</div>
              </div>
            </v-list-item>

            <!-- <v-simple-table v-else>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="course in neptunCourses"
                    :key="course.szakkod"
                  >
                    <td>
                      <v-icon v-if="course.statusz === 'Aktív'" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="muted">mdi-close-circle-outline</v-icon>
                    </td>
                    <td>{{ course.szak }} ({{ course.szakkod}}) - {{ course.tagozat }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="6" :lg="4">
        <v-card>
          <v-card-title><v-icon left>mdi-security</v-icon>Hozzáférési szintek</v-card-title>
          <v-multi-select-list
            :headers="[
              { text: 'Megnevezés', value: 'name' },
              { text: 'Lejárat', value: 'valid_to' },
            ]"
            :items="accessLevels"
            v-model="form.access_levels"
            item-value="element_id"
            item-text="name"
            unique-values
            :blank-item-template="{ element_id: 0 }"
          >
            <template #[`item.name`]="{ item, items }">
              <v-autocomplete
                :items="items"
                v-model="item.element_id"
                item-text="name"
                item-value="element_id"
                placeholder="Válassz"
                hide-details
                filled
                dense
              />
            </template>
            <template #[`item.valid_to`]="{ item }">
              <v-datetime-picker
                v-model="item.valid_to"
                filled
                placeholder="Nincs lejárat"
                clearable
                dense
                hide-details
                :time-picker="false"
              />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>

      <v-col :cols="12" :md="6" :lg="4">
        <v-card>
          <v-card-title><v-icon left>mdi-car</v-icon>Rendszámok</v-card-title>
          <v-multi-select-list
            :headers="[{ text: 'Rendszám', value: 'name' }]"
            :items="form.plates"
            v-model="form.plates"
            item-value="number"
            item-text="number"
            hide-default-header
            :blank-item-template="{ number: '' }"
          >
            <template #[`item.name`]="{ item, index }">
              <v-masked-text-field
                :label="`#${item.id || index + 1}`"
                v-model="item.number"
                :error-messages="errors[`plate-${index}`]"
                filled
                dense
                :hide-details="!item.active_season_ticket"
                v-uppercase
                :mask="$config.inputMasks.plateNumber"
              />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>

      <v-col v-if="hasFeature('PARKING_PENALTY')" :cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-gavel</v-icon>
            Parkolási büntetések
          </v-card-title>
          <parking-penalties :card="form" />
        </v-card>
      </v-col>

      <v-col :cols="12">
        <v-card>
          <v-card-title><v-icon left>mdi-history</v-icon>Eseménynapló</v-card-title>
          <event-log
            v-if="form.element_id"
            :log="form.log"
            :access-levels="accessLevels"
            :card-statuses="cardStatuses"
          ></event-log>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ParkingPenalties, CardEventLog } from '@/components';

export default {
  components: {
    EventLog: CardEventLog,
    ParkingPenalties,
  },

  data() {
    return {
      form: {
        status: {},
        owner: {},
        access_levels: [],
      },
      formSnapshot: null,
      newAccessLevelId: 0,
      newAccessLevels: [],
      cardOwners: [],
      logs: [],
      accessLevels: [],
      cardStatuses: [],
      cardAccessLevelsGroups: [],
      cardTemplates: [],
      showPrintIcon: true,
      parkingPenalty: {
        start_date: '',
        interval: '1 MONTH',
        reason: '',
      },
      errors: {},
      loading: false,
      socket: '',
      headers: [
        { text: 'Adminisztrátor', value: 'writer_name', sortable: false },
        { text: 'Módosítva', value: 'created', sortable: false },
        { value: 'v', sortable: false },
      ],
      select: null,
      search: null,
    };
  },

  watch: {
    search(val) {
      val && val !== this.select && this.queryOwners(val);
    },
  },

  beforeDestroy() {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }
  },

  mounted() {
    this.fetchCard();
  },

  methods: {
    queryOwners(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },

    setAccessLevelsFromGroup(group_id) {
      this.form.access_level_ids = [];
      this.cardAccessLevelsGroups.map((item) => {
        if (item.group_id == group_id) {
          this.form.access_level_ids = JSON.parse(JSON.stringify(item.access_level_ids));
        }
      });

      //this.setNewAccessLevels();
    },

    async fetchCard() {
      try {
        this.loading = true;
        const response = await this.$http.get(`cards/details/${this.$route.params.id || 0}`);
        this.form = response.card;
        if (this.$route.params.ownerId) {
          this.form.owner_id = this.$route.params.ownerId;
        }
        this.formSnapshot = JSON.stringify(this.form);
        this.accessLevels = response.access_levels;
        this.cardStatuses = response.card_statuses;
        this.cardOwners = response.card_owners;
        //this.setNewAccessLevels();
        this.cardAccessLevelsGroups = response.card_access_levels_groups;
        this.cardTemplates = response.card_templates;
        if (this.form.status === 'printReady') {
          this.showPrintIcon = true;
        }
        if (this.form.element_id) {
          // this.getLatestLogs();
          //this.getLatestParkingPenalties();
          if (this.form.status === 'idReading') this.watchCardReader();
        }
        if (this.form.element_id === 0 && !this.$route.params.id) {
          this.form.owner.element_id = this.$route.params.id;
        }
        this.formSnapshot = this.lodash.cloneDeep(this.form);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /*setNewAccessLevels() {
      var _this = this;
      this.newAccessLevels = [];
      this.accessLevels.map(function (al) {
        if (_this.form.access_level_ids.indexOf(al.value) < 0)
          _this.newAccessLevels.push(al);
      });
      setTimeout(function () {
        if (_this.$refs["newAccessLevelId"] != undefined)
          _this.$refs["newAccessLevelId"].reload();
      }, 100);
    },*/

    async save() {
      // for (const [index, plate] of this.form.plates.entries()) {
      //   plate.input_id = `plate-${index}`;
      // }

      const data = this.lodash.cloneDeep(this.form);
      delete data.log;
      delete data.status;
      data.deleted_access_level_ids = this.lodash
        .differenceWith(
          this.formSnapshot.access_levels,
          this.form.access_levels,
          (a, b) => a.element_id === b.element_id
        )
        .map((e) => e.element_id);
      data.updated_access_levels = this.lodash.differenceWith(
        this.form.access_levels,
        this.formSnapshot.access_levels,
        (a, b) => a.element_id === b.element_id && a.valid_to === b.valid_to
      );
      data.deleted_plates = this.lodash.differenceWith(
        this.formSnapshot.plates,
        this.form.plates,
        (a, b) => a.id === b.id
      );

      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('cards/save', data);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewCard') {
            this.$router.push({ name: 'CardEditor', params: { id: response.card.element_id } });
          } else {
            this.fetchCard();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async setRFID() {
      if (this.form.rf_id === '') {
        return;
      }
      const result = await this.$dialog.confirm({
        text: 'A megfelelő kártyát érintette az olvasóhoz?',
        title: 'Figyelem!',
        color: 'primary',
      });
      if (result) {
        this.loading = true;
        try {
          const data = {
            card_id: this.form.element_id,
            rf_id: this.form.rf_id,
          };
          await this.$http.post('card/set_rfid/', data);
          this.fetchCard();
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      } else {
        this.form.rf_id = '';
      }
    },
  },

  computed: {
    assignedAccessLevels() {
      return this.lodash.filter(this.accessLevels, (al) => {
        console.log(this.form.access_level_ids);
        return this.lodash.includes(this.form.access_level_ids, al.element_id);
      });
      // const accessLevels = [];
      // for (const accessLevel of this.accessLevels) {
      //   if (this.form.access_level_ids.includes(accessLevel.element_id)) {
      //     accessLevels.push(accessLevel);
      //   }
      // }
      // console.log(accessLevels);
      // return accessLevels;
    },

    isDirty() {
      return JSON.stringify(this.form) === this.formSnapshot;
    },
  },

  asyncComputed: {
    neptunCourses: {
      async get() {
        const response = await this.$http.get(`cards/neptun-courses/${this.$route.params.id || 0}`);
        return response.courses.sort((a, b) => a.tagozat < b.tagozat);
      },
      default: null,
    },
  },
};
</script>
