var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Üdvözöljük")]), _c('div', {
    staticClass: "normal-text mb-8"
  }, [_vm._v(" A rendszer használatához válasszon a bal oldali menüpontok közül! ")])]);
}]

export { render, staticRenderFns }