<template>
  <div>
    <export-dialog v-if="exportDialog" filename="Kártyabirtokosok" api-endpoint="/card-owners/export"
      @close="exportDialog = false" />
    <title-row title="Kártyabirtokosok">
      <template #buttons>
        <title-row-button @click="exportDialog = true" color="muted">
          <v-icon left>mdi-file-export</v-icon>
          Exportálás
        </title-row-button>

        <title-row-button :to="{ name: 'NewCardOwner' }">
          <v-icon left>mdi-plus</v-icon>
          Új kártyabirtokos
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field v-model="search.name" @input="searchEventHandler" label="Név" dense clearable hide-details
                class="my-4 my-sm-0" />
            </v-col>

            <template v-for="field in extraFields">
              <v-col :cols="12" :sm="4" :md="3" v-if="field.searchable == 1 && field.show_in_list == 1"
                :key="`extra-field-${field.name}`">
                <dynamic-field :field="field" v-model="search[field.name]" @input="searchEventHandler" hide-details dense
                  clearable :required="false" class="my-4 my-sm-0" />
              </v-col>
            </template>
            <!-- <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.email"
                @input="searchEventHandler"
                label="Email"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.phone"
                @input="searchEventHandler"
                label="Telefon"
                :mask="'###############'"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.company"
                @input="searchEventHandler"
                label="Cég"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table :loading="loading" :headers="dataTableHeaders" :items="dataTable.items"
        :server-items-length="dataTable.itemsLength" :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps" calculate-widths>
        <template #[`item.contact`]="{ item }">
          <div>
            {{ item.phone | VMask($config.inputMasks.phoneNumber) }}
          </div>
          <div>
            {{ item.email }}
          </div>
        </template>

        <template v-for="field in extraFields" #[`item.extra_fields.${field.name}`]="{ item, index }">
          <dynamic-field-value v-if="item.extra_fields && item.extra_fields[field.name]"
            :key="`dynamic-field-value-${index}-${field.name}`" :value="item.extra_fields[field.name]" :field="field" />
        </template>

        <template #[`item.validity_end`]="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ item.validity_end | moment('L') }}
              </span>
            </template>
            {{ item.validity_end | moment('LL') }}
          </v-tooltip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn v-if="hasRight('CARD_OWNER_DELETE')" small text fab dark depressed color="error" class="rounded-sm"
            @click="deleteOwner(item)" title="Törlés">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn v-if="hasRight('CARD_OWNER_WRITE')" small text fab dark depressed color="secondary" class="rounded-sm"
            :to="{ name: 'CardOwnerEditor', params: { id: item.element_id } }" title="Szerkesztés">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
import DynamicField from '@/components/DynamicField.vue';
import ExportDialog from '@/components/ExportDialog.vue';

export default {
  components: { ExportDialog },
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'CardOwnerList',
      fetchItems: this.fetchCardOwners,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'Név', value: 'name', width: 240, cellClass: 'font-weight-bold' },
          { text: 'Lejárat', value: 'validity_end', width: 100 },
        ],
      },
      search: {
        name: '',
        email: '',
        phone: '',
        company: '',
      },
      exportDialog: false,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
      };
    },

    async deleteOwner(owner) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<strong>${owner.name}</strong> kártyabirtoks törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        return;
      }

      this.loading = true;
      try {
        const response = await this.$http.post('card-owners/delete', { element_id: owner.element_id });
        if (response.status !== 'OK') {
          throw new 'Sikertelen mentés';
        }
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.fetchItems();
      }
    },

    async fetchCardOwners() {
      this.loading = true;
      try {
        const response = await this.$http.post('card-owners/list', this.postData);
        this.dataTable.items = response.card_owners;
        this.dataTable.itemsLength = response.card_owners_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      const queries = {
        name: this.search.name || '',
      };
      for (const field of this.extraFields) {
        if (field.searchable == 1) {
          queries[field.name] = this.search[field.name] || '';
        }
      }
      return queries;
    },

    extraFields() {
      return this.$store.getters['fields/findByModelName']('CardOwners');
    },

    dataTableHeaders() {
      const headers = this.dataTable.headers;
      for (const field of this.extraFields) {
        if (field.show_in_list == 1) {
          headers.push({ value: `extra_fields.${field.name}`, text: field.label });
        }
      }
      headers.push({ value: 'actions', sortable: false, align: 'end' });
      return headers;
    },
  },

  // asyncComputed: {
  //   cardOwners: {
  //     async get() {
  //       this.loading = true;
  //       try {
  //         const response = await this.$http.post('card-owners/list', this.postData);
  //         this.dataTable.itemsLength = response.card_owners_count;
  //         this.dataTable.options.page = this.$route.query.page * 1 || 1;
  //         return response.card_owners;
  //       } catch (e) {
  //         console.error(e);
  //       } finally {
  //         this.loading = false;
  //       }
  //     },
  //     default: [],
  //     lazy: true
  //   },
  // },
};
</script>
