<template>
  <v-list>
    <!-- <v-text-field
      prepend-inner-icon="mdi-magnify"
      placeholder="Keresés"
      solo
      dense
      class="ma-3"
      hide-details
    ></v-text-field> -->
    <template v-for="(item, index) in items">
      <sidebar-header
        v-if="isHeadingVisible(item)"
        v-show="!isSectionEmpty(index)"
        :key="'sidebar-menu-header-' + index"
        :item="item"
        :class="{
          'grey--text text--lighten-2': $store.state.darkMode,
          'grey--text text--darken-2': !$store.state.darkMode,
        }"
      />
      <v-divider
        v-else-if="isDividerVisible(item)"
        v-show="!isSectionEmpty(index)"
        :key="'sidebar-menu-divider-' + index"
        class="ma-6"
      />
      <sidebar-menu-item
        v-else-if="isItemVisible(item)"
        :key="'sidebar-menu-item-' + index"
        :item="item"
      />
    </template>
  </v-list>
</template>

<script>
import SidebarMenuItem from './SidebarMenuItem.vue';
import SidebarHeader from './SidebarHeader.vue';

export default {
  components: {
    SidebarMenuItem,
    SidebarHeader,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isItemVisible(item) {
      return !item.right || !item.feature || (this.hasRight(item.right) && this.hasFeature(item.feature));
    },

    isHeadingVisible(item) {
      return item.type === 'heading';
    },

    isDividerVisible(item) {
      return item.type === 'divider';
    },

    isSectionEmpty(index) {
      let isEmpty = true;
      for (let i = index + 1; i < this.items.length; i++) {
        if (!['heading', 'divider'].includes(this.items[i].type) && this.hasRight(this.items[i].right)) {
          isEmpty = false;
          return false;
        }
      }
      return true;
      // return isEmpty;
    },
  },

  data() {
    return {};
  },
};
</script>
