<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        color="grey"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="my-6" v-if="$route.name === 'NewConnection'">Új kapcsolat</h1>
    <h1 class="my-6" v-else>Kapcsolat szerkesztése</h1>

    <v-row>
      <v-col :cols="12" :lg="6">
        <v-card>
          <v-card-title>Alapadatok</v-card-title>
          <v-card-text>
            <v-masked-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-select
              label="Gateway"
              :error-messages="errors.gateway_id"
              v-model="form.gateway_id"
              :items="gateways"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-masked-text-field
              label="RS232 Port név"
              :error-messages="errors.rs232_port_dev_name"
              v-model="form.rs232_port_dev_name"
              filled
            />
            <v-masked-text-field
              label="RS232 Baud rate"
              :error-messages="errors.rs232_baud_rate"
              v-model="form.rs232_baud_rate"
              filled
              :mask="$config.inputMasks.createNumberMask({ min: 0, max: 921600 })"
            />
            <v-masked-text-field
              label="Ip"
              :error-messages="errors.ip"
              v-model="form.ip"
              filled
              :mask="$config.inputMasks.ipAddress"
            />
            <v-masked-text-field
              label="Port"
              :error-messages="errors.port"
              v-model="form.port"
              filled
              :mask="$config.inputMasks.createNumberMask({ min: 1, max: 65535 })"
              placeholder="1-65535"
            />
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              rows="3"
              auto-grow
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      buildings: [],
      gateways: [],
      errors: {},
      loading: false
    };
  },

  mounted() {
    this.fetchGateways();
    this.fetchConnection();
  },

  methods: {
    async fetchConnection() {
      this.loading = true;
      try {
        const response = await this.$http.get(`connections/details/${this.$route.params.id || 0}`);
        this.form = response.connection;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchGateways() {
      this.loading = true;
      try {
        const response = await this.$http.get(`gateways/list`);
        this.gateways = response.gateways;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      this.loading = true;
      this.errors = {};

      try {
        const response = await this.$http.post('connections/save', this.form);

        this.loading = false;

        if (Object.keys(response.errors).length) {
          return this.errors = response.errors;
        }
        this.$dialog.notify.success('Sikeres mentés');
        this.$router.replace({ name: 'ConnectionList' });
      } catch (e) {
        this.$dialog.notify.error(e);
      }
    },
  },
};
</script>
