var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Vonalkódok")]), _c('div', {
    staticClass: "floating-buttons"
  }, [_c('button', {
    staticClass: "fl-button",
    attrs: {
      "disabled": _vm.is_save,
      "title": "Új vonalkód"
    },
    on: {
      "click": _vm.addBarcode
    }
  }, [_c('i', {
    staticClass: "customicon customicon-plus"
  })]), _vm.barcodes.length > 0 ? _c('button', {
    staticClass: "fl-button",
    attrs: {
      "disabled": _vm.is_save,
      "type": "button"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('i', {
    staticClass: "customicon customicon-save"
  })]) : _vm._e()]), _vm.barcodes.length > 0 ? _c('table', {
    staticClass: "list"
  }, [_vm._m(0), _vm._l(_vm.barcodes, function (barcode) {
    return _c('tr', [_c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('span', {
      staticStyle: {
        "font-size": "10px"
      }
    }, [_vm._v(_vm._s(barcode.rf_id))]), _c('br'), _vm._v(_vm._s(barcode.barcode_number) + " "), barcode.email_sent == 1 ? _c('div', [_c('i', {
      staticClass: "customicon customicon-envelope"
    })]) : _vm._e()]), _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('inputbox', {
      key: 'barcode_name_' + barcode.id,
      staticStyle: {
        "width": "200px",
        "padding-bottom": "0px"
      },
      attrs: {
        "placeholder": "Kedvezményezett neve",
        "errors": _vm.errors,
        "label": "",
        "id": 'barcode_name_' + barcode.id
      },
      on: {
        "input": function ($event) {
          return _vm.emailBtnHide(barcode);
        }
      },
      model: {
        value: barcode.name,
        callback: function ($$v) {
          _vm.$set(barcode, "name", $$v);
        },
        expression: "barcode.name"
      }
    }), _c('inputbox', {
      key: 'barcode_email_' + barcode.id,
      staticStyle: {
        "width": "200px"
      },
      attrs: {
        "placeholder": "Kedvezményezett email címe",
        "errors": _vm.errors,
        "label": "",
        "id": 'barcode_email_' + barcode.id
      },
      on: {
        "input": function ($event) {
          return _vm.emailBtnHide(barcode);
        }
      },
      model: {
        value: barcode.email,
        callback: function ($$v) {
          _vm.$set(barcode, "email", $$v);
        },
        expression: "barcode.email"
      }
    })], 1), _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('inputbox', {
      key: 'barcode_license_plate_' + barcode.id,
      staticStyle: {
        "width": "100px"
      },
      attrs: {
        "inputMaskOptions": {
          regex: '[0-9a-zA-Z]+',
          jitMasking: true
        },
        "errors": _vm.errors,
        "label": "",
        "id": 'barcode_license_plate_' + barcode.id
      },
      on: {
        "input": function ($event) {
          return _vm.emailBtnHide(barcode);
        }
      },
      model: {
        value: barcode.license_plate,
        callback: function ($$v) {
          _vm.$set(barcode, "license_plate", $$v);
        },
        expression: "barcode.license_plate"
      }
    })], 1), _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_vm._l(_vm.access_levels, function (al) {
      return _c('checkbox', {
        staticStyle: {
          "padding-bottom": "5px"
        },
        attrs: {
          "label": al.name,
          "name": 'barcode_access_levels_' + barcode.id + '[]',
          "id": 'barcode_access_levels_' + barcode.id + '_' + al.element_id,
          "val": al.element_id
        },
        on: {
          "input": function ($event) {
            return _vm.emailBtnHide(barcode);
          }
        },
        model: {
          value: barcode.access_levels,
          callback: function ($$v) {
            _vm.$set(barcode, "access_levels", $$v);
          },
          expression: "barcode.access_levels"
        }
      });
    }), _vm._l(_vm.errors, function (error) {
      return _c('div', {
        key: error.id,
        staticClass: "form-error"
      }, [error.id == 'barcode_access_levels_' + barcode.id ? [_vm._v(" Kötelező ")] : _vm._e()], 2);
    })], 2), _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('inputbox', {
      key: 'barcode_start_date_time_' + barcode.id,
      staticStyle: {
        "width": "150px"
      },
      attrs: {
        "datepicker": {
          enableTime: true
        },
        "errors": _vm.errors,
        "label": "",
        "id": 'barcode_start_date_time_' + barcode.id
      },
      on: {
        "input": function ($event) {
          return _vm.emailBtnHide(barcode);
        }
      },
      model: {
        value: barcode.start_date_time,
        callback: function ($$v) {
          _vm.$set(barcode, "start_date_time", $$v);
        },
        expression: "barcode.start_date_time"
      }
    })], 1), _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('inputbox', {
      key: 'barcode_period_' + barcode.id,
      staticStyle: {
        "width": "60px"
      },
      attrs: {
        "inputMaskOptions": {
          regex: '[0-9]+',
          jitMasking: true
        },
        "errors": _vm.errors,
        "label": "",
        "id": 'barcode_period_' + barcode.id
      },
      on: {
        "input": function ($event) {
          return _vm.emailBtnHide(barcode);
        },
        "keyup": function ($event) {
          return _vm.periodKeyup(barcode);
        }
      },
      model: {
        value: barcode.period,
        callback: function ($$v) {
          _vm.$set(barcode, "period", $$v);
        },
        expression: "barcode.period"
      }
    })], 1), _c('td', [barcode.email_can_be_sent ? _c('button', {
      staticClass: "button small",
      attrs: {
        "disabled": _vm.is_save,
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.sendEmail(barcode.id);
        }
      }
    }, [_c('i', {
      staticClass: "customicon customicon-envelope"
    })]) : _vm._e()])]);
  })], 2) : _vm._e(), _vm.barcodes.length == 0 ? _c('div', [_vm._v("Nem található vonalkód.")]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("Rf ID / Szám")]), _c('th', [_vm._v("Név / Email")]), _c('th', [_vm._v("Rendszám")]), _c('th', [_vm._v("Jog")]), _c('th', [_vm._v("Kezdő dátum")]), _c('th', [_vm._v("Időtartam (óra)")]), _c('th')]);
}]

export { render, staticRenderFns }