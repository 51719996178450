<template>
  <div class="text-center" v-if="dialog">
    <v-dialog
      v-model="showDialog"
      width="500"
      click:outside="showDialog"
      :persistent="false"
    >
      <v-card>
        <v-card-actions class="p-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close" small fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <h2 class="p-0">Vonalkód importálás</h2>
        </v-card-text>

        <v-card-text class="pb-0">
          <v-row class="m-1">
            <v-col :cols="12" :md="6" :sm="12">
              <a class="link" href="/assets/doc/vonalkod_sablon.xls">
                <i class="customicon customicon-download"></i>
                Sablon XLS letöltése
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :md="12" :sm="12">
              <v-file-input
                label="Feltöltés"
                @change="fileChanged"
                show-size
                :error-messages="errors.msg"
                ref="myFiles"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mb-0 pb-0"></v-divider>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            align="start"
            @click="upload"
            :disabled="loading"
          >
            Importálás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    requestId: String,
    dialog: {
      type: [Boolean, String],
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      files: [],
      errors: [],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.dialog;
      },
      set() {
        return this.$emit("show", false);
      },
    },
  },
  methods: {
    fileChanged(e) {
      var _this = this;
      this.files = e;
      //this.files = e.target.files || e.dataTransfer.files;
    },
    async upload() {
      const _this = this;
      _this.loading = true;
      const formData = new FormData();
      formData.append("request_id", this.request_id);
      formData.append("file", this.files[0]);
      try {
        const response = await this.$http.post("barcodeImport/", formData);
        this.loading = false;
        if (response.errors == undefined) {
          _this.$emit("success");
          _this.$emit("close");
        } else {
          _this.errors = response.errors;
        }
      } catch (e) {
        console.error(e);
      }
    },
    close() {
      this.$emit("show", false);
    },
  },
};
</script>
