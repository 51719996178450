<template>
  <div class="ma-auto">
    <v-card outlined light color="red lighten-3">
      <v-card-title> Tester panel </v-card-title>
      <v-card-text class="d-flex align-center">
        <v-btn small class="mx-2" @click="simulateRead('ABCDEF123456')">Existing key</v-btn>
        <v-btn small class="mx-2" @click="simulateRead('ABCDEF12345')">Not existing key</v-btn>
        <v-divider></v-divider>
        <v-btn small class="mx-2" @click="simulateRead('6DDC8E1A')">Allowed card</v-btn>
        <v-btn small class="mx-2" @click="simulateRead('E87138C7')">Disallowed card</v-btn>
        <v-btn small class="mx-2" @click="simulateRead('FD1F432')">Not existing card</v-btn>
      </v-card-text>
    </v-card>

    <h1 class="py-6">Kulcskezelés</h1>

    <loading-overlay v-if="loading" />

    <v-card class="mb-8" :color="response?.color" :dark="!!response">
      <v-card-title>
        <v-icon left>mdi-account-key-outline</v-icon>
        Olvasás
      </v-card-title>
      <v-card-text>
        <v-alert
          v-if="!card.data && !key.data"
          height="200"
          type="info"
          color="grey"
          prominent
          class="d-flex align-center justify-center text-h5"
        >
          Érintsen egy kártyát vagy kulcsot az olvasóhoz!
        </v-alert>

        <div class="d-flex flex-wrap justify-center align-center">
          <v-expand-transition>
            <v-card
              :dark="$store.state.darkMode"
              :light="!$store.state.darkMode"
              v-if="card.data"
              height="240px"
              width="440px"
              class="ma-4 d-flex flex-column"
            >
              <v-card-title text-center="left">
                <v-icon left>mdi-card-account-details</v-icon>
                Beolvasott kártya
              </v-card-title>
              <v-card-text class="flex-grow-1 d-flex align-center">
                <v-avatar width="120" height="150" color="grey ligthen-2" rounded>
                  <v-img
                    v-if="card.data.photo"
                    :src="'data:image/png;base64, ' + card.data.photo"
                    @click="lightbox = true"
                  ></v-img>
                  <v-icon v-else size="100">mdi-account</v-icon>
                </v-avatar>
                <div class="ml-5 d-flex flex-column justify-center align-start">
                  <div class="text-h5">{{ card.data.owner?.name }}</div>
                  <div class="my-2">Kártyaszám: {{ card.data.card_number }}</div>
                  <div class="">Típus: {{ card.data.card_rule_name_hu }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-expand-transition>

          <v-expand-transition>
            <v-card
              :dark="$store.state.darkMode"
              :light="!$store.state.darkMode"
              v-if="key.data"
              height="240px"
              width="440px"
              class="ma-4 d-flex flex-column"
            >
              <v-card-title text-center="left">
                <v-icon left>mdi-card-account-details</v-icon>
                Beolvasott kulcs
              </v-card-title>
              <v-card-text class="flex-grow-1 d-flex align-center">
                <v-avatar width="120" height="150" :color="key.data.color" rounded>
                  <v-icon size="100">mdi-key</v-icon>
                </v-avatar>
                <div class="ml-5 d-flex flex-column justify-center align-start">
                  <div class="text-h5">{{ key.data.name }}</div>
                  <div class="my-2">{{ key.data.remark }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-expand-transition>

          <v-expand-transition>
            <v-col v-if="response" cols="12" class="text-center d-flex flex-column align-center">
              <v-alert
                :type="response.color"
                prominent
                class="text-h5 text-uppercase"
                v-html="response.message"
              />
            </v-col>
          </v-expand-transition>
          <v-expand-transition>
            <v-col
              v-if="card.data || key.data"
              cols="12"
              class="text-center d-flex flex-column align-center"
            >
              <v-btn color="primary" x-large @click="reset">
                <v-icon left>mdi-refresh</v-icon>
                Új olvasás
              </v-btn>
            </v-col>
          </v-expand-transition>
          <!-- <v-card outlined>
            <v-card-text class="pa-10 text-center">
              <v-icon size="80">mdi-key</v-icon>
              <v-icon size="80">mdi-slash-forward</v-icon>
              <v-icon size="80">mdi-card-account-details</v-icon>
              <div class="mt-8">
                Érintsen egy kártyát vagy kulcsot az olvasóhoz!
              </div>
            </v-card-text>
          </v-card> -->
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="0" class="mb-8">
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              label="Kulcsszekrény"
              filled
              :loading="keyCabinets.length === 0"
              :items="keyCabinets"
              v-model="keyCabinetId"
              item-text="name"
              item-value="id"
              :value-comparator="$config.comparators.isEqual"
              :hint="
                keyCabinets.length === 0
                  ? 'Nincs kulcsszekrény kiválasztva. A továbblépéshez kérjük válasszon egyet!'
                  : ''
              "
              :persistent-hint="true"
            />
          </v-col>

          <template v-if="keyCabinetId">
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <v-icon left>mdi-</v-icon>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <v-icon left>mdi-</v-icon>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-card>
            </v-col>
          </template> </v-row
      ></v-card-text>
    </v-card>

    <!-- <v-sheet v-if="!view" class="d-flex flex-column" width="320px">
      <v-btn color="primary" x-large class="my-2" @click="view = KeyView.Request">
        <v-icon left>mdi-account-key</v-icon>
        Jogosultság ellenőrzése
      </v-btn>
      <v-btn color="primary" x-large class="my-2">
        <v-icon left>mdi-key</v-icon>
        Kulcs visszavétele
      </v-btn>
    </v-sheet> -->

    <!-- <v-sheet v-if="view === KeyView.Request" width="80%" class="d-flex justify-center flex-wrap">
      <v-card outlined height="220px" width="320px" class="ma-4">
        <v-card-title>
          <v-icon left>mdi-card-account-details</v-icon>
          Kártya
        </v-card-title>
        <v-card-text v-if="!card.data"> Érintse a kártyát az olvasóhoz! </v-card-text>

        <v-card outlined v-else>
          <v-card-text class="d-flex align-center">
            <v-avatar size="120" color="grey ligthen-2" rounded>
              <v-icon size="80">mdi-account</v-icon>
            </v-avatar>
            <div class="ml-3 d-flex flex-column justify-center align-start">
              <div class="text-h6">{{ card.data?.owner?.name }}</div>
              <div class="my-2">Kártyaszám: {{ card.data?.card_number }}</div>
              <div>{{ card.data?.card_rule_name_hu }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-card>
      <v-card outlined height="220px" width="320px" class="ma-4">
        <v-card-title>
          <v-icon left>mdi-key</v-icon>
          Kulcs
        </v-card-title>
        <v-card-text> </v-card-text>
      </v-card>
    </v-sheet> -->
    <v-overlay absolute :value="lightbox" dark @click="lightbox = false">
      <v-img
        @click="lightbox = false"
        width="480"
        height="720"
        :src="'data:image/png;base64, ' + card.data?.photo"
      ></v-img>
    </v-overlay>
  </div>
</template>

<script>
const KeyView = {
  Request: 'Request',
  Return: 'Return',
};

const RESPONSES = {
  permitted: {
    color: 'success',
    // message: 'A kártya tulajdonosa jogosult a kulcs használatára.',
    message: 'Jogosult',
  },
  rejected: {
    color: 'error',
    message: 'Nem jogosult',
  },
  returned: {
    color: 'info',
    message: 'A kulcs visszavétele sikeres',
  },
  taken: {
    color: 'warning lighten-1',
    message:
      'Ez a kulcs a rendszer szerint használatban van.<br>Kérjük először vegye vissza a kulcsot, majd próbálja újra!',
  },
};

export default {
  data() {
    return {
      readerDisabled: false,
      KeyView,
      response: null,
      lightbox: false,
      keyCabinetId: 0,
      keys: false,
      view: null,
      loading: false,
      card: {
        loading: false,
        data: null,
      },
      key: {
        loading: false,
        data: null,
      },
    };
  },

  mounted() {
    this.keyCabinetId = this.keyCabinetId || this.$store.getters.config.default_key_cabinet_id;
  },

  methods: {
    reset() {
      this.view = null;
      this.readerDisabled = false;
      this.response = null;
      this.card.data = null;
      this.key.data = null;
    },

    async fetchKeys() {
      const response = await this.$http.post('keys/list');
      this.keys = response.keys;
    },

    async checkAccess() {
      const res = await this.$http.post('keys/check-access', {
        card_element_id: this.card.data.element_id,
        key_id: this.key.data.id,
      });
      this.response = res.permitted ? RESPONSES.permitted : RESPONSES.rejected;
      this.readerDisabled = true;
    },

    // test methods
    async simulateRead(rfid) {
      if (this.readerDisabled) {
        return;
      }
      try {
        this.loading = true;
        const response = await this.$http.get(`keys/lookup/${rfid}`);
        if (!response.key && !response.card) {
          throw Error('Ez a kártya vagy kulcs nincs a rendszerben!<br>Kérjük próbálja újra!');
        }

        this.card.data = response.card ?? this.card.data;
        this.key.data = response.key ?? this.key.data;

        if (this.key.data?.taken_by) {
          this.response = this.card.data ? RESPONSES.taken : RESPONSES.returned;
          this.readerDisabled = true;
          return;
        }

        if (this.card.data && this.key.data) {
          this.checkAccess();
        }
      } catch (err) {
        this.$dialog.notify.error(err.message, {
          position: 'bottom-right',
        });
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    keyCabinets: {
      async get() {
        const response = await this.$http.post('key-cabinets/list', { nolimit: true });
        return response.key_cabinets;
      },
      default: [],
    },
  },
};
</script>
