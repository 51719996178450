import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VSheet, {
    staticClass: "mt-6 px-6 pa-1 relative font-weight-regular text-uppercase text-caption grey--text",
    attrs: {
      "min-height": "36px",
      "rounded": "",
      "color": "transparent"
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.item.label) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }