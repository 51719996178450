import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm$response, _vm$card$data$owner, _vm$card$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-auto"
  }, [_c(VCard, {
    attrs: {
      "outlined": "",
      "light": "",
      "color": "red lighten-3"
    }
  }, [_c(VCardTitle, [_vm._v(" Tester panel ")]), _c(VCardText, {
    staticClass: "d-flex align-center"
  }, [_c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF123456');
      }
    }
  }, [_vm._v("Existing key")]), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.simulateRead('ABCDEF12345');
      }
    }
  }, [_vm._v("Not existing key")]), _c(VDivider), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.simulateRead('6DDC8E1A');
      }
    }
  }, [_vm._v("Allowed card")]), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.simulateRead('E87138C7');
      }
    }
  }, [_vm._v("Disallowed card")]), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.simulateRead('FD1F432');
      }
    }
  }, [_vm._v("Not existing card")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Kulcskezelés")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VCard, {
    staticClass: "mb-8",
    attrs: {
      "color": (_vm$response = _vm.response) === null || _vm$response === void 0 ? void 0 : _vm$response.color,
      "dark": !!_vm.response
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account-key-outline")]), _vm._v(" Olvasás ")], 1), _c(VCardText, [!_vm.card.data && !_vm.key.data ? _c(VAlert, {
    staticClass: "d-flex align-center justify-center text-h5",
    attrs: {
      "height": "200",
      "type": "info",
      "color": "grey",
      "prominent": ""
    }
  }, [_vm._v(" Érintsen egy kártyát vagy kulcsot az olvasóhoz! ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap justify-center align-center"
  }, [_c(VExpandTransition, [_vm.card.data ? _c(VCard, {
    staticClass: "ma-4 d-flex flex-column",
    attrs: {
      "dark": _vm.$store.state.darkMode,
      "light": !_vm.$store.state.darkMode,
      "height": "240px",
      "width": "440px"
    }
  }, [_c(VCardTitle, {
    attrs: {
      "text-center": "left"
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-account-details")]), _vm._v(" Beolvasott kártya ")], 1), _c(VCardText, {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c(VAvatar, {
    attrs: {
      "width": "120",
      "height": "150",
      "color": "grey ligthen-2",
      "rounded": ""
    }
  }, [_vm.card.data.photo ? _c(VImg, {
    attrs: {
      "src": 'data:image/png;base64, ' + _vm.card.data.photo
    },
    on: {
      "click": function ($event) {
        _vm.lightbox = true;
      }
    }
  }) : _c(VIcon, {
    attrs: {
      "size": "100"
    }
  }, [_vm._v("mdi-account")])], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s((_vm$card$data$owner = _vm.card.data.owner) === null || _vm$card$data$owner === void 0 ? void 0 : _vm$card$data$owner.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kártyaszám: " + _vm._s(_vm.card.data.card_number))]), _c('div', {}, [_vm._v("Típus: " + _vm._s(_vm.card.data.card_rule_name_hu))])])], 1)], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.key.data ? _c(VCard, {
    staticClass: "ma-4 d-flex flex-column",
    attrs: {
      "dark": _vm.$store.state.darkMode,
      "light": !_vm.$store.state.darkMode,
      "height": "240px",
      "width": "440px"
    }
  }, [_c(VCardTitle, {
    attrs: {
      "text-center": "left"
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-card-account-details")]), _vm._v(" Beolvasott kulcs ")], 1), _c(VCardText, {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c(VAvatar, {
    attrs: {
      "width": "120",
      "height": "150",
      "color": _vm.key.data.color,
      "rounded": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "100"
    }
  }, [_vm._v("mdi-key")])], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.key.data.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(_vm._s(_vm.key.data.remark))])])], 1)], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.response ? _c(VCol, {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c(VAlert, {
    staticClass: "text-h5 text-uppercase",
    attrs: {
      "type": _vm.response.color,
      "prominent": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.response.message)
    }
  })], 1) : _vm._e()], 1), _c(VExpandTransition, [_vm.card.data || _vm.key.data ? _c(VCol, {
    staticClass: "text-center d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.reset
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-refresh")]), _vm._v(" Új olvasás ")], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), 0 ? _c(VCard, {
    staticClass: "mb-8"
  }, [_c(VCardTitle), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Kulcsszekrény",
      "filled": "",
      "loading": _vm.keyCabinets.length === 0,
      "items": _vm.keyCabinets,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "hint": _vm.keyCabinets.length === 0 ? 'Nincs kulcsszekrény kiválasztva. A továbblépéshez kérjük válasszon egyet!' : '',
      "persistent-hint": true
    },
    model: {
      value: _vm.keyCabinetId,
      callback: function ($$v) {
        _vm.keyCabinetId = $$v;
      },
      expression: "keyCabinetId"
    }
  })], 1), _vm.keyCabinetId ? [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-")])], 1), _c(VCardText)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-")])], 1), _c(VCardText)], 1)], 1)] : _vm._e()], 2)], 1)], 1) : _vm._e(), _c(VOverlay, {
    attrs: {
      "absolute": "",
      "value": _vm.lightbox,
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.lightbox = false;
      }
    }
  }, [_c(VImg, {
    attrs: {
      "width": "480",
      "height": "720",
      "src": 'data:image/png;base64, ' + ((_vm$card$data = _vm.card.data) === null || _vm$card$data === void 0 ? void 0 : _vm$card$data.photo)
    },
    on: {
      "click": function ($event) {
        _vm.lightbox = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }