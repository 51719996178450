import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": _vm.open,
      "persistent": "",
      "max-width": "600"
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-exit-run")]), _vm._v(" Kiléptetés "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c(VIcon, [_vm._v(" mdi-close ")])], 1)], 1), _c(VDivider), _c(VCardText, {
    staticClass: "mt-4"
  }, [_c(VRow, [_c(VCol, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Név")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.name))])]), _c(VCol, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Zóna")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.zone_name || '-'))])])], 1)], 1), _c(VDivider), _c(VCardText, {
    staticClass: "mt-4"
  }, [_c(VRow, [_c(VCol, [_c(VAutocomplete, {
    attrs: {
      "label": "Kilépés helye",
      "items": _vm.gates,
      "filled": "",
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": !_vm.errors.exit_gate_id,
      "error-messages": _vm.errors.exit_gate_id,
      "dense": ""
    },
    model: {
      value: _vm.form.exit_gate_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "exit_gate_id", $$v);
      },
      expression: "form.exit_gate_id"
    }
  })], 1), _c(VCol, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "depressed": "",
      "block": "",
      "x-large": "",
      "loading": _vm.loading,
      "disabled": !_vm.form.exit_gate_id
    },
    on: {
      "click": _vm.exit
    }
  }, [_vm._v("Kiléptetés")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }