import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c(VDialog, {
    attrs: {
      "value": true,
      "width": "500"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_vm.requestId ? [_vm._v("Vonalkódigénylés módosítása")] : [_vm._v("Vonalkód igénylés")], _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "small": "",
      "fab": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 2), _c(VCardText, {
    staticClass: "pb-0"
  }, [_c('v-masked-text-field', {
    key: ("quantity-" + (_vm.form.id)),
    attrs: {
      "label": "Darabszám",
      "error-messages": _vm.errors.amount,
      "mask": _vm.$config.inputMasks.createNumberMask({
        max: 100
      }),
      "filled": ""
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Csoport",
      "error-messages": _vm.errors.group_id,
      "items": _vm.groups,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "clearable": ""
    },
    model: {
      value: _vm.form.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "group_id", $$v);
      },
      expression: "form.group_id"
    }
  })], 1), _c(VDivider, {
    staticClass: "mb-0 pb-0"
  }), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Mentés ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }