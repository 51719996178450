<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="grey"
        width="60"
        height="60"
        class="ml-1"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>
    <h1 class="py-6">Forgóvilla</h1>
    <v-card :loading="loading">
      <v-row class="m-1">
        <v-col :cols="12" :md="6" :sm="12">
          <v-text-field
            label="Megnevezés"
            :error-messages="errors.name"
            v-model="form.name"
            filled
          />
          <v-select
            label="Kontroller"
            :error-messages="errors.controller_id"
            v-model="form.controller_id"
            :items="controllers"
            item-text="label"
            item-value="value"
            @change="controllerChange"
            filled
          />
          <v-select
            label="Olvasó"
            :error-messages="errors.reader_id"
            v-model="form.reader_id"
            :items="readers"
            item-text="label"
            item-value="value"
            filled
          />
          <v-select
            label="Relé"
            :error-messages="errors.relay_id"
            v-model="form.relay_id"
            :items="relays"
            item-text="label"
            item-value="value"
            filled
          />
        </v-col>
      </v-row>
      <v-row class="m-1">
        <v-col :cols="12" :md="3" :sm="6">
          <v-select
            label="Épület"
            :error-messages="errors.building_id"
            v-model="form.building_id"
            :items="buildings"
            item-text="label"
            item-value="value"
            @change="buildingChange"
            filled
          />
        </v-col>
        <v-col :cols="12" :md="3" :sm="6">
          <v-select
            label="Épület szint"
            :error-messages="errors.building_level_id"
            v-model="form.building_level_id"
            :items="buildingLevels"
            item-text="label"
            item-value="value"
            filled
          />
        </v-col>
      </v-row>
      <v-row class="m-1">
        <v-col :cols="12" :md="3" :sm="6">
          <v-text-field
            label="X koordináta"
            :error-messages="errors.x_coord"
            v-model="form.x_coord"
            filled
            type="number"
          />
        </v-col>
        <v-col :cols="12" :md="3" :sm="6">
          <v-text-field
            label="Y koordináta"
            :error-messages="errors.y_coord"
            v-model="form.y_coord"
            filled
            type="number"
          />
        </v-col>
      </v-row>
      <v-row class="m-1">
        <v-col :cols="12" :md="6" :sm="12">
          <v-switch
            label="Kiegészítő eszköz"
            :error-messages="errors.additional"
            v-model="form.additional.selected"
            flat
          />
          <v-text-field
            label="Megnevezés kiegészítés"
            :error-messages="errors.name_addition"
            v-model="form.name_addition"
            filled
          />
          <v-textarea
            label="Megjegyzés"
            :error-messages="errors.comment"
            v-model="form.comment"
            filled
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      form: {
        element_id: 0,
        name: "",
        controller_id: 0,
        reader_id: 0,
        relay_id: 0,
        building_id: 0,
        building_level_id: 0,
        x_coord: "",
        y_coord: "",
        comment: "",
        name_addition: "",
        additional: {
          value: 1,
          selected: false,
        },
      },
      controllers: [],
      readers: [],
      relays: [],
      buildings: [],
      buildingLevels: [],
      errors: [],
      loading: false,
    };
  },

  async mounted() {
    var _this = this;
    this.loading = true;
    try {
      var route = "";
      if (this.$route.params.id != undefined) {
        route = this.$route.params.id;
      } else {
        route = 0;
      }
      const response = await this.$http.get("turnstiles/details/" + route);
      if (response.msg) {
        throw response.msg;
      }
      this.form = response.turnstile;
      this.form.building_id = this.form.building_id.toString();
      this.form.building_level_id = this.form.building_level_id.toString();
      this.getBuildings();
      this.getBuildingLevels();
      this.getControllers();
      this.getReaders();
      this.getRelays();
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async getControllers() {
      try {
        const response = await this.$http.post("controllers/list");
        this.controllers = response.controllers;
      } catch (e) {
        console.error(e);
      }
    },

    async getReaders() {
      try {
        const response = await this.$http.post("readers/list", {
          controller_id: this.form.controller_id
        });
        this.readers = response.readers;
      } catch (e) {
        console.error(e);
      }
    },

    async getRelays() {
      try {
        const response = await this.$http.post("relays/list", {
          controller_id: this.form.controller_id
        });
        this.relays = response.relays;
      } catch (e) {
        console.error(e);
      }
    },

    controllerChange() {
      this.getReaders();
      this.getRelays();
    },

    async getBuildings() {
      try {
        const response = await this.$http.post("buildings/list");
      } catch (e) {
        console.error(e);
      }
    },

    async getBuildingLevels(reset = false) {
      if (!this.form.building_id) {
        this.form.building_level_id = 0;
        return;
      }
      try {
        const response = await this.$http.post("building-levels/list", {
          building_id: this.form.building_id
        });
        this.buildingLevels = response.building_levels;

        if (reset) {
          this.form.building_level_id = 0;
        }
      } catch (e) {
        console.error(e);
      }
    },

    buildingChange() {
      this.getBuildingLevels(true);
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post("turnstiles/save/", this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$router.push({ name: "TurnstileList" });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
