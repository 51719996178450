<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Sorompó</h1>

    <v-card :loading="loading">
      <v-card-title>
        <v-icon left>mdi-boom-gate</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-autocomplete
              label="Kontroller"
              :error-messages="errors.controller_id"
              v-model="form.controller_id"
              :items="controllers"
              item-text="name"
              item-value="element_id"
              @change="controllerChangedEventHandler"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Olvasó"
              :error-messages="errors.reader_id"
              v-model="form.reader_id"
              :items="readers"
              item-text="name"
              item-value="element_id"
              filled
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
              :persistent-hint="!form.controller_id"
              :disabled="!form.controller_id"
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Relé"
              :error-messages="errors.relay_id"
              v-model="form.relay_id"
              :items="relays"
              item-text="name"
              item-value="element_id"
              filled
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
              :persistent-hint="!form.controller_id"
              :disabled="!form.controller_id"
              :value-comparator="$config.comparators.isEqual"
            />
            <v-text-field
              label="Videó stream URL"
              :error-messages="errors.camera_video_stream"
              v-model="form.camera_video_stream"
              filled
            />
            <v-text-field
              label="NCIOM Control Seq ID"
              :error-messages="errors.nciom_control_seq_id"
              v-model="form.nciom_control_seq_id"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              auto-grow
              :rows="3"
            />

            <v-checkbox label="Kiegészítő eszköz" v-model="form.additional"/>

            <v-text-field
              label="Megnevezés kiegészítés"
              :error-messages="errors.name_addition"
              v-model="form.name_addition"
              filled
            />

            <v-row class="m-1">
              <v-col :cols="12" :md="6">
                <v-text-field
                  label="X koordináta"
                  :error-messages="errors.x_coord"
                  v-model="form.x_coord"
                  type="number"
                  filled
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  label="Y koordináta"
                  :error-messages="errors.y_coord"
                  v-model="form.y_coord"
                  type="number"
                  filled
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      controllers: [],
      readers: [],
      relays: [],
      errors: {},
      loading: false,
    };
  },

  async mounted() {
    this.fetchBarrier();
  },

  methods: {
    async fetchBarrier() {
      try {
        const response = await this.$http.get(`barriers/details/${this.$route.params.id || 0}`);
        this.form = response.barrier;
        this.fetchControllers();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchControllers() {
      try {
        const response = await this.$http.post('controllers/list');
        this.controllers = response.controllers;
        if (this.form.controller_id) {
          await Promise.all([
            this.fetchReaders(),
            this.fetchRelays(),
          ]);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchReaders() {
      try {
        const response = await this.$http.post('readers/list', {
          controller_id: this.form.controller_id,
        });
        this.readers = response.readers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchRelays() {
      try {
        const response = await this.$http.post('relays/list', {
          controller_id: this.form.controller_id,
        });
        this.relays = response.relays;
      } catch (e) {
        console.error(e);
      }
    },

    async controllerChangedEventHandler() {
      this.fetchReaders();
      this.fetchRelays();
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('barriers/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'BarrierEditor') {
            this.$router.push({
              name: 'BarrierEditor',
              params: { id: response.barrier.element_id },
            });
          }
          this.fetchBarrier();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
