<template>
  <div class="text-center">
    <v-dialog
      :value="true"
      width="500"
      @click:outside="$emit('close')"
    >
      <v-card :loading="loading">
        <v-card-title>
          <template v-if="requestId">Vonalkódigénylés módosítása</template>
          <template v-else>Vonalkód igénylés</template>

          <v-spacer/>

          <v-btn color="primary" text @click="$emit('close')" small fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-masked-text-field
            label="Darabszám"
            :key="`quantity-${form.id}`"
            :error-messages="errors.amount"
            v-model="form.amount"
            :mask="$config.inputMasks.createNumberMask({ max: 100 })"
            filled
          />
          <v-textarea
            label="Megjegyzés"
            v-model="form.comment"
            :error-messages="errors.comment"
            filled
          />
          <v-select
            label="Csoport"
            :error-messages="errors.group_id"
            v-model="form.group_id"
            :items="groups"
            item-text="name"
            item-value="element_id"
            filled
            :value-comparator="$config.comparators.isEqual"
            clearable
          />
        </v-card-text>

        <v-divider class="mb-0 pb-0"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            align="start"
            :disabled="loading"
            @click="save"
          >
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    requestId: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      form: {},
      groups: [],
      errors: {},
    };
  },

  async mounted() {
    this.loading = true;
    try {
      const response = await this.$http.get(`qr-code-requests/details/${this.requestId || 0}`);
      this.form = response.qr_code_request;
      this.groups = response.groups;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async save() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post(
          'qr-code-requests/save',
          this.form
        );
        if (!Object.keys(response.errors).length) {
          this.$emit('save', response.qr_code_request);
          this.$emit('close');
          this.$dialog.notify.success('Sikeres mentés!');
        } else {
          this.errors = response.errors;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
