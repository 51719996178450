<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        color="grey"
        dark
        @click="$router.go(-1)"
        title="Vissza"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
        title="Mentés"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Funkciók</h1>

    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Név
              </th>
              <th class="text-left">
                Alias
              </th>
              <th class="text-left">
                Engedélyezve
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="feature in features"
              :key="feature.alias"
            >
              <td>{{ feature.name }}</td>
              <td>{{ feature.alias }}</td>
              <td>
                <v-checkbox v-model="feature.enabled"/>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      features: [],
      loading: false
    };
  },

  mounted() {
    this.fetchFeatures();
  },

  methods: {
    async fetchFeatures() {
      this.loading = true;
      const response = await this.$http.get('superadmin/features/list');
      this.loading = false;
      this.features = response.features;
    },

    async save() {
      this.loading = true;
      const response = await this.$http.post('superadmin/features/save', { features: this.features });
      this.loading = false;
    }
  },
};
</script>
