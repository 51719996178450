import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c(VCard, {
    staticClass: "relative mx-auto"
  }, [_c(VCardTitle, [_vm._v("Telepítő")]), _c(VDivider), _c(VCardText, {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ma-3"
  }, [_c(VTextField, {
    attrs: {
      "label": "Adatbázis url",
      "value": "localhost:3306",
      "filled": ""
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Adatbázis név",
      "value": "belepteto",
      "filled": ""
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Adatbázis felhasználó",
      "type": "admin",
      "value": "root",
      "filled": ""
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Adatbázis jelszó",
      "type": "password",
      "filled": ""
    }
  })], 1), _c('div', {
    staticClass: "ma-3"
  }, [_c(VCheckbox, {
    attrs: {
      "label": "HikVision modul"
    }
  })], 1)]), _c(VDivider), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "success",
      "large": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" Telepítés ")], 1), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "warning",
      "large": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" Javítás ")], 1), _c(VSpacer)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }