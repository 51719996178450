import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": _vm.$route.name === 'NewCardOwner' ? 'Új kártyabirtokos létrehozása' : 'Kártyabirtokos szerkesztése'
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_vm.hasRight('CARD_OWNER_WRITE') && _vm.hasFeature('CARD_OWNER_CREATION') ? _c('title-row-button', {
          on: {
            "click": _vm.save
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-clipboard-account")]), _vm._v("Alapadatok")], 1), _c(VCardText, [_c('v-masked-text-field', {
    attrs: {
      "label": "Név",
      "error-messages": _vm.errors.name,
      "mask": _vm.$config.inputMasks.name,
      "filled": "",
      "clearable": "",
      "hint": "Kötelező",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-datetime-picker', {
    attrs: {
      "label": "Orvosi alkalmassági lejárata",
      "time-picker": false,
      "filled": "",
      "hint": "Kötelező (Elveszíti a jogosultságait az ezt követő napon)",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.validity_end,
      callback: function ($$v) {
        _vm.$set(_vm.form, "validity_end", $$v);
      },
      expression: "form.validity_end"
    }
  }), _vm._l(_vm.extraFields, function (field) {
    return _c('dynamic-field', {
      key: ("extra-field-" + (field.name)),
      attrs: {
        "field": field,
        "error-messages": _vm.errors[field.name],
        "filled": ""
      },
      model: {
        value: _vm.form.extra_fields[field.name],
        callback: function ($$v) {
          _vm.$set(_vm.form.extra_fields, field.name, $$v);
        },
        expression: "form.extra_fields[field.name]"
      }
    });
  })], 2)], 1)], 1), _vm.$route.name !== 'NewCardOwner' ? _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-badge-account-horizontal")]), _vm._v("Kártyák")], 1), _vm.form.element_id ? _c('div', [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.cards,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.card_number",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.card_number) + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "depressed": "",
            "to": {
              name: 'CardEditor',
              params: {
                id: item.element_id
              }
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, true)
  })], 1) : _vm._e(), _c(VDivider), _c('div', {
    staticClass: "d-flex justify-center py-4"
  }, [_c(VBtn, {
    staticClass: "rounded-sm mt-2",
    attrs: {
      "color": "secondary",
      "depressed": "",
      "to": {
        name: 'NewCard',
        params: {
          ownerId: _vm.form.element_id
        }
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")]), _vm._v(" "), _c('span', {
    staticClass: "mt-1"
  }, [_vm._v("Hozzáadás")])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }