<template>
  <div>
    <h1 class="py-6">Vonalkódok</h1>
    <div class="floating-buttons">
      <button
        :disabled="is_save"
        class="fl-button"
        @click="addBarcode"
        title="Új vonalkód"
      >
        <i class="customicon customicon-plus"></i>
      </button>
      <button
        :disabled="is_save"
        v-if="barcodes.length > 0"
        class="fl-button"
        type="button"
        @click="save"
      >
        <i class="customicon customicon-save"></i>
      </button>
    </div>

    <table v-if="barcodes.length > 0" class="list">
      <tr>
        <th>Rf ID / Szám</th>
        <th>Név / Email</th>
        <th>Rendszám</th>
        <th>Jog</th>
        <th>Kezdő dátum</th>
        <th>Időtartam (óra)</th>
        <th></th>
      </tr>

      <tr v-for="barcode in barcodes">
        <td valign="top">
          <span style="font-size: 10px">{{ barcode.rf_id }}</span
          ><br />{{ barcode.barcode_number }}
          <div v-if="barcode.email_sent == 1">
            <i class="customicon customicon-envelope"></i>
          </div>
        </td>
        <td valign="top">
          <inputbox
            @input="emailBtnHide(barcode)"
            placeholder="Kedvezményezett neve"
            style="width: 200px; padding-bottom: 0px"
            :errors="errors"
            label=""
            :key="'barcode_name_' + barcode.id"
            :id="'barcode_name_' + barcode.id"
            v-model="barcode.name"
          />
          <inputbox
            @input="emailBtnHide(barcode)"
            placeholder="Kedvezményezett email címe"
            style="width: 200px"
            :errors="errors"
            label=""
            :key="'barcode_email_' + barcode.id"
            :id="'barcode_email_' + barcode.id"
            v-model="barcode.email"
          />
        </td>
        <td valign="top">
          <inputbox
            @input="emailBtnHide(barcode)"
            style="width: 100px"
            :inputMaskOptions="{ regex: '[0-9a-zA-Z]+', jitMasking: true }"
            :errors="errors"
            label=""
            :key="'barcode_license_plate_' + barcode.id"
            :id="'barcode_license_plate_' + barcode.id"
            v-model="barcode.license_plate"
          />
        </td>
        <td valign="top">
          <checkbox
            @input="emailBtnHide(barcode)"
            style="padding-bottom: 5px"
            v-for="al in access_levels"
            :label="al.name"
            :name="'barcode_access_levels_' + barcode.id + '[]'"
            :id="'barcode_access_levels_' + barcode.id + '_' + al.element_id"
            :val="al.element_id"
            v-model="barcode.access_levels"
          />
          <div class="form-error" v-for="error in errors" :key="error.id">
            <template v-if="error.id == 'barcode_access_levels_' + barcode.id">
              Kötelező
            </template>
          </div>
        </td>
        <td valign="top">
          <inputbox
            @input="emailBtnHide(barcode)"
            :datepicker="{ enableTime: true }"
            style="width: 150px"
            :errors="errors"
            label=""
            :key="'barcode_start_date_time_' + barcode.id"
            :id="'barcode_start_date_time_' + barcode.id"
            v-model="barcode.start_date_time"
          />
        </td>
        <td valign="top">
          <inputbox
            @input="emailBtnHide(barcode)"
            @keyup="periodKeyup(barcode)"
            style="width: 60px"
            :inputMaskOptions="{ regex: '[0-9]+', jitMasking: true }"
            :errors="errors"
            label=""
            :key="'barcode_period_' + barcode.id"
            :id="'barcode_period_' + barcode.id"
            v-model="barcode.period"
          />
        </td>
        <td>
          <button
            :disabled="is_save"
            v-if="barcode.email_can_be_sent"
            class="button small"
            type="button"
            @click="sendEmail(barcode.id)"
          >
            <i class="customicon customicon-envelope"></i>
          </button>
        </td>
      </tr>
    </table>
    <div v-if="barcodes.length == 0">Nem található vonalkód.</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      barcodes: [],
      access_levels: [],
      errors: [],
      is_save: false,
    };
  },
  mounted: function () {
    this.getBarcodes();
  },
  methods: {
    emailBtnHide: function (barcode) {
      barcode.email_can_be_sent = false;
    },

    getBarcodes: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("barcodes_new/list/" + _this.$store.state.token, {
          method: "GET",
          success: function (response) {
            _this.barcodes = response.barcodes;
            _this.access_levels = response.access_levels;
          },
        });
      });
    },

    periodKeyup: function (barcode) {
      if (parseInt(this.$root.user.barcode_period_fix) == 1) {
        barcode.period = 24;
      }
    },

    save: function () {
      var _this = this;

      this.is_save = true;
      this.errors = [];

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("barcodes_new/save/" + _this.$store.state.token, {
          method: "POST",
          data: {
            barcodes: _this.barcodes,
          },
          success: function (response) {
            _this.is_save = false;

            if (response.errors != undefined) {
              _this.errors = response.errors;
            } else {
              _this.getBarcodes();
            }
          },
        });
      });
    },

    addBarcode: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("barcodes_new/add/" + _this.$store.state.token, {
          method: "GET",
          success: function (response) {
            if (response.barcode != undefined) {
              response.barcode.access_levels = [];
              _this.barcodes.push(response.barcode);
            }
          },
        });
      });
    },

    sendEmail: function (barcode_id) {
      var _this = this;

      this.is_save = true;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax(
          "barcodes_new/send_email/" + _this.$store.state.token,
          {
            data: {
              id: barcode_id,
            },
            method: "POST",
            success: function (response) {
              swal({
                title: "Siker!",
                icon: "success",
                text: "A vonalkód elküldve a megadott email címre.",
                buttons: false,
                timer: 2000,
              });

              _this.is_save = false;
              _this.getBarcodes();
            },
          }
        );
      });
    },
  },
};
</script>
