import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": "Admin napló"
    }
  }), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Típus",
      "items": _vm.logTypes,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "type_id", $$v);
      },
      expression: "search.type_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Létrehozó",
      "items": _vm.logWriters,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.writer_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "writer_id", $$v);
      },
      expression: "search.writer_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Paraméterek",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.params,
      callback: function ($$v) {
        _vm.$set(_vm.search, "params", $$v);
      },
      expression: "search.params"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": "",
      "show-expand": "",
      "single-expand": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function (_ref) {
        var headers = _ref.headers,
          item = _ref.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "pa-4"
        }, [_c('span', {
          staticClass: "text-subtitle-1 font-weight-bold"
        }, [_vm._v(_vm._s(item.type))]), _c('vue-json-pretty', {
          attrs: {
            "path": "res",
            "data": JSON.parse(item.params),
            "collapsedOnClickBrackets": "",
            "showLine": "",
            "showLength": "",
            "deep": 1
          }
        })], 1)])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.showParamsDialog(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-information")])], 1)];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_vm.paramsDialog.item ? _c(VDialog, {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.paramsDialog.visible,
      callback: function ($$v) {
        _vm.$set(_vm.paramsDialog, "visible", $$v);
      },
      expression: "paramsDialog.visible"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(" Paraméterek "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "small": "",
      "fab": ""
    },
    on: {
      "click": _vm.hideParamsDialog
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c(VCardText, [_c('vue-json-pretty', {
    attrs: {
      "path": "res",
      "data": JSON.parse(_vm.paramsDialog.item.params),
      "collapsedOnClickBrackets": "",
      "showLine": "",
      "showLength": "",
      "deep": 1
    }
  })], 1), _c(VDivider, {
    staticClass: "mb-0 pb-0"
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }