import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "d-flex flex-wrap justify-center align-center justify-items-center",
    attrs: {
      "id": "bg-overlay"
    }
  }, [_c('div', {
    attrs: {
      "id": "login-modal"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [!_vm.$route.meta.logo ? _c(VIcon, {
    attrs: {
      "color": "secondary",
      "size": "96"
    }
  }, [_vm._v("mdi-shield-key-outline")]) : _c(VImg, {
    staticClass: "mx-auto",
    attrs: {
      "width": "120",
      "src": _vm.logo
    }
  }), _vm._m(0), _c('small', {
    attrs: {
      "text-center": ""
    }
  }, [_vm._v(_vm._s(_vm.$route.meta.subtitle))])], 1), _c('div', {
    staticClass: "pa-4 px-sm-12"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Email",
      "type": "email",
      "filled": "",
      "dense": "",
      "error-messages": _vm.errors.email,
      "autofocus": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Jelszó",
      "filled": "",
      "error-messages": _vm.errors.password,
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c(VExpandTransition, [_vm.errors.login && _vm.errors.login.length ? _c(VAlert, {
    attrs: {
      "dense": "",
      "filled": "",
      "dismissible": "",
      "border": "left",
      "type": "error"
    }
  }, [_vm._l(_vm.errors.login, function (error) {
    return [_vm._v(" " + _vm._s(error) + " ")];
  })], 2) : _vm._e(), _vm.messageText ? _c(VAlert, {
    attrs: {
      "dense": "",
      "text": "",
      "type": _vm.messageType
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.messageText)
    }
  })]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column justify-center align-center"
  }, [_c(VBtn, {
    attrs: {
      "type": "submit",
      "color": "accent",
      "x-large": "",
      "depressed": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-login-variant")]), _vm._v(" Bejelentkezés ")], 1)], 1)], 1)]), _c('div', {
    staticClass: "text-center",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('small', {
    staticClass: "text-caption",
    attrs: {
      "title": ("Build #" + (_vm.buildNumber) + "\n" + (_vm.buildDate))
    }
  }, [_vm._v(" " + _vm._s(_vm.version) + " ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "my-2 pa-0 text-uppercase text-center"
  }, [_vm._v("UNIOM"), _c('br'), _c('span', [_vm._v("Beléptetőrendszer")])]);
}]

export { render, staticRenderFns }