import router from '@/router';


const routes = [
  {
    name: 'Login',
    meta: {
      background: 'variants/pamok/assets/login-bg.jpg',
      logo: 'variants/pamok/assets/logo.png',
      subtitle: 'Marcus Security beléptető adminisztrációs felület'
    },
  },
];

router.$addRoutes(routes);

export default router;



// import store from '@/store';

// async function fetchRights() {
//   if (window.vue.$store.state.user) {
//     const response = await window.vue.$http.get(`users/details/${window.vue.$store.state.user.element_id}`);
//     window.vue.$store.commit('setRights', response.rights);
//   }
// }

// async function requireAuth(to, from, next) {
//   try {
//     fetchRights();
//     if (!window.vue.$store.state.token) {
//       throw '';
//     }

//     const response = await window.vue.$http.get('auth/validate/' + window.vue.$store.state.token);
//     if (response.status == 'OK') {
//       next();
//     } else {
//       throw 'Kérjük jelentkezzen be újra!';
//     }
//   } catch (e) {
//     next({
//       name: 'Login',
//       params: {
//         message: e,
//         redirect: to.fullPath,
//       },
//     });
//   }
// }

// import Vue from 'vue';
// import VueRouter from 'vue-router';

// import AccessLevelEditor from '@/pages/access-levels/Editor.vue';
// import AccessLevelList from '@/pages/access-levels/List.vue';
// import AdminLog from '@/pages/logs/AdminLog.vue';
// import BarrierEditor from '@/pages/barriers/Editor.vue';
// import BarrierList from '@/pages/barriers/List.vue';
// import BuildingEditor from '@/pages/buildings/Editor.vue';
// import BuildingList from '@/pages/buildings/List.vue';
// import BuildingLevelEditor from '@/pages/building-levels/Editor.vue';
// import BuildingLevelList from '@/pages/building-levels/List.vue';
// import BuildingLog from '@/pages/daemon_log/building_log.vue';
// import CardEditor from '@/pages/cards/Editor';
// import CardGroupEditor from '@/pages/cardgroup/cardgroup.vue';
// import CardGroupList from '@/pages/cardgroup/cardgroups.vue';
// import CardList from '@/pages/cards/List';
// import CardOwnerEditor from '@/pages/card-owners/Editor';
// import CardOwnerList from '@/pages/card-owners/List';
// import ChangePassword from '@/pages/profil/change_password.vue';
// import ConnectionEditor from '@/pages/connections/Editor.vue';
// import ConnectionList from '@/pages/connections/List.vue';
// import ControllerEditor from '@/pages/controllers/Editor.vue';
// import ControllerList from '@/pages/controllers/List.vue';
// import DaemonLog from '@/pages/daemon_log/daemon_log.vue';
// import DateIntervalEditor from '@/pages/date-intervals/Editor.vue';
// import DateIntervalList from '@/pages/date-intervals/List.vue';
// import DoorEditor from '@/pages/doors/Editor.vue';
// import DoorList from '@/pages/doors/List.vue';
// import Filter from '@/pages/filter/List.vue';
// import GatewayEditor from '@/pages/gateways/Editor.vue';
// import GatewayList from '@/pages/gateways/List.vue';
// import GroupEditor from '@/pages/groups/Editor.vue';
// import GroupList from '@/pages/groups/List.vue';
// import Home from '@/pages/home/Home';
// import Login from '@/variants/pamok/pages/login/login.vue';
// import Logout from '@/pages/logout/logout.vue';
// import Message from '@/pages/message/message.vue';
// import OpeningHours from '@/pages/openings/Editor.vue';
// import OperationList from '@/pages/operations/operations.vue';
// import ParkingLog from '@/pages/daemon_log/parking_log.vue';
// import ParkingTicketsList from '@/pages/parking-tickets/List.vue';
// import PlateEditor from '@/pages/plates/Editor';
// import PlateList from '@/pages/plates/List';
// import QrCodeList from '@/pages/qr-codes/List.vue';
// import QrCodeRequestList from '@/pages/qr-codes/requests/List.vue';
// import QrCodeRequestEditor from '@/pages/qr-codes/requests/Editor.vue';
// import QrCodeApprovalList from '@/pages/qr-codes/approval/List.vue';
// import ReaderEditor from '@/pages/readers/Editor.vue';
// import ReaderList from '@/pages/readers/List.vue';
// import RelayEditor from '@/pages/relays/Editor.vue';
// import RelayList from '@/pages/relays/List.vue';
// import RemoteOpen from '@/pages/remote_open/RemoteOpen.vue';
// import ScheduleEditor from '@/pages/schedule-groups/Editor.vue';
// import ScheduleList from '@/pages/schedule-groups/List.vue';
// import Status from '@/pages/status/status.vue';
// import TurnstileEditor from '@/pages/turnstiles/Editor.vue';
// import TurnstileList from '@/pages/turnstiles/List.vue';
// import UserEditor from '@/pages/users/Editor';
// import UsersList from '@/pages/users/List';

// import DashboardLayout from '@/layouts/DashboardLayout';
// import EmptyLayout from '@/layouts/EmptyLayout';

// Vue.use(VueRouter);

// const router = new VueRouter({
//   base: process.env.BASE_URL || '',
//   mode: process.env.NODE_ENV === 'production' ? 'history' : 'history',
//   // mode: 'hash',
//   routes: [
//     {
//       path: '/login',
//       component: Login,
//       name: 'Login',
//     },
//     {
//       path: '/logout',
//       component: Logout,
//       name: 'Logout',
//     },
//     {
//       path: '/',
//       component: DashboardLayout,
//       beforeEnter: requireAuth,
//       children: [
//         {
//           path: 'msg',
//           component: Message,
//           name: 'Message',
//         },
//         {
//           path: '',
//           component: EmptyLayout,
//           children: [
//             {
//               path: 'home',
//               component: Home,
//               name: 'Home',
//             },
//             {
//               path: '',
//               redirect: { name: 'Home' },
//             },
//           ],
//         },
//         {
//           path: 'access-levels',
//           name: 'AccessLevels',
//           component: EmptyLayout,
//           redirect: 'access-levels/list',
//           children: [
//             {
//               path: 'list',
//               component: AccessLevelList,
//               name: 'AccessLevelList',
//             },
//             {
//               path: 'new',
//               component: AccessLevelEditor,
//               name: 'NewAccessLevel',
//             },
//             {
//               path: 'edit/:id',
//               component: AccessLevelEditor,
//               name: 'AccessLevelEditor',
//             },
//           ],
//         },
//         {
//           path: 'qr-codes',
//           name: 'QrCodes',
//           component: EmptyLayout,
//           redirect: 'qr-codes/list',
//           children: [
//             {
//               path: 'list',
//               component: QrCodeList,
//               name: 'QrCodeList',
//             },
//             {
//               path: 'approval',
//               component: QrCodeApprovalList,
//               name: 'QrCodeApprovalList',
//             },
//             {
//               path: 'requests',
//               component: EmptyLayout,
//               redirect: 'requests/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: QrCodeRequestList,
//                   name: 'QrCodeRequestList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: QrCodeRequestEditor,
//                   name: 'QrCodeRequestEditor',
//                 },
//               ]
//             },
//           ],
//         },
//         {
//           path: 'card-owners',
//           name: 'CardOwners',
//           component: EmptyLayout,
//           redirect: 'card-owners/list',
//           children: [
//             {
//               path: 'list',
//               component: CardOwnerList,
//               name: 'CardOwnerList',
//             },
//             {
//               path: 'edit/:id',
//               component: CardOwnerEditor,
//               name: 'CardOwnerEditor',
//             },
//             {
//               path: 'new',
//               component: CardOwnerEditor,
//               name: 'NewCardOwner',
//             },
//           ],
//         },
//         {
//           path: 'cards',
//           name: 'Cards',
//           component: EmptyLayout,
//           redirect: 'cards/list',
//           children: [
//             {
//               path: 'list',
//               component: CardList,
//               name: 'CardList',
//             },
//             {
//               path: 'new',
//               component: CardEditor,
//               name: 'NewCard',
//             },
//             {
//               path: 'edit/:id',
//               component: CardEditor,
//               name: 'CardEditor',
//             },
//           ],
//         },
//         {
//           path: 'card-groups',
//           name: 'CardGroups',
//           component: EmptyLayout,
//           redirect: 'card-groups/list',
//           children: [
//             {
//               path: 'list',
//               component: CardGroupList,
//               name: 'CardGroupList',
//             },
//             {
//               path: 'edit',
//               component: CardGroupEditor,
//               name: 'CardGroupEditor',
//             },
//           ],
//         },
//         {
//           path: 'change-password',
//           component: EmptyLayout,
//           children: [
//             {
//               path: '',
//               component: ChangePassword,
//               name: 'ChangePassword',
//             },
//           ],
//         },
//         {
//           path: 'date-intervals',
//           name: 'DateIntervals',
//           component: EmptyLayout,
//           redirect: 'date-intervals/list',
//           children: [
//             {
//               path: 'list',
//               component: DateIntervalList,
//               name: 'DateIntervalList',
//             },
//             {
//               path: 'new',
//               component: DateIntervalEditor,
//               name: 'NewDateInterval',
//             },
//             {
//               path: 'edit/:id',
//               component: DateIntervalEditor,
//               name: 'DateIntervalEditor',
//             },
//           ],
//         },
//         {
//           path: 'devices',
//           name: 'Devices',
//           component: EmptyLayout,
//           redirect: 'devices/buildings',
//           children: [
//             {
//               path: 'building-levels',
//               name: 'BuildingLevels',
//               component: EmptyLayout,
//               redirect: 'building-levels/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: BuildingLevelList,
//                   name: 'BuildingLevelList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: BuildingLevelEditor,
//                   name: 'BuildingLevelEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: BuildingLevelEditor,
//                   name: 'NewBuildingLevel',
//                 },
//               ],
//             },
//             {
//               path: 'buildings',
//               name: 'Buildings',
//               component: EmptyLayout,
//               redirect: 'buildings/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: BuildingList,
//                   name: 'BuildingList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: BuildingEditor,
//                   name: 'BuildingEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: BuildingEditor,
//                   name: 'NewBuilding',
//                 },
//               ],
//             },
//             {
//               path: 'gateways',
//               name: 'Gateways',
//               component: EmptyLayout,
//               redirect: 'gateways/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: GatewayList,
//                   name: 'GatewayList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: GatewayEditor,
//                   name: 'GatewayEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: GatewayEditor,
//                   name: 'NewGateway',
//                 },
//               ],
//             },
//             {
//               path: 'connections',
//               name: 'Connections',
//               component: EmptyLayout,
//               redirect: 'connections/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: ConnectionList,
//                   name: 'ConnectionList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: ConnectionEditor,
//                   name: 'ConnectionEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: ConnectionEditor,
//                   name: 'NewConnection',
//                 },
//               ],
//             },
//             {
//               path: 'controllers',
//               name: 'Controllers',
//               component: EmptyLayout,
//               redirect: 'controllers/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: ControllerList,
//                   name: 'ControllerList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: ControllerEditor,
//                   name: 'ControllerEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: ControllerEditor,
//                   name: 'NewController',
//                 },
//               ],
//             },
//             {
//               path: 'readers',
//               name: 'Readers',
//               component: EmptyLayout,
//               redirect: 'devices/readers/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: ReaderList,
//                   name: 'ReaderList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: ReaderEditor,
//                   name: 'ReaderEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: ReaderEditor,
//                   name: 'NewReader',
//                 },
//               ],
//             },
//             {
//               path: 'relays',
//               name: 'Relays',
//               component: EmptyLayout,
//               redirect: 'devices/relays/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: RelayList,
//                   name: 'RelayList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: RelayEditor,
//                   name: 'RelayEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: RelayEditor,
//                   name: 'NewRelay',
//                 },
//               ],
//             },
//             {
//               path: 'doors',
//               name: 'Doors',
//               component: EmptyLayout,
//               redirect: 'doors/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: DoorList,
//                   name: 'DoorList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: DoorEditor,
//                   name: 'DoorEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: DoorEditor,
//                   name: 'NewDoor',
//                 },
//               ],
//             },
//             {
//               path: 'barriers',
//               name: 'Barriers',
//               component: EmptyLayout,
//               redirect: 'barriers/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: BarrierList,
//                   name: 'BarrierList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: BarrierEditor,
//                   name: 'BarrierEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: BarrierEditor,
//                   name: 'NewBarrier',
//                 },
//               ],
//             },
//             {
//               path: 'turnstiles',
//               name: 'Turnstiles',
//               component: EmptyLayout,
//               redirect: 'turnstiles/list',
//               children: [
//                 {
//                   path: 'list',
//                   component: TurnstileList,
//                   name: 'TurnstileList',
//                 },
//                 {
//                   path: 'edit/:id',
//                   component: TurnstileEditor,
//                   name: 'TurnstileEditor',
//                 },
//                 {
//                   path: 'new',
//                   component: TurnstileEditor,
//                   name: 'NewTurnstile',
//                 },
//               ],
//             },
//           ],
//         },
//         {
//           path: 'filter',
//           component: Filter,
//           name: 'Filter',
//         },
//         {
//           path: 'groups',
//           name: 'Groups',
//           component: EmptyLayout,
//           redirect: 'groups/list',
//           children: [
//             {
//               path: 'list',
//               component: GroupList,
//               name: 'GroupList',
//             },
//             {
//               path: 'new',
//               component: GroupEditor,
//               name: 'NewGroup',
//             },
//             {
//               path: 'edit/:id',
//               component: GroupEditor,
//               name: 'GroupEditor',
//             },
//           ],
//         },
//         {
//           path: 'log',
//           name: 'Log',
//           component: EmptyLayout,
//           redirect: 'log/admin',
//           children: [
//             {
//               path: 'admin',
//               component: AdminLog,
//               name: 'AdminLog',
//             },
//             {
//               path: 'building',
//               component: BuildingLog,
//               name: 'BuildingLog',
//               params: {
//                 type: 'Building',
//               },
//             },
//             {
//               path: 'parking',
//               component: ParkingLog,
//               name: 'ParkingLog',
//               params: {
//                 type: 'Parkinglot',
//               },
//             },
//           ],
//         },
//         {
//           path: 'opening',
//           component: EmptyLayout,
//           children: [
//             {
//               path: '',
//               component: OpeningHours,
//               name: 'OpeningHours',
//             },
//           ],
//         },
//         {
//           path: 'operations',
//           component: EmptyLayout,
//           children: [
//             {
//               path: '',
//               component: OperationList,
//               name: 'OperationList',
//             },
//           ],
//         },
//         {
//           path: 'plates',
//           name: 'Plates',
//           component: EmptyLayout,
//           redirect: 'plates/list',
//           children: [
//             {
//               path: 'list',
//               component: PlateList,
//               name: 'PlateList',
//             },
//             {
//               path: 'new',
//               component: PlateEditor,
//               name: 'NewPlate',
//             },
//             {
//               path: 'edit/:id',
//               component: PlateEditor,
//               name: 'PlateEditor',
//             },
//           ],
//         },
//         {
//           path: 'remote-open',
//           component: EmptyLayout,
//           children: [
//             {
//               path: '',
//               component: RemoteOpen,
//               name: 'RemoteOpen',
//             },
//           ],
//         },
//         {
//           path: 'schedules',
//           name: 'Schedules',
//           component: EmptyLayout,
//           redirect: 'schedules/list',
//           children: [
//             {
//               path: 'list',
//               component: ScheduleList,
//               name: 'ScheduleList',
//             },
//             {
//               path: 'new',
//               component: ScheduleEditor,
//               name: 'NewSchedule',
//             },
//             {
//               path: 'edit/:id',
//               component: ScheduleEditor,
//               name: 'ScheduleEditor',
//             },
//           ],
//         },
//         {
//           path: 'status',
//           component: EmptyLayout,
//           children: [
//             {
//               path: '',
//               component: Status,
//               name: 'Status',
//             },
//           ],
//         },
//         {
//           path: 'tickets',
//           name: 'Tickets',
//           component: EmptyLayout,
//           redirect: 'tickets/list',
//           children: [
//             {
//               path: 'list',
//               component: ParkingTicketsList,
//               name: 'TicketList',
//             },
//           ],
//         },
//         {
//           path: 'users',
//           name: 'Users',
//           component: EmptyLayout,
//           redirect: 'users/list',
//           children: [
//             {
//               path: 'new',
//               component: UserEditor,
//               name: 'NewUser',
//             },
//             {
//               path: 'edit/:id',
//               component: UserEditor,
//               name: 'UserEditor',
//             },
//             {
//               path: 'list',
//               component: UsersList,
//               name: 'UserList',
//             },
//           ],
//         },
//         {
//           path: '',
//           component: Home,
//           beforeEnter: requireAuth,
//         },
//         {
//           path: '*',
//           component: Home,
//         },
//       ],
//     },
//   ],
// });

// // Wait for vuex store to restore states
// router.beforeEach(async (to, from, next) => {
//   await store.restored;
//   next();
// });

// export default router;
