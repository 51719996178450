import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('SCHEDULE_WRITE') ? _c(VBtn, _vm._b({
    attrs: {
      "to": {
        name: 'NewSchedule'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Ütemzések")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 4
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm.hasRight('SCHEDULE_WRITE') ? _c(VBtn, {
          staticClass: "rounded-sm mx-2",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "to": {
              name: 'ScheduleEditor',
              params: {
                id: item.id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('SCHEDULE_DELETE') ? _c(VBtn, {
          staticClass: "rounded-sm mx-2",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "dark": "",
            "color": "secondary",
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteSchedule(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  }), _c(VDivider, {
    staticClass: "mt-0"
  }), _c('div', {
    staticClass: "px-4"
  }, [_vm.search.amount ? _c('pagination', {
    attrs: {
      "showcombo": 1,
      "limit": _vm.search.limit,
      "amount": _vm.search.amount
    },
    on: {
      "pageChanged": _vm.pageChanged
    },
    model: {
      value: _vm.search.page,
      callback: function ($$v) {
        _vm.$set(_vm.search, "page", $$v);
      },
      expression: "search.page"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }