<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewGateway' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Gateways</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Név"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.building_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Épület megnevezés"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.process_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Folyamat"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.address"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Cím"
                clearable
                hide-details
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.port"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Port"
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            small
            text
            fab
            dark
            depressed
            color="secondary"
            class="rounded-sm"
            :to="{ name: 'GatewayEditor', params: { id: item.element_id }}"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            text
            fab
            small
            color="secondary"
            class="rounded-sm m-3"
            @click="deleteGateway(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            text
            fab
            small
            color="secondary"
            class="rounded-sm m-3"
            @click="gatewayOperation(item.element_id)"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'GatewayList',
      fetchItems: this.fetchGateways,
      dataTable: {
        options: {
          sortBy: ['gateway_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'gateway_name' },
          { text: 'Épület megnevezés', value: 'building_name' },
          { text: 'Folyamat', value: 'process_name' },
          { text: 'Cím', value: 'address' },
          { text: 'Port', value: 'port' },
          { value: 'actions', sortable: false, align: 'end' }
        ],
      },
      search: {
        name: '',
        building_name: '',
        process_name: '',
        address: '',
        port: ''
      }
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        building_name: this.$route.query.building_name,
        process_name: this.$route.query.process_name,
        address: this.$route.query.address,
        port: this.$route.query.port
      };
    },

    async fetchGateways() {
      this.loading = true;
      try {
        const response = await this.$http.post('gateways/list', this.options);
        this.dataTable.items = response.gateways;
        this.dataTable.itemsLength = response.gateways_count;
        this.dataTable.options.page = this.$route.query.page*1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteGateway(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> gateway törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`gateways/delete/${item.element_id}`);
        this.fetchGateways();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A gateway törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async gatewayOperation(element_id) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: 'Újra feltölti az összes kártyát?',
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.post('operation/add', {
          alias: 'ALL_CARD_UPLOAD',
          params: {
            id: element_id
          }
        });
        this.$dialog.notify.info('A művelet kiadva.');

        await this.$http.post("log/write/", {
          log_type_alias: 'ALL_CARD_UPLOAD',
          params: {
            id: element_id
          }
        });

      } catch (e) {
        console.error(e);
      }
    }
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        building_name: this.search.building_name || '',
        process_name: this.search.process_name || '',
        address: this.search.address || '',
        port: this.search.port || ''
      };
    }
  }
};
</script>
