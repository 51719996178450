<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        color="grey"
        dark
        @click="$router.go(-1)"
        title="Vissza"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading" title="Mentés">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Zóna</h1>

    <v-card :loading="loading" class="mb-6">
      <v-card-title>
        <v-icon left>mdi-fence</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.remark"
              v-model="form.remark"
              filled
              :rows="3"
              auto-grow
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card :loading="loading">
      <v-card-title>
        <v-icon left>mdi-smart-card-reader</v-icon>
        Olvasók
      </v-card-title>

      <!-- {{ form?.readers }} -->

      <v-card-text>
        <v-multi-select-list
          :headers="[
            { text: 'Megnevezés', value: 'name' },
            { text: 'Irány', value: 'direction' },
          ]"
          :items="readers"
          v-model="form.readers"
          item-value="element_id"
          item-text="name"
          unique-values
          :blank-item-template="{ element_id: 0 }"
        >
          <template #[`item.name`]="{ item, items, index }">
            <v-autocomplete
              :items="items"
              v-model="item.element_id"
              item-text="name"
              item-value="element_id"
              placeholder="Válassz"
              :error-messages="errors[`reader-${index}-element_id`]"
              :hide-details="!errors[`reader-${index}-element_id`] && !errors[`reader-${index}-direction`]"
              filled
              dense
              :value-comparator="$config.comparators.isEqual"
            />
          </template>
          <template #[`item.direction`]="{ item, index }">
            <v-select
              :items="[
                { text: 'Bejárat', value: 'I' },
                { text: 'Kijárat', value: 'O' },
              ]"
              v-model="item.direction"
              item-text="text"
              item-value="value"
              placeholder="Válassz"
              :error-messages="errors[`reader-${index}-direction`]"
              :hide-details="!errors[`reader-${index}-element_id`] && !errors[`reader-${index}-direction`]"
              filled
              dense
            />
          </template>
        </v-multi-select-list>
      </v-card-text>

      {{ errors }}
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      errors: {},
      loading: false,
    };
  },

  mounted() {
    this.fetchZone();
  },

  methods: {
    async fetchZone() {
      this.loading = true;
      const response = await this.$http.get(`zones/details/${this.$route.params.id || 0}`);
      this.loading = false;
      this.form = response.zone;
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('zones/save/', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'ZoneEditor') {
            this.$router.push({
              name: 'ZoneEditor',
              params: { id: response.zone.element_id },
            });
          }
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    readers: {
      async get() {
        const response = await this.$http.post('readers/list', {
          controller_id: this.form.controller_id,
        });
        return response.readers;
      },
      default: [],
      lazy: true,
    },
  },
};
</script>
