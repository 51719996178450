<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="popup.import = true"
        title="Importálás Excel fájlból"
        :disabled="hasSelect()"
      >
        <v-icon dark>mdi-file-excel</v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        title="Mentés"
        :disabled="hasSelect()"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="mail"
        title="Email küldés"
        v-if="hasSelect()"
      >
        <v-icon dark>mdi-email-outline</v-icon>
      </v-btn>
    </div>
    <h1 class="py-6">Vonalkód beállítás</h1>
    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-cog</v-icon>
            Csoportos beállítás
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-btn
                :disabled="!visibleAllSelect()"
                key="all_select"
                id="all_select"
                v-model="all_select"
                class="m-1"
                color="secondary"
              >
                Összes kijelölése
              </v-btn>
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-checkbox
                @change="allAccessLevelChange"
                v-for="(al, index) in accessLevels"
                :key="index"
                :label="al.name"
                v-model="allAccessLevelId"
                class="m-1"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <date-time-picker
                :timePicker="true"
                :datePicker="true"
                class="m-1"
                @input="allStartDateTimeChange"
                v-model="allStartDateTime"
                label="Kezdő dátum"
                filled
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                label="Időtartam"
                v-if="$root.user.barcode_period_fix == 0"
                @input="allPeriodChange"
                v-model="allPeriod"
                filled
                type="number"
                class="m-1"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card :loading="loading" v-if="barcodes.length > 0">
      <v-data-table :headers="headers" :items="barcodes">
        <template #[`item.check`]="{ item }">
          <v-checkbox
            :disabled="!item.email_can_be_sent"
            v-model="item.selected"
          />
          <!-- :key="'barcode_selected_' + barcode.id"
              :id="'barcode_selected_' + barcode.id"
              val="1" Ezt kihagytam-->
        </template>
        <template #[`item.rfid`]="{ item }">
          <span
            ><small>{{ item.rf_id }}</small
            ><br />{{ item.barcode_number }}</span
          >
          <v-row v-if="item.email_sent == 1" class="m-1">
            <v-icon>mdi-email-outline</v-icon>
            <span
              >{{ item.email_sent_user_name }} ({{
                item.email_sent_user_email
              }})</span
            >
          </v-row>
        </template>
        <template #[`item.emailDatas`]="{ item }">
          <v-row class="mt-1">
            <v-text-field
              placeholder="Kedvezményezett neve"
              :disabled="!item.editable"
              @keydown="
                item.email_can_be_sent = false;
                item.selected = false;
                all_select = false;
              "
              :error-messages="errors['barcode_name_' + item.id]"
              v-model="item.name"
              filled
            />
          </v-row>
          <v-row class="mb-1">
            <v-text-field
              placeholder="Kedvezményezett email"
              :disabled="!item.editable"
              @keydown="
                item.email_can_be_sent = false;
                item.selected = false;
                all_select = false;
              "
              :error-messages="errors['barcode_email_' + item.id]"
              v-model="item.email"
              filled
            />
          </v-row>
        </template>
        <template #[`item.plate`]="{ item }">
          <v-row class="mb-1">
            <v-text-field
              placeholder="Rendszám"
              :disabled="!item.editable"
              @keydown="
                item.email_can_be_sent = false;
                item.selected = false;
                all_select = false;
              "
              :error-messages="errors['barcode_license_plate_' + item.id]"
              v-model="item.license_plate"
              :rules="numtextrules"
              filled
            />
          </v-row>
        </template>
        <template #[`item.law`]="{ item }">
          <v-checkbox
            :disabled="!item.editable"
            @change="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            v-for="(al, index) in accessLevels"
            :key="index"
            :label="al.name"
            :name="'barcode_access_levels_' + item.id + '[]'"
            :id="'barcode_access_levels_' + item.id + '_' + al.id"
            v-model="item.access_levels"
            class="m-1"
          />
          <div
            v-show="error.id == 'barcode_access_levels_' + item.id"
            class="form-error"
            v-for="(error, id) in errors"
            :key="id"
          >
            Kötelező
          </div>
        </template>
        <template #[`item.startDate`]="{ item }">
          <v-date-time-picker
            :disabled="!item.editable"
            :timePicker="true"
            :datePicker="true"
            @input="
              item.email_can_be_sent = false;
              item.selected = false;
            "
            :errorMessages="errors['barcode_start_date_time_' + item.id]"
            v-model="item.start_date_time"
          />
        </template>
        <template #[`item.period`]="{ item }">
          <v-text-field
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
            "
            @keyup="periodKeyup(item)"
            :disabled="!item.editable"
            :error-messages="errors['barcode_period_' + item.id]"
            v-model="item.period"
            :rules="numrules"
          />
        </template>
      </v-data-table>
    </v-card>
    <div v-else>Nem található vonalkód.</div>
    <import-popup
      :requestId="$route.query.id"
      @close="popup.import = false"
      @success="getBarcodes"
      :dialog="popup.import"
      @show="showDialog"
      v-if="popup.import"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ImportPopup from "../barcode/import.vue";
import $ from "jquery";

export default {
  components: {
    ImportPopup,
  },
  data() {
    return {
      popup: {
        import: false,
      },
      barcodes: [],
      accessLevels: [],
      errors: [],
      allAccessLevelId: [],
      allStartDateTime: "",
      allPeriod: "",
      all_select: false,
      loading: false,
      headers: [
        { text: "", value: "check" },
        { text: "Rf ID / Szám", value: "rfid" },
        { text: "Név / Email", value: "emailDatas" },
        { text: "Rendszám", value: "plate" },
        { text: "Jog", value: "law" },
        { text: "Kezdő dátum", value: "startDate" },
        { text: "Időtartam (óra)", value: "period" },
      ],
      numtextrules: [
        (v) => /^[a-zA-Z0-9]*$/.test(v) || "Csak számot és betüt lehet írni",
      ],
      numrules: [(v) => /^[0-9]*$/.test(v) || "Csak számot lehet írni"],
    };
  },
  watch: {
    all_select() {
      var _this = this;

      this.barcodes.map(function (barcode) {
        if (barcode.email_can_be_sent) barcode.selected = _this.all_select;
      });
    },
  },

  mounted() {
    this.getBarcodes();

    /*Vue.nextTick(function () {
      if (
        !$(".menu-column-sub").hasClass("hide") &&
        $(".menu-column-sub2").hasClass("hide")
      ) {
        $(".wrapper .content").css({ "padding-left": "162px" });
      }

      if (
        !$(".menu-column-sub").hasClass("hide") &&
        !$(".menu-column-sub2").hasClass("hide")
      ) {
        $(".wrapper .content").css({
          "padding-left": 162 + $(".menu-column-sub2").outerWidth(),
        });
      }

      if (
        $(".menu-column-sub").hasClass("hide") &&
        $(".menu-column-sub2").hasClass("hide")
      )
        $(".wrapper .content").css({ "padding-left": "100px" });
    });*/
  },
  methods: {
    showDialog(value){
      this.popup.import = value;
    },
    periodKeyup(barcode) {
      if (parseInt(this.$root.user.barcode_period_fix) == 1) {
        barcode.period = 24;
      }
    },

    visibleAllSelect() {
      var r = false;
      this.barcodes.map(function (barcode) {
        if (barcode.email_can_be_sent) r = true;
      });
      return r;
    },

    hasSelect() {
      var s = false;
      this.barcodes.map(function (barcode) {
        if (barcode.selected) s = true;
      });
      return s;
    },

    allAccessLevelChange() {
      var _this = this;

      this.barcodes.map(function (barcode) {
        if (barcode.editable) {
          barcode.access_levels = [];
          _this.allAccessLevelId.map(function (id) {
            barcode.access_levels.push(id);
          });
        }
      });
    },

    allStartDateTimeChange(ov, nv) {
      var _this = this;

      this.barcodes.map(function (barcode) {
        if (barcode.editable)
          barcode.start_date_time = _this.allStartDateTime;
      });
    },

    allPeriodChange() {
      var _this = this;

      this.barcodes.map(function (barcode) {
        if (barcode.editable) barcode.period = _this.allPeriod;
      });
    },

    async save() {
      var _this = this;
      this.$emit("save_start");
      try {
        var data = {
          barcodes: JSON.stringify(_this.barcodes),
        };
        const response = await this.$http.post("barcodesUpdate/", data);
        _this.errors = [];
        _this.$emit("save_finish");

        if (response.errors != undefined) {
          _this.errors = response.errors;
        } else {
          _this.getBarcodes();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async mail() {
      var _this = this;
      this.errors = [];
      this.$emit("save_start");
      try {
        var data = {
          barcodes: JSON.stringify(this.barcodes),
        };
        const response = this.$http.post("barcodeSendEmail/", data);
        _this.errors = [];
        _this.$emit("save_finish");

        if (response.errors != undefined) {
          _this.errors = response.errors;
        } else {
          _this.getBarcodes();
          _this.all_select = false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async getBarcodes() {
      var _this = this;
      try {
        const response = await this.$http.get("barcodesToSet/1"); //a router.query.id-nak kellene itt lennie. A tesztelés során írtam át
        _this.barcodes = response.barcodes;
        _this.accessLevels = response.access_levels;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
