<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="popup.import = true"
        title="Importálás Excel fájlból"
        :disabled="hasSelect()"
      >
        <v-icon dark>mdi-file-excel</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        title="Mentés"
        :disabled="hasSelect()"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="mail"
        title="Email küldés"
        v-if="hasSelect()"
      >
        <v-icon dark>mdi-email-outline</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Vonalkód beállítás</h1>

    <v-expansion-panels class="mb-4" v-model="groupEditor.show" :value="selectedRows.length ? (groupEditor.show ?? 0) : null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-checkbox-multiple-outline</v-icon>
            Csoportos beállítás
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :md="3">
              <v-select
                :disabled="!selectedRows.length"
                label="Jogosultság"
                :items="accessLevels"
                item-value="element_id"
                item-text="name"
                chips
                small-chips
                clearable
                multiple
                hide-details
                v-model="groupEditor.access_level_ids"
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="2">
              <v-datetime-picker
                :disabled="!selectedRows.length"
                @input="allStartDateTimeChange"
                v-model="allStartDateTime"
                label="Kezdő dátum"
                :text-field-props="{ filled: false, hideDetails: true }"
                :date-picker="true"
                :time-picker="false"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="1">
              <v-masked-text-field
                :disabled="!selectedRows.length"
                label="Időtartam"
                v-if="$store.state.user.barcode_period_fix == 0"
                @input="allPeriodChange"
                v-model="allPeriod"
                :mask="'###'"
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="qrCodes"
        :single-select="false"
        show-select
        calculate-widths
        disable-sort
        v-model="selectedRows"
      >
        <!-- <template #[`item.check`]="{ item }">
          <v-checkbox :disabled="!item.email_can_be_sent" v-model="item.selected" />
        </template> -->
        <template #[`item.rfid`]="{ item }">
          <span
            ><small>{{ item.rf_id }}</small
            ><br />{{ item.code }}</span
          >
          <v-row v-if="item.email_sent == 1" class="m-1">
            <v-icon>mdi-email-outline</v-icon>
            <span>{{ item.email_sent_user_name }} ({{ item.email_sent_user_email }})</span>
          </v-row>
        </template>
        <template #[`item.emailData`]="{ item }">
          <v-text-field
            placeholder="Kedvezményezett neve"
            :disabled="!item.editable"
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            :error-messages="errors['barcode_name_' + item.id]"
            :hide-details="!errors['barcode_name_' + item.id]"
            v-model="item.name"
            filled
            dense
          />
          <v-text-field
            placeholder="Kedvezményezett e-mail címe"
            :disabled="!item.editable"
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            :error-messages="errors['barcode_email_' + item.id]"
            :hide-details="!errors['barcode_email_' + item.id]"
            v-model="item.email"
            filled
            dense
          />
        </template>
        <template #[`item.plate`]="{ item }">
          <v-masked-text-field
            placeholder="Rendszám"
            :disabled="!item.editable"
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            :error-messages="errors['barcode_license_plate_' + item.id]"
            :hide-details="!errors['barcode_license_plate_' + item.id]"
            v-model="item.license_plate"
            :mask="$config.inputMasks.plateNumber"
            v-uppercase
            filled
          />
        </template>
        <template #[`item.permissions`]="{ item }">
          <v-autocomplete
            placeholder="Jogosultság"
            :disabled="!item.editable"
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            :error-messages="errors['barcode_access_level_' + item.id]"
            :hide-details="!errors['barcode_access_level_' + item.id]"
            v-model="item.access_level_ids"
            :items="accessLevels"
            item-value="element_id"
            item-text="name"
            multiple
            filled
            small-chips
            @input="item.access_level_ids.sort((a, b) => a > b ? 1 : - 1)"
          />
          <!-- <v-checkbox
            :disabled="!item.editable"
            @change="
              item.email_can_be_sent = false;
              item.selected = false;
              all_select = false;
            "
            v-for="(al, index) in accessLevels"
            item-value="element_id"
            :key="index"
            :label="al.name"
            :name="'barcode_access_levels_' + item.id + '[]'"
            :id="'barcode_access_levels_' + item.id + '_' + al.id"
            v-model="item.access_level_ids"
            class="m-1"
          /> -->
          <!-- <div
            v-show="error.id == 'barcode_access_levels_' + item.id"
            class="form-error"
            v-for="(error, id) in errors"
            :key="id"
          >
            Kötelező
          </div> -->
        </template>
        <template #[`item.startDate`]="{ item }">
          <v-datetime-picker
            :disabled="!item.editable"
            :time-picker="true"
            :date-picker="true"
            @input="
              item.email_can_be_sent = false;
              item.selected = false;
            "
            filled
            placeholder="Dátum"
            :hide-details="!errors.startDate?.length"
            :error-messages="errors['barcode_start_date_time_' + item.id]"
            v-model="item.start_date_time"
          />
        </template>
        <template #[`item.period`]="{ item }">
          <v-masked-text-field
            placeholder="Időtartam"
            filled
            @keydown="
              item.email_can_be_sent = false;
              item.selected = false;
            "
            @keyup="periodKeyup(item)"
            :disabled="!item.editable"
            :error-messages="errors[item.id]?.period"
            :hide-details="!errors[item.id]?.period"
            v-model="item.period"
            :mask="'###'"
          />
        </template>
      </v-data-table>
    </v-card>

    <!-- <import-popup
      :requestId="$route.query.id"
      @close="popup.import = false"
      @success="getBarcodes"
      :dialog="popup.import"
      @show="showDialog"
      v-if="popup.import"
    /> -->
  </div>
</template>

<script>
// import ImportPopup from "../barcode/import.vue";

export default {
  data() {
    return {
      popup: {
        import: false,
      },
      groupEditor: {
        show: false,
        access_level_ids: [],
      },
      selectedRows: [],
      barcodes: [],
      accessLevels: [],
      errors: [],
      allAccessLevelId: [],
      allStartDateTime: '',
      allPeriod: '',
      all_select: false,
      loading: true,
      headers: [
        // { text: '', value: 'check' },
        { text: 'Rf ID / Szám', value: 'rfid' },
        { text: 'Név / Email', value: 'emailData' },
        { text: 'Rendszám', value: 'plate' },
        { text: 'Jogosultság', value: 'permissions' },
        { text: 'Kezdő dátum', value: 'startDate' },
        { text: 'Időtartam (óra)', value: 'period' },
      ],
    };
  },

  watch: {
    all_select() {
      for (const barcode of this.barcodes) {
        if (barcode.email_can_be_sent) {
          barcode.selected = this.all_select;
        }
      }
    },
  },

  mounted() {
    // this.getBarcodes();
    /*Vue.nextTick(function () {
      if (
        !$(".menu-column-sub").hasClass("hide") &&
        $(".menu-column-sub2").hasClass("hide")
      ) {
        $(".wrapper .content").css({ "padding-left": "162px" });
      }

      if (
        !$(".menu-column-sub").hasClass("hide") &&
        !$(".menu-column-sub2").hasClass("hide")
      ) {
        $(".wrapper .content").css({
          "padding-left": 162 + $(".menu-column-sub2").outerWidth(),
        });
      }

      if (
        $(".menu-column-sub").hasClass("hide") &&
        $(".menu-column-sub2").hasClass("hide")
      )
        $(".wrapper .content").css({ "padding-left": "100px" });
    });*/
  },

  methods: {
    showDialog(value) {
      this.popup.import = value;
    },

    periodKeyup(barcode) {
      if (parseInt(this.$root.user.barcode_period_fix) == 1) {
        barcode.period = 24;
      }
    },

    visibleAllSelect() {
      for (const code of this.barcodes) {
        if (code.email_can_be_sent) {
          return true;
        }
      }
      return false;
    },

    hasSelect() {
      for (const code of this.barcodes) {
        if (code.selected) {
          return true;
        }
      }
      return true;
    },

    allAccessLevelChange() {
      for (const code of this.barcodes) {
        if (code.editable) {
          code.access_levels = [];
          for (const id of this.allAccessLevelId) {
            code.access_levelss.push(id);
          }
        }
      }
    },

    allStartDateTimeChange(ov, nv) {
      for (const code of this.barcodes) {
        if (code.editable) {
          code.start_date_time = this.allStartDateTime;
        }
      }
    },

    allPeriodChange() {
      for (const code of this.barcodes) {
        if (code.editable) {
          code.period = this.allPeriod;
        }
      }
    },

    async save() {
      try {
        const response = await this.$http.post('qr-code-requests/codes/save', {
          qr_codes: this.qrCodes,
        });
        this.errors = [];

        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async mail() {
      this.errors = [];
      try {
        const response = this.$http.post('qr-code-requests/codes/email', {
          barcodes: JSON.stringify(this.barcodes),
        });
        this.errors = {};

        if (response.errors) {
          this.errors = response.errors;
        } else {
          this.$asyncComputed.qrCodes.update();
          this.all_select = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  asyncComputed: {
    qrCodes: {
      async get() {
        this.loading = true;
        const response = await this.$http.get(
          `qr-code-requests/codes/list/${this.$route.params.id || 0}`
        );
        this.accessLevels = response.access_levels;
        for (const qr_code of response.qr_codes) {
          qr_code.isSelectable = !qr_code.email_can_be_sent;
        }
        this.loading = false;
        return response.qr_codes;
      },
      default: [],
    },
  },
};
</script>
