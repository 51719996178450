<template>
  <div>
    <export-dialog
      v-if="exportDialog"
      filename="Benntlévők listája"
      api-endpoint="/occupants/export"
      @close="exportDialog = false"
    />
    <exit-dialog v-if="exitDialog" v-model="exitDialog" @success="fetchItems" />

    <title-row title="Benntlévők listája" :subtitle="`${dataTable.itemsLength > 0 ? dataTable.itemsLength : 0} személy tartózkodik bent`">
      <template #buttons>
        <title-row-button @click="togglePolling" :color="polling ? 'primary' : 'muted'">
          <v-icon v-if="!loading" left>{{ polling ? 'mdi-stop' : 'mdi-play' }}</v-icon>
          <v-progress-circular v-else indeterminate size="18" width="2" class="mr-2 ml-n1" />
          Frissítés
        </title-row-button>

        <title-row-button @click="exportDialog = true" color="muted">
          <v-icon left>mdi-file-export</v-icon>
          Exportálás
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels
      class="mb-4"
      :mandatory="!!Object.values(search).find((e) => e && e !== '' && e.length)"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Név"
                :mask="$config.inputMasks.name"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.card_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Kártyaszám"
                :mask="$config.inputMasks.cardNumber"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                v-if="hasRight('DEVICE_READ')"
                v-model="search.zone_ids"
                @input="searchEventHandler"
                :items="zones"
                placeholder="Összes"
                label="Zóna"
                dense
                clearable
                multiple
                hide-details
                item-value="element_id"
                item-text="name"
                class="my-4 my-sm-0"
              />
              <v-masked-text-field
                v-else
                v-model="search.zone_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Zóna"
                :mask="$config.inputMasks.name"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                v-if="hasRight('DEVICE_READ')"
                v-model="search.gate_ids"
                @input="searchEventHandler"
                :items="gates"
                placeholder="Összes"
                label="Kapu"
                :mask="$config.inputMasks.name"
                dense
                clearable
                multiple
                hide-details
                item-value="element_id"
                item-text="name"
                class="my-4 my-sm-0"
              />
              <v-masked-text-field
                v-else
                v-model="search.gate_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Kapu"
                :mask="$config.inputMasks.name"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :headers="dataTableHeaders"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :loading="dataTable.loading"
      >
        <template #[`item.entry_datetime`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.entry_datetime | moment('from') }}
              </span>
            </template>
            {{ item.entry_datetime | moment('lll') }}
          </v-tooltip>
        </template>

        <template
          v-for="field in extraFields"
          #[`item.extra_fields.${field.name}`]="{ item, index }"
        >
          <dynamic-field-value
            v-if="item.extra_fields && item.extra_fields[field.name]"
            :key="`dynamic-field-value-${index}-${field.name}`"
            :value="item.extra_fields[field.name]"
            :field="field"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            small
            text
            fab
            dark
            depressed
            color="secondary"
            class="rounded-sm"
            title="Kiléptetés"
            @click="exitDialog = item"
            ><v-icon>mdi-exit-run</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
import ExportDialog from '@/components/ExportDialog.vue';
import ExitDialog from './ExitDialog.vue';


export default {
  mixins: [dataTablePage],

  components: { ExportDialog, ExitDialog },

  data() {
    return {
      routeName: 'OccupantList',
      fetchItems: this.fetchOccupants,
      exoneration: {
        ticket: false,
        remark: '',
      },
      selectedPlate: {},
      dataTable: {
        options: {
          sortBy: ['entry_datetime'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Utolsó áthaladás ideje', value: 'entry_datetime' },
          { text: 'Név', value: 'name', cellClass: 'font-weight-bold' },
          { text: 'Kapu', value: 'gate_name', sortable: false },
          { text: 'Zóna', value: 'zone_name' },
        ],
      },
      search: {
        name: '',
        card_number: '',
        zone_ids: [],
        zone_name: '',
        gate_ids: [],
        gate_name: '',
      },
      searchTimeout: null,
      fetchTimeout: null,
      exportDialog: false,
      pollingTimeout: null,
      pollingPausedByPagination: false,
      firstFetch: true,
      polling: false,
      exitDialog: null,
    };
  },

  watch: {
    'dataTable.options': {
      handler(val) {
        if (val.page > 1) {
          if (this.polling) {
            clearTimeout(this.pollingTimeout);
            this.polling = false;
            this.pollingPausedByPagination = true;
          }
        } else if (this.pollingPausedByPagination) {
          this.fetchOccupants(null, true);
          this.polling = true;
          this.pollingPausedByPagination = false;
        }
      },
      deep: true,
    },

    search: {
      handler() {
        this.dataTable.loading = true;
      },
      deep: true,
    },
  },

  created() {
    if (this.options.page == 1) {
      this.polling = true;
    }
  },

  destroyed() {
    clearTimeout(this.pollingTimeout);
  },

  methods: {
    parseSearchQueries() {
      return {
        license_plate_number: this.$route.query.license_plate_number,
        rf_id_code: this.$route.query.rf_id_code,
      };
    },

    togglePolling() {
      this.polling = !this.polling;
      if (this.polling) {
        this.fetchItems(null, true);
      } else {
        clearTimeout(this.pollingTimeout);
      }
    },

    async fetchOccupants(origin = null, keepPolling = false) {
      if (origin === 'searchEventHandler' || this.firstFetch) {
        this.dataTable.loading = true;
      }

      if (this.firstFetch) {
        keepPolling = this.polling;
        this.firstFetch = false;
      }

      this.loading = true;
      const data = JSON.parse(JSON.stringify(this.search));
      data.sort_by = this.options.sortBy || [''];
      data.sort_desc = this.options.sortDesc || [false];
      try {
        const response = await this.$http.post('occupants/list', this.options);
        if (this.polling && keepPolling) {
          this.pollingTimeout = setTimeout(() => {
            this.fetchItems(null, true);
          }, 2000);
        }
        this.dataTable.items = response.occupants;
        this.dataTable.itemsLength = response.occupants_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        this.polling = false;
        console.error(e);
      } finally {
        this.loading = false;
        this.dataTable.loading = false;
        this.abortController = null;
      }
    },
  },

  computed: {
    dataTableHeaders() {
      return [
        ...this.dataTable.headers,
        ...this.extraFields
          .filter((e) => e.show_in_list == 1)
          .map((e) => ({ text: e.label, value: `extra_fields.${e.name}`, sortable: e.sortable == 1 })),
        { text: '', value: 'actions', sortable: false },
      ];
    },

    extraFields() {
      return this.$store.getters['fields/findByModelName']('CardOwners');
    },

    searchQueries() {
      return {
        name: this.search.name || '',
        card_number: this.search.card_number || '',
        zone_ids: this.search.zone_ids || [],
        zone_name: this.search.zone_name || '',
        gate_ids: this.search.gate_ids || [],
        gate_name: this.search.gate_name || '',
      };
    },
  },

  asyncComputed: {
    gates: {
      async get() {
        if (!this.hasRight('DEVICE_READ')) {
          return [];
        }
        const response = await this.$http.get('gates/list', { limit: 10000 });
        return response.gates;
      },
      default: [],
    },
    zones: {
      async get() {
        if (!this.hasRight('DEVICE_READ')) {
          return [];
        }
        const response = await this.$http.get('zones/list', { limit: 10000 });
        return response.zones;
      },
      default: [],
    },
  },
};
</script>
