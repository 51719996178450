import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "accent",
      "width": "60",
      "height": "60",
      "to": {
        name: 'TurnstileEditor'
      },
      "title": "Hozzáadás"
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Forgóvillák")]), _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VCombobox, {
    attrs: {
      "label": "Épület",
      "items": _vm.buildings,
      "item-text": "label",
      "item-value": "value",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "building_id", $$v);
      },
      expression: "search.building_id"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c(VCombobox, {
    attrs: {
      "label": "Épület szint",
      "items": _vm.buildingLevels,
      "item-text": "label",
      "item-value": "value",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "building_level_id", $$v);
      },
      expression: "search.building_level_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCard, {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.turnstiles,
      "items-per-page": parseInt(_vm.search.limit),
      "server-items-length": parseInt(_vm.search.amount),
      "page": parseInt(_vm.search.page),
      "options": _vm.options,
      "hide-default-footer": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      },
      "update:sort-by": _vm.sortChanged,
      "update:sort-desc": _vm.sortChanged
    },
    scopedSlots: _vm._u([{
      key: "item.k",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(_vm._s(item.x_coord) + ", " + _vm._s(item.y_coord))];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "to": ("/devices/turnstiles/edit/" + (item.element_id)),
            "title": "Módosítás"
          }
        }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1), _c(VBtn, {
          staticClass: "rounded-sm m-2",
          attrs: {
            "fab": "",
            "small": "",
            "color": "secondary",
            "dark": "",
            "elevation": "0",
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.del(item.element_id);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  }), _c(VDivider, {
    staticClass: "mt-0"
  }), _c('div', {
    staticClass: "px-4"
  }, [_vm.turnstiles.length > 0 ? _c('pagination', {
    attrs: {
      "showcombo": "1",
      "limit": _vm.search.limit,
      "amount": _vm.search.amount
    },
    on: {
      "pageChanged": _vm.pageChanged
    },
    model: {
      value: _vm.search.page,
      callback: function ($$v) {
        _vm.$set(_vm.search, "page", $$v);
      },
      expression: "search.page"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }